import { Component,Input} from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { IUserEx } from '@vierkant-software/types__api';
import { Globals } from 'src/app/services/globals.service';

@Component({
    selector:    'gc-profile', //TODO: rename to gc-avatar as the wrapper is never used
    styleUrls:   ['./gc-avatar.component.sass'],
    templateUrl: './gc-avatar.component.haml',
})
export class GcAvatarComponent{
    public color: string;
    _internalImage: string | SafeUrl;

    @Input() public set image(value: string | SafeUrl) {
        this._internalImage = value;
    }
    @Input() public set value(value: [number, number] | IUserEx) {
        if (value && !Array.isArray(value)) value = value.userColor ?? value.defaultColor;
        this._internalValue = value as [number, number] ?? [undefined, undefined];
        this.setColor();
    }

    _internalValue: [number, number] = [undefined, undefined];

    public get lightness() {
        const lightness = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ?
            Globals.DARK_MODE_LIGHTNESS :
            Globals.LIGHT_MODE_LIGHTNESS;
        return `${lightness * 100}%`;
    }

    private setColor() {
        const [h, s] = this._internalValue;
        if (!h || !s) {
            this.color = undefined;
            return;
        }
        this.color = `${h}deg, ${s * 100}%`;
    }

}