import { Component,Input } from '@angular/core';
import { IAddress, ILocation } from '@vierkant-software/types__api';
type Format = "short" | "long";

@Component({
    selector: 'gc-address',
    template: '<span [ngClass]="{pre: isLong}">{{ _internvalParsedValue }}</span>',
    styles:   ['.pre { white-space:pre; display: block; }']
})
export class GcAddressComponent {
     @Input() public set value(value: IAddress) {
        this._internalValue = value;
        this.updateValue();
    }

    @Input() public set nullValue(value: string) {
        this._internalNullValue = value;
        this.updateValue();
    }

    @Input() public set format(value: Format){
        this._internalFormat = value;
        this.updateValue();
    }


    public get format(){
        return this._internalFormat;
    }

    get isLong(){
        return this.format === "long";
    }

    _internalValue: IAddress;
    _internalFormat: Format = "short";
    _internalNullValue: string = '-';
    _internvalParsedValue: string;

    constructor(){
        this.updateValue();
    }

    private updateValue(){
        this._internvalParsedValue = this._internalValue ? this.formatAddress(this._internalValue, this.format) : this._internalNullValue;
    }

    private formatAddress(value: IAddress, format: Format): string {
        if (format === "short")
            return this.shortFormat(value);
        return this.longFormat(value);
    }

    private longFormat(value: IAddress){
        if (!value) return '';
        const result = [
            [value.street, value.houseNumber].filter(x => x).join(' '),
            [value.postcode, value.city].filter(x => x).join(' '),
            value.country,
            value.description,
        ].filter(x => x).join('\n');
        return result.trim();
    }

    private shortFormat(value: ILocation): string {
        if (!value) return '';
        const result = [
            [value.street, value.houseNumber].filter(x => x).join(' '),
            [value.postcode, value.city, value.country].filter(x => x).join(' '),
        ].filter(x => x).join(', ');
        return result.trim();
    }

}
