/* eslint-disable @typescript-eslint/prefer-for-of */
/* eslint-disable @angular-eslint/no-output-on-prefix */
import { animate, AnimationEvent, state, style, transition, trigger } from '@angular/animations';
import { CommonModule } from '@angular/common';
import {
    AfterContentInit,
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ContentChildren,
    ElementRef,
    EventEmitter,
    forwardRef,
    HostBinding,
    Input,
    NgModule,
    NgZone,
    OnDestroy,
    OnInit,
    Optional,
    Output,
    Provider,
    QueryList,
    Renderer2,
    TemplateRef,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import { ControlContainer, ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { GCValidatorFn, ValidatorFlags } from 'src/app/modules/ngx-components';
import { DateObjectUnits, DateTime, MonthNumbers } from 'luxon';
import { OverlayService, PrimeNGConfig, PrimeTemplate, SharedModule, TranslationKeys } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { ConnectedOverlayScrollHandler, DomHandler } from 'primeng/dom';
import { RippleModule } from 'primeng/ripple';
import { UniqueComponentId, ZIndexUtils } from 'primeng/utils';
import { Subscription } from 'rxjs';
import { InternalAppService } from 'src/services/app.service';

type ResponsiveOptions = {
    numMonths: number;
    breakpoint: string;
};

type Value = DateTime | DateTime[];
type DateMeta = DateObjectUnits & {selectable?: boolean};
type Func = () => void;
/**
 * Intl.DateTimeFormatOptions does not match type used in luxon.DateTime.
 * As fallback we allow Record<string, string> here, to prevent the TS compiler from failing
 */
type DateFormat = string | Intl.DateTimeFormatOptions | Record<string, string>;

export const CALENDAR_VALUE_ACCESSOR: Provider = {
    provide:     NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => GcDatepickerComponent),
    multi:       true
};

export interface LocaleSettings {
    firstDayOfWeek?: number;
    dayNames?: string[];
    dayNamesShort?: string[];
    dayNamesMin?: string[];
    monthNames?: string[];
    monthNamesShort?: string[];
    today?: string;
    clear?: string;
    dateFormat?: DateFormat;
    weekHeader?: string;
}

export type CalendarTypeView = 'date' | 'month' | 'year';

@Component({
    selector: 'gc-datepicker',
    template: `
        <label *ngIf="label !== undefined" class="gc-datepicker-label">{{ label }}</label>
        <span #container
            [ngClass]="{
                'p-calendar': true,
                'p-calendar-w-btn': showIcon,
                'p-calendar-timeonly': timeOnly,
                'p-calendar-disabled': disabled,
                'p-focus': focus,
                'label-container': asLabel
            }"
            [ngStyle]="style"
            [class]="asLabel ? styleClass : ''">
            <ng-template [ngIf]="asLabel">
                <label
                    #label
                    type="text"
                    [attr.id]="inputId"
                    [attr.name]="name"
                    [attr.required]="required"
                    [attr.aria-required]="required"
                    (focus)="onInputFocus($event)"
                    (keydown)="onInputKeydown($event)"
                    (click)="onInputClick()"
                    (blur)="onInputBlur($event)"
                    (input)="onUserInput($event)"
                    [ngStyle]="inputStyle"
                    [class]="inputStyleClass"
                    [attr.tabindex]="tabindex"
                    [attr.inputmode]="touchUI ? 'off' : null"
                    [ngClass]="'p-component gc-datepicker-as-label ' + styleClass"
                    autocomplete="off"
                    [attr.aria-labelledby]="ariaLabelledBy"
                >
                    {{inputFieldValue}}
                </label>
            </ng-template>
            <ng-template [ngIf]="!inline && !asLabel">
                <input
                    #inputfield
                    type="text"
                    [attr.id]="inputId"
                    [attr.name]="name"
                    [attr.required]="required"
                    [attr.aria-required]="required"
                    [value]="inputFieldValue"
                    (focus)="onInputFocus($event)"
                    (click)="onInputClick()"
                    (blur)="onInputBlur($event)"
                    [readonly]="readonlyInput"
                    [ngStyle]="inputStyle"
                    [class]="inputStyleClass"
                    [placeholder]="placeholder || (localizedPlaceholder ? _localizedPlaceholder : '')"
                    [disabled]="disabled"
                    [attr.tabindex]="tabindex"
                    [attr.inputmode]="touchUI ? 'off' : null"
                    [ngClass]="'p-inputtext p-component'"
                    autocomplete="off"
                    [attr.aria-labelledby]="ariaLabelledBy"
                    (keydown)="onInputKeydown($event)"
                    (mouseup)="onMouseUp($event)"
                    (keypress)="onKeyPress($event)"
                    (input)="onUserInput($event)"
                    (copy)="onCopy($event)"
                    (paste)="handleInputChange($event)"
                />
                <i *ngIf="showClear && !disabled && (value !== null && value !== undefined)" class="p-calendar-clear-icon pi pi-times" (click)="clear()"></i>
                <button
                type="button"
                [attr.aria-label]="iconAriaLabel"
                [icon]="icon"
                pButton pRipple
                *ngIf="showIcon"
                (click)="onButtonClick($event, inputfield)"
                class="p-datepicker-trigger"
                [disabled]="disabled"
                tabindex="0"></button>
            </ng-template>
            <div
                #contentWrapper
                [class]="panelStyleClass"
                [ngStyle]="panelStyle"
                [ngClass]="{
                    'p-datepicker p-component': true,
                    'p-datepicker-inline': inline,
                    'p-disabled': disabled,
                    'p-datepicker-timeonly': timeOnly,
                    'p-datepicker-multiple-month': this.numberOfMonths > 1,
                    'p-datepicker-monthpicker': view === 'month',
                    'p-datepicker-touch-ui': touchUI
                }"
                [@overlayAnimation]="
                    touchUI
                        ? { value: 'visibleTouchUI', params: { showTransitionParams: showTransitionOptions, hideTransitionParams: hideTransitionOptions } }
                        : { value: 'visible', params: { showTransitionParams: showTransitionOptions, hideTransitionParams: hideTransitionOptions } }
                "
                [@.disabled]="inline === true"
                (@overlayAnimation.start)="onOverlayAnimationStart($event)"
                (@overlayAnimation.done)="onOverlayAnimationDone($event)"
                (click)="onOverlayClick($event)"
                *ngIf="inline || overlayVisible"
            >
                <ng-content select="p-header"></ng-content>
                <ng-container *ngTemplateOutlet="headerTemplate"></ng-container>
                <ng-container *ngIf="!timeOnly">
                    <div class="p-datepicker-group-container">
                        <div class="p-datepicker-group" *ngFor="let month of months; let i = index">
                            <div class="p-datepicker-header">
                                <button
                                    (keydown)="onContainerButtonKeydown($event)"
                                    class="p-datepicker-prev p-link"
                                    (click)="onPrevButtonClick($event)"
                                    *ngIf="i === 0"
                                    type="button" pRipple
                                >
                                    <span class="p-datepicker-prev-icon pi pi-chevron-left"></span>
                                </button>
                                <div class="p-datepicker-title">
                                    <button
                                        type="button"
                                        (click)="switchToMonthView($event)"
                                        (keydown)="onContainerButtonKeydown($event)"
                                        *ngIf="currentView === 'date'"
                                        class="p-datepicker-month p-link"
                                        [disabled]="switchViewButtonDisabled()"
                                    >
                                        {{ getMonthName(month.month) }}
                                    </button>
                                    <button
                                        type="button"
                                        (click)="switchToYearView($event)"
                                        (keydown)="onContainerButtonKeydown($event)"
                                        *ngIf="currentView !== 'year'"
                                        class="p-datepicker-year p-link"
                                        [disabled]="switchViewButtonDisabled()"
                                    >
                                        {{ getYear(month) }}
                                    </button>
                                    <span class="p-datepicker-decade" *ngIf="currentView === 'year'">
                                        <ng-container *ngIf="!decadeTemplate">
                                            {{ yearPickerValues()[0] }} - {{ yearPickerValues()[yearPickerValues().length - 1] }}
                                        </ng-container>
                                        <ng-container *ngTemplateOutlet="decadeTemplate; context: { $implicit: yearPickerValues }"></ng-container>
                                    </span>
                                </div>
                                <button
                                    (keydown)="onContainerButtonKeydown($event)"
                                    class="p-datepicker-next p-link"
                                    (click)="onNextButtonClick($event)"
                                    [style.display]="numberOfMonths === 1 ? 'inline-flex' : i === numberOfMonths - 1 ? 'inline-flex' : 'none'"
                                    type="button"
                                    pRipple
                                >
                                    <span class="p-datepicker-next-icon pi pi-chevron-right"></span>
                                </button>
                            </div>
                            <div class="p-datepicker-calendar-container" *ngIf="currentView === 'date'">
                                <table class="p-datepicker-calendar">
                                    <thead>
                                        <tr>
                                            <th *ngIf="showWeek" class="p-datepicker-weekheader p-disabled">
                                                <span>{{ getTranslation('weekHeader') }}</span>
                                            </th>
                                            <th scope="col" *ngFor="let weekDay of weekDays; let begin = first; let end = last">
                                                <span>{{ weekDay }}</span>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let week of month.dates; let j = index">
                                            <td *ngIf="showWeek" class="p-datepicker-weeknumber">
                                                <span class="p-disabled">
                                                    {{ month.weekNumbers[j] }}
                                                </span>
                                            </td>
                                            <td
                                                *ngFor="let date of week"
                                                [ngClass]="{ 'p-datepicker-other-month': date.otherMonth, 'p-datepicker-today': date.today }"
                                            >
                                                <ng-container *ngIf="date.otherMonth ? showOtherMonths : true">
                                                    <span
                                                        [ngClass]="{ 'p-highlight': isSelected(date), 'p-disabled': !date.selectable }"
                                                        (click)="onDateSelect($event, date)"
                                                        draggable="false" (keydown)="onDateCellKeydown($event, date, i)"
                                                        pRipple
                                                    >
                                                        <ng-container *ngIf="!dateTemplate">{{ date.day }}</ng-container>
                                                        <ng-container *ngTemplateOutlet="dateTemplate; context: { $implicit: date }"></ng-container>
                                                    </span>
                                                </ng-container>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="p-monthpicker" *ngIf="currentView === 'month'">
                        <span
                            *ngFor="let m of monthPickerValues(); let i = index"
                            (click)="onMonthSelect($event, i+1)"
                            (keydown)="onMonthCellKeydown($event, i+1)"
                            class="p-monthpicker-month"
                            [ngClass]="{ 'p-highlight': isMonthSelected(i+1), 'p-disabled': isMonthDisabled(i+1) }"
                            pRipple
                        >
                            {{ m }}
                        </span>
                    </div>
                    <div class="p-yearpicker" *ngIf="currentView === 'year'">
                        <span
                            *ngFor="let y of yearPickerValues()"
                            (click)="onYearSelect($event, y)"
                            (keydown)="onYearCellKeydown($event, y)"
                            class="p-yearpicker-year"
                            [ngClass]="{ 'p-highlight': isYearSelected(y) }"
                            pRipple
                        >
                            {{ y }}
                        </span>
                    </div>
                </ng-container>
                <div class="p-timepicker" *ngIf="(showTime || timeOnly) && currentView === 'date'">
                    <div class="p-hour-picker">
                        <button
                            class="p-link"
                            type="button"
                            (keydown)="onContainerButtonKeydown($event)"
                            (keydown.enter)="incrementHour($event)"
                            (keydown.space)="incrementHour($event)"
                            (mousedown)="onTimePickerElementMouseDown($event, 0, 1)"
                            (mouseup)="onTimePickerElementMouseUp($event)"
                            (keyup.enter)="onTimePickerElementMouseUp($event)"
                            (keyup.space)="onTimePickerElementMouseUp($event)"
                            (mouseleave)="onTimePickerElementMouseLeave()"
                            pRipple
                        >
                            <span class="pi pi-chevron-up"></span>
                        </button>
                        <span><ng-container *ngIf="currentHour < 10">0</ng-container>{{ currentHour }}</span>
                        <button
                            class="p-link"
                            type="button"
                            (keydown)="onContainerButtonKeydown($event)"
                            (keydown.enter)="decrementHour($event)"
                            (keydown.space)="decrementHour($event)"
                            (mousedown)="onTimePickerElementMouseDown($event, 0, -1)"
                            (mouseup)="onTimePickerElementMouseUp($event)"
                            (keyup.enter)="onTimePickerElementMouseUp($event)"
                            (keyup.space)="onTimePickerElementMouseUp($event)"
                            (mouseleave)="onTimePickerElementMouseLeave()"
                            pRipple
                        >
                            <span class="pi pi-chevron-down"></span>
                        </button>
                    </div>
                    <div class="p-separator">
                        <span>{{ timeSeparator }}</span>
                    </div>
                    <div class="p-minute-picker">
                        <button
                            class="p-link"
                            type="button"
                            (keydown)="onContainerButtonKeydown($event)"
                            (keydown.enter)="incrementMinute($event)"
                            (keydown.space)="incrementMinute($event)"
                            (mousedown)="onTimePickerElementMouseDown($event, 1, 1)"
                            (mouseup)="onTimePickerElementMouseUp($event)"
                            (keyup.enter)="onTimePickerElementMouseUp($event)"
                            (keyup.space)="onTimePickerElementMouseUp($event)"
                            (mouseleave)="onTimePickerElementMouseLeave()"
                            pRipple
                        >
                            <span class="pi pi-chevron-up"></span>
                        </button>
                        <span><ng-container *ngIf="currentMinute < 10">0</ng-container>{{ currentMinute }}</span>
                        <button
                            class="p-link"
                            type="button"
                            (keydown)="onContainerButtonKeydown($event)"
                            (keydown.enter)="decrementMinute($event)"
                            (keydown.space)="decrementMinute($event)"
                            (mousedown)="onTimePickerElementMouseDown($event, 1, -1)"
                            (mouseup)="onTimePickerElementMouseUp($event)"
                            (keyup.enter)="onTimePickerElementMouseUp($event)"
                            (keyup.space)="onTimePickerElementMouseUp($event)"
                            (mouseleave)="onTimePickerElementMouseLeave()"
                            pRipple
                        >
                            <span class="pi pi-chevron-down"></span>
                        </button>
                    </div>
                    <div class="p-separator" *ngIf="showSeconds">
                        <span>{{ timeSeparator }}</span>
                    </div>
                    <div class="p-second-picker" *ngIf="showSeconds">
                        <button
                            class="p-link"
                            type="button"
                            (keydown)="onContainerButtonKeydown($event)"
                            (keydown.enter)="incrementSecond($event)"
                            (keydown.space)="incrementSecond($event)"
                            (mousedown)="onTimePickerElementMouseDown($event, 2, 1)"
                            (mouseup)="onTimePickerElementMouseUp($event)"
                            (keyup.enter)="onTimePickerElementMouseUp($event)"
                            (keyup.space)="onTimePickerElementMouseUp($event)"
                            (mouseleave)="onTimePickerElementMouseLeave()"
                            pRipple
                        >
                            <span class="pi pi-chevron-up"></span>
                        </button>
                        <span><ng-container *ngIf="currentSecond < 10">0</ng-container>{{ currentSecond }}</span>
                        <button
                            class="p-link"
                            type="button"
                            (keydown)="onContainerButtonKeydown($event)"
                            (keydown.enter)="decrementSecond($event)"
                            (keydown.space)="decrementSecond($event)"
                            (mousedown)="onTimePickerElementMouseDown($event, 2, -1)"
                            (mouseup)="onTimePickerElementMouseUp($event)"
                            (keyup.enter)="onTimePickerElementMouseUp($event)"
                            (keyup.space)="onTimePickerElementMouseUp($event)"
                            (mouseleave)="onTimePickerElementMouseLeave()"
                            pRipple
                        >
                            <span class="pi pi-chevron-down"></span>
                        </button>
                    </div>
                    <div class="p-ampm-picker" *ngIf="hourFormat === '12'">
                        <button
                            class="p-link"
                            type="button"
                            (keydown)="onContainerButtonKeydown($event)"
                            (click)="toggleAMPM($event)"
                            (keydown.enter)="toggleAMPM($event)"
                            pRipple
                        >
                            <span class="pi pi-chevron-up"></span>
                        </button>
                        <span>{{ pm ? 'PM' : 'AM' }}</span>
                        <button
                            class="p-link"
                            type="button"
                            (keydown)="onContainerButtonKeydown($event)"
                            (click)="toggleAMPM($event)"
                            (keydown.enter)="toggleAMPM($event)"
                            pRipple
                        >
                            <span class="pi pi-chevron-down"></span>
                        </button>
                    </div>
                </div>
                <div class="p-datepicker-buttonbar" *ngIf="showButtonBar || showToday || showClear">
                    <button
                        *ngIf="showButtonBar || showToday"
                        type="button"
                        [label]="getTranslation('today')"
                        (keydown)="onContainerButtonKeydown($event)"
                        (click)="onTodayButtonClick($event)"
                        pButton
                        pRipple
                        [ngClass]="[todayButtonStyleClass]"
                    ></button>
                    <button
                        *ngIf="showButtonBar || showClear"
                        type="button"
                        [label]="getTranslation('clear')"
                        (keydown)="onContainerButtonKeydown($event)"
                        (click)="onClearButtonClick($event)"
                        pButton
                        pRipple
                        [ngClass]="[clearButtonStyleClass]"
                    ></button>
                </div>
                <ng-content select="p-footer"></ng-content>
                <ng-container *ngTemplateOutlet="footerTemplate"></ng-container>
            </div>
        </span>
    `,
    animations: [
        trigger('overlayAnimation', [
            state(
                'visibleTouchUI',
                style({
                    transform: 'translate(-50%,-50%)',
                    opacity:   1
                })
            ),
            transition(
                'void => visible',
                [
                    style({ opacity: 0, transform: 'scaleY(0.8)' }),
                    animate('{{showTransitionParams}}', style({ opacity: 1, transform: '*' }))
                ]
            ),
            transition(
                'visible => void',
                [
                    animate('{{hideTransitionParams}}', style({ opacity: 0 }))
                ]
            ),
            transition(
                'void => visibleTouchUI',
                [
                    style({ opacity: 0, transform: 'translate3d(-50%, -40%, 0) scale(0.9)' }), animate('{{showTransitionParams}}')
                ]
            ),
            transition(
                'visibleTouchUI => void',
                [
                    animate('{{hideTransitionParams}}', style({ opacity: 0, transform: 'translate3d(-50%, -40%, 0) scale(0.9)'}))
                ]
            ),
        ])
    ],
    providers:       [CALENDAR_VALUE_ACCESSOR],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation:   ViewEncapsulation.Emulated,
    styleUrls:       ['./datepicker.component.sass']
})
export class GcDatepickerComponent implements OnInit, OnDestroy, AfterContentInit, AfterViewInit,  ControlValueAccessor {
    @HostBinding("class") class = 'p-element p-inputwrapper gc-datepicker';
    @HostBinding("class.p-inputwrapper-filled") classInputwrapperFilled = "filled";
    @HostBinding("class.p-inputwrapper-focus") classInputwrapperFocus = "focus";
    @HostBinding("class.p-calendar-clearable") classPCalendarClearable = "'showClear && !disabled'";

    defs: Record<string | number, string>;

    tests: RegExp[];

    partialPosition: number;

    firstNonMaskPos: number;

    lastRequiredNonMaskPos: number;

    len: number;

    buffer: string[];

    chunkSeparator: string[];

    parts: Intl.DateTimeFormatPart[] = [];

    @Input() characterPattern: string = '[A-Za-z]';
    @Input() keepBuffer: boolean = false;
    @Input() slotChar: string = '_';

    @Input() style: Record<string, unknown>;

    @Input() styleClass: string;

    @Input() inputStyle: Record<string, unknown>;

    @Input() inputId: string;

    @Input() name: string;

    @Input() label: string;

    @Input() inputStyleClass: string;

    @Input() placeholder: string;
    @Input() localizedPlaceholder: boolean;

    @Input() ariaLabelledBy: string;

    @Input() iconAriaLabel: string;

    @Input() disabled?: boolean;
    @Input() disablePopup?: boolean;

    @Input() dateFormat: DateFormat;

    @Input() multipleSeparator: string = ',';

    @Input() rangeSeparator: string = '-';

    @Input() inline: boolean = false;
    @Input() asLabel: boolean = false;

    @Input() showOtherMonths: boolean = true;

    @Input() selectOtherMonths: boolean;

    @Input() showIcon: boolean;

    @Input() icon: string = 'pi pi-calendar';

    @Input() appendTo?: string | HTMLElement;

    @Input() readonlyInput: boolean;

    @Input() shortYearCutoff: string = '+10';

    @Input() monthNavigator: boolean;

    @Input() yearNavigator: boolean;

    @Input() hourFormat: string = '24';

    @Input() timeOnly: boolean;

    @Input() stepHour: number = 1;

    @Input() stepMinute: number = 1;

    @Input() stepSecond: number = 1;

    @Input() showSeconds: boolean = false;

    @Input() required: boolean;

    @Input() showOnFocus: boolean = true;

    @Input() showWeek: boolean = false;

    @Input() showClear: boolean = false;

    @Input() selectionMode: string = 'single';

    @Input() maxDateCount: number;

    @Input() showButtonBar: boolean;

    @Input() showToday: boolean;

    @Input() todayButtonStyleClass: string = 'p-button-text';

    @Input() clearButtonStyleClass: string = 'p-button-text';

    @Input() autoZIndex: boolean = true;

    @Input() baseZIndex: number = 0;

    @Input() panelStyleClass: string;

    @Input() panelStyle: Record<string, unknown>;

    @Input() keepInvalid: boolean = false;

    @Input() hideOnDateTimeSelect: boolean = true;

    @Input() touchUI: boolean;

    @Input() timeSeparator: string = ':';

    @Input() focusTrap: boolean = true;

    @Input() showTransitionOptions: string = '.12s cubic-bezier(0, 0, 0.2, 1)';

    @Input() hideTransitionOptions: string = '.1s linear';

    @Output() onFocus: EventEmitter<unknown> = new EventEmitter();

    @Output() onBlur: EventEmitter<unknown> = new EventEmitter();

    @Output() onClose: EventEmitter<unknown> = new EventEmitter();

    @Output() onSelect: EventEmitter<unknown> = new EventEmitter();

    @Output() onClear: EventEmitter<unknown> = new EventEmitter();

    @Output() onInput: EventEmitter<unknown> = new EventEmitter();

    @Output() onTodayClick: EventEmitter<unknown> = new EventEmitter();

    @Output() onClearClick: EventEmitter<unknown> = new EventEmitter();

    @Output() onMonthChange: EventEmitter<unknown> = new EventEmitter();

    @Output() onYearChange: EventEmitter<unknown> = new EventEmitter();

    @Output() onClickOutside: EventEmitter<unknown> = new EventEmitter();

    @Output() onShow: EventEmitter<unknown> = new EventEmitter();

    @ContentChildren(PrimeTemplate) templates: QueryList<PrimeTemplate>;

    @Input() tabindex: number;

    @ViewChild('container', { static: false }) containerViewChild: ElementRef;

    @ViewChild('inputfield', { static: false }) inputfieldViewChild: ElementRef;
    @ViewChild('label', { static: false }) labelViewChild: ElementRef;

    @ViewChild('contentWrapper', { static: false }) set content(content: ElementRef) {
        this.contentViewChild = content;

        if (this.contentViewChild) {
            if (this.isMonthNavigate) {
                Promise.resolve(null).then(() => this.updateFocus()).catch(console.error);
                this.isMonthNavigate = false;
            } else {
                if (!this.focus)
                    this.initFocusableCell();

            }
        }
    }

    contentViewChild: ElementRef;

    value: Value;

    dates: DateTime[];

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    months: any[];

    weekDays: string[];

    currentMonth: number;

    currentYear: number;

    currentHour: number;

    currentMinute: number;

    currentSecond: number;

    pm: boolean;

    maskOverlay: HTMLDivElement;

    maskOverlayClickListener: Func;

    overlay: HTMLDivElement;

    responsiveStyleElement: HTMLStyleElement;

    overlayVisible: boolean;

    onModelChange = (value: Value) => {};

    onModelTouched: Func = () => {};

    calendarElement: HTMLElement;

    timePickerTimer: number;

    documentClickListener: Func;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    animationEndListener: (this: HTMLDivElement, ev: any) => unknown;

    yearOptions: number[];

    focus: boolean;

    filled: boolean | number;

    isKeydown: boolean;

    inputFieldValue: string = null;

    _minDate: DateTime;

    _maxDate: DateTime;

    _showTime: boolean;

    _yearRange: string;

    preventDocumentListener: boolean;

    dateTemplate: TemplateRef<unknown>;

    headerTemplate: TemplateRef<unknown>;

    footerTemplate: TemplateRef<unknown>;

    disabledDateTemplate: TemplateRef<unknown>;

    decadeTemplate: TemplateRef<unknown>;

    _disabledDates: Array<DateTime>;

    _disabledDays: Array<number>;

    selectElement: unknown;

    todayElement: unknown;

    focusElement: unknown;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    scrollHandler: any;

    documentResizeListener: (this: Window, ev: UIEvent) => unknown;

    navigationState: { backward?: boolean, button?: boolean } = null;

    isMonthNavigate: boolean;

    initialized: boolean;

    translationSubscription: Subscription;

    _locale: LocaleSettings;

    _responsiveOptions: ResponsiveOptions[];

    currentView: string;

    attributeSelector: string;

    _numberOfMonths: number = 1;

    _firstDayOfWeek: number;
    _mask: string;
    _view: CalendarTypeView = 'date';

    preventFocus: boolean;

    @Input() get view(): CalendarTypeView {
        return this._view;
    }

    set view(view: CalendarTypeView) {
        this._view = view;
        this.currentView = this._view;
    }

    @Input() get defaultDate(): DateTime {
        return this._defaultDate;
    }

    set defaultDate(defaultDate: DateTime) {
        this._defaultDate = defaultDate;

        if (this.initialized) {
            const date = defaultDate || DateTime.now();
            this.currentMonth = date.month;
            this.currentYear = date.year;
            this.initTime(date);
            this.createMonths(this.currentMonth, this.currentYear);
        }
    }

    _defaultDate: DateTime;

    @Input() get minDate(): DateTime {
        return this._minDate;
    }

    set minDate(date: DateTime) {
        if (+date === +this._minDate)
            return;
        this._minDate = date;

        if (this.currentMonth !== undefined && this.currentMonth !== null && this.currentYear)
            this.createMonths(this.currentMonth, this.currentYear);

    }

    @Input() get maxDate(): DateTime {
        return this._maxDate;
    }

    set maxDate(date: DateTime) {
        if (+date === +this._maxDate)
            return;
        this._maxDate = date;

        if (this.currentMonth !== undefined && this.currentMonth !== null && this.currentYear)
            this.createMonths(this.currentMonth, this.currentYear);

    }

    @Input() get disabledDates(): DateTime[] {
        return this._disabledDates;
    }

    set disabledDates(disabledDates: DateTime[]) {
        this._disabledDates = disabledDates;
        if (this.currentMonth !== undefined && this.currentMonth !== null && this.currentYear)
            this.createMonths(this.currentMonth, this.currentYear);

    }

    @Input() get disabledDays(): number[] {
        return this._disabledDays;
    }

    set disabledDays(disabledDays: number[]) {
        this._disabledDays = disabledDays;

        if (this.currentMonth !== undefined && this.currentMonth !== null && this.currentYear)
            this.createMonths(this.currentMonth, this.currentYear);

    }

    @Input() get yearRange(): string {
        return this._yearRange;
    }

    set yearRange(yearRange: string) {
        this._yearRange = yearRange;

        if (yearRange) {
            const years = yearRange.split(':');
            const yearStart = parseInt(years[0], 10);
            const yearEnd = parseInt(years[1], 10);

            this.populateYearOptions(yearStart, yearEnd);
        }
    }

    @Input() get showTime(): boolean {
        return this._showTime;
    }

    set showTime(showTime: boolean) {
        this._showTime = showTime;
        if (Array.isArray(this.value))
            return;
        if (this.currentHour === undefined)
            this.initTime(this.value || DateTime.now());

        this.updateInputfield();
    }

    @Input()
    set locale(newLocale: LocaleSettings) {
        console.warn('Locale property has no effect, use new i18n API instead.');
    }
    get locale() {
        return this._locale;
    }

    @Input() get responsiveOptions(): ResponsiveOptions[] {
        return this._responsiveOptions;
    }

    set responsiveOptions(responsiveOptions: ResponsiveOptions[]) {
        this._responsiveOptions = responsiveOptions;

        this.destroyResponsiveStyleElement();
        this.createResponsiveStyle();
    }

    @Input() get numberOfMonths(): number {
        return this._numberOfMonths;
    }

    set numberOfMonths(numberOfMonths: number) {
        this._numberOfMonths = numberOfMonths;

        this.destroyResponsiveStyleElement();
        this.createResponsiveStyle();
    }

    @Input() get firstDayOfWeek(): number {
        return this._firstDayOfWeek;
    }

    set firstDayOfWeek(firstDayOfWeek: number) {
        this._firstDayOfWeek = firstDayOfWeek;

        this.createWeekDays();
    }

    get _localizedPlaceholder() {
        const dt = DateTime.now();
        return dt.toFormat(this.getDateFormat());
    }

    set mask(val: string) {
        this._mask = val;

        this.initMask();
        this.writeValue(undefined);
        this.onModelChange(this.value);
    }

    #localeSubscription: Subscription;


    @Input() formControlName: string;
    @Input() formControl: FormControl;
    fc = new FormControl();

    get gcControl(): FormControl {
        return <FormControl>(
          this.formControl ||
          this.formControlName ? this.controlContainer?.control?.get(this.formControlName) : this.fc
        );
    }

    @HostBinding('class.gc-required')
    get GcRequired(): boolean {
      // eslint-disable-next-line no-bitwise
      return (this.gcControl as unknown as { _rawValidators: GCValidatorFn[] })?._rawValidators?.some((v) => (v.flags & ValidatorFlags.required)) ?? false;
    }

    constructor(
        public service: InternalAppService,
        public el: ElementRef,
        public renderer: Renderer2,
        public cd: ChangeDetectorRef,
        private zone: NgZone,
        private config: PrimeNGConfig,
        public overlayService: OverlayService,
        @Optional() protected controlContainer: ControlContainer,
    ) {
    }

    ngOnInit() {
        this.attributeSelector = UniqueComponentId();
        const date = this.defaultDate || DateTime.now();
        this.createResponsiveStyle();
        this.currentMonth = date.month;
        this.currentYear = date.year;
        this.currentView = this.view;

        if (this.view === 'date') {
            this.createWeekDays();
            this.initTime(date);
            this.createMonths(this.currentMonth, this.currentYear);
        }

        this.translationSubscription = this.config.translationObserver.subscribe(() => {
            this.createWeekDays();
            this.cd.markForCheck();
        });

        this.initialized = true;
        this.#localeSubscription = this.service.$Locale.subscribe(x => this.onLocaleChanged(x));
    }

    private onLocaleChanged(locale: string){
        const intl = this.getIntl(locale);
        this.parts = intl.formatToParts(DateTime.now().valueOf());
        this.chunkSeparator = this.parts.filter(x => x.type === "literal").map(x => x.value.split('')).flat();
        let types = ["day", "month", "year", "hour" , "minute", "second"];
        this.mask = this.parts.map(x => {
            if (types.includes(x.type))
                x.value = x.value.replace(/./gi, '_');
            return x.value;
        }).join('');
    }

    ngAfterContentInit() {
        this.templates.forEach((item) => {
            switch(item.getType()) {
                case 'date':
                    this.dateTemplate = item.template;
                    break;

                case 'decade':
                    this.decadeTemplate = item.template;
                    break;

                case 'disabledDate':
                    this.disabledDateTemplate = item.template;
                    break;

                case 'header':
                    this.headerTemplate = item.template;
                    break;

                case 'footer':
                    this.footerTemplate = item.template;
                    break;

                default:
                    this.dateTemplate = item.template;
                    break;
            }
        });
    }

    ngAfterViewInit() {
        if (this.inline && this.contentViewChild) {
            this.contentViewChild.nativeElement.setAttribute(this.attributeSelector, '');

            if (!this.disabled) {
                this.initFocusableCell();
                if (this.numberOfMonths === 1)
                    this.contentViewChild.nativeElement.style.width = DomHandler.getOuterWidth(this.containerViewChild.nativeElement) + 'px';

            }
        }
    }

    getTranslation(option: string) {
        return this.config.getTranslation(option);
    }

    populateYearOptions(start: number, end: number) {
        this.yearOptions = [];

        for (let i = start; i <= end; i++)
            this.yearOptions.push(i);

    }

    createWeekDays() {
        this.weekDays = [];
        let dayIndex = this.getFirstDateOfWeek();
        const dayLabels = this.getTranslation(TranslationKeys.DAY_NAMES_MIN);
        for (let i = 0; i < 7; i++) {
            this.weekDays.push(dayLabels[dayIndex]);
            dayIndex = dayIndex === 7 ? 1 : ++dayIndex;
        }
    }

    monthPickerValues() {
        const monthPickerValues = [];
        for (let i = 1; i <= 12; i++)
            monthPickerValues.push(this.config.getTranslation('monthNamesShort')[i - 1]);


        return monthPickerValues;
    }

    yearPickerValues() {
        const yearPickerValues = [];
        const base = this.currentYear - (this.currentYear % 10);
        for (let i = 0; i < 10; i++)
            yearPickerValues.push(base + i);


        return yearPickerValues;
    }

    createMonths(month: number, year: number) {
        this.months = this.months = [];
        for (let i = 0; i < this.numberOfMonths; i++) {
            let m = month + i;
            let y = year;
            if (m > 12) {
                m = (m % 12) - 1;
                y = year + 1;
            }

            this.months.push(this.createMonth(m, y));
        }
    }

    getWeekNumber(date: DateTime) {
        return date.weekNumber;
    }

    createMonth(month: number, year: number) {
        const dates = [];
        const firstDay = this.getFirstDayOfMonthIndex(month, year);
        const daysLength = this.getDaysCountInMonth(month, year);
        const prevMonthDaysLength = this.getDaysCountInPrevMonth(month, year);
        let dayNo = 1;
        const today = DateTime.now();
        const weekNumbers = [];
        const monthRows = Math.ceil((daysLength + firstDay) / 7);

        for (let i = 0; i < monthRows; i++) {
            const week = [];

            if (i === 0) {
                for (let j = prevMonthDaysLength - firstDay + 1; j <= prevMonthDaysLength; j++) {
                    const prev = this.getPreviousMonthAndYear(month, year);
                    week.push({
                        day:        j,
                        month:      prev.month,
                        year:       prev.year,
                        otherMonth: true,
                        today:      this.isToday(today, j, prev.month, prev.year),
                        selectable: this.isSelectable(j, prev.month, prev.year, true)
                    });
                }

                const remainingDaysLength = 7 - week.length;
                for (let j = 0; j < remainingDaysLength; j++) {
                    week.push({
                        day:        dayNo,
                        month,
                        year,
                        today:      this.isToday(today, dayNo, month, year),
                        selectable: this.isSelectable(dayNo, month, year, false)
                    });
                    dayNo++;
                }
            } else {
                for (let j = 0; j < 7; j++) {
                    if (dayNo > daysLength) {
                        const next = this.getNextMonthAndYear(month, year);
                        week.push({
                            day:        dayNo - daysLength,
                            month:      next.month,
                            year:       next.year,
                            otherMonth: true,
                            today:      this.isToday(today, dayNo - daysLength, next.month, next.year),
                            selectable: this.isSelectable(dayNo - daysLength, next.month, next.year, true)
                        });
                    } else {
                        week.push({
                            day:        dayNo,
                            month,
                            year,
                            today:      this.isToday(today, dayNo, month, year),
                            selectable: this.isSelectable(dayNo, month, year, false)
                        });
                    }

                    dayNo++;
                }
            }

            if (this.showWeek)
                { weekNumbers.push(this.getWeekNumber(DateTime.fromObject({
                    year:  week[0].year,
                    month: week[0].month,
                    day:   week[0].day
                }))); }


            dates.push(week);
        }

        return {
            month,
            year,
            dates,
            weekNumbers
        };
    }

    initTime(date: DateTime) {
        this.pm = date.hour > 11;

        if (this.showTime) {
            this.currentMinute = date.minute;
            this.currentSecond = date.second;
            this.setCurrentHourPM(date.hour);
        } else if (this.timeOnly) {
            this.currentMinute = 0;
            this.currentHour = 0;
            this.currentSecond = 0;
        }
    }

    navBackward(event: Event) {
        if (this.disabled) {
            event.preventDefault();
            return;
        }

        this.isMonthNavigate = true;

        if (this.currentView === 'month') {
            this.decrementYear();
            setTimeout(() => {
                this.updateFocus();
            }, 1);
        } else if (this.currentView === 'year') {
            this.decrementDecade();
            setTimeout(() => {
                this.updateFocus();
            }, 1);
        } else {
            if (this.currentMonth === 1) {
                this.currentMonth = 12;
                this.decrementYear();
            } else {
                this.currentMonth--;
            }

            this.onMonthChange.emit({ month: this.currentMonth + 1, year: this.currentYear });
            this.createMonths(this.currentMonth, this.currentYear);
        }
    }

    navForward(event: Event) {
        if (this.disabled) {
            event.preventDefault();
            return;
        }

        this.isMonthNavigate = true;

        if (this.currentView === 'month') {
            this.incrementYear();
            setTimeout(() => {
                this.updateFocus();
            }, 1);
        } else if (this.currentView === 'year') {
            this.incrementDecade();
            setTimeout(() => {
                this.updateFocus();
            }, 1);
        } else {
            if (this.currentMonth === 12) {
                this.currentMonth = 1;
                this.incrementYear();
            } else {
                this.currentMonth++;
            }

            this.onMonthChange.emit({ month: this.currentMonth + 1, year: this.currentYear });
            this.createMonths(this.currentMonth, this.currentYear);
        }
    }

    decrementYear() {
        this.currentYear--;

        if (this.yearNavigator && this.currentYear < this.yearOptions[0]) {
            const difference = this.yearOptions[this.yearOptions.length - 1] - this.yearOptions[0];
            this.populateYearOptions(this.yearOptions[0] - difference, this.yearOptions[this.yearOptions.length - 1] - difference);
        }
    }

    decrementDecade() {
        this.currentYear = this.currentYear - 10;
    }

    incrementDecade() {
        this.currentYear = this.currentYear + 10;
    }

    incrementYear() {
        this.currentYear++;

        if (this.yearNavigator && this.currentYear > (this.yearOptions[this.yearOptions.length - 1])) {
            const difference = this.yearOptions[this.yearOptions.length - 1] - this.yearOptions[0];
            this.populateYearOptions(this.yearOptions[0] + difference, this.yearOptions[this.yearOptions.length - 1] + difference);
        }
    }

    switchToMonthView(event: Event) {
        this.setCurrentView('month');
        event.preventDefault();
    }

    switchToYearView(event: Event) {
        this.setCurrentView('year');
        event.preventDefault();
    }

    onDateSelect(event: Event, dateMeta: DateMeta) {
        if (this.disabled || !dateMeta.selectable) {
            event.preventDefault();
            return;
        }

        if (this.isMultipleSelection() && this.isSelected(dateMeta)) {
            this.value = (this.value as DateTime[]).filter((date, i) => !this.isDateEquals(date, dateMeta));
            if (this.value.length === 0)
                this.value = null;

            this.updateModel(this.value);
        } else {
            if (this.shouldSelectDate(dateMeta))
                this.selectDate(dateMeta);

        }

        if (this.isSingleSelection() && this.hideOnDateTimeSelect) {
            setTimeout(() => {
                event.preventDefault();
                this.hideOverlay();

                if (this.maskOverlay)
                    this.disableModality();


                this.cd.markForCheck();
            }, 150);
        }

        this.updateInputfield();
        event.preventDefault();
    }

    shouldSelectDate(dateMeta: DateMeta) {
        if (Array.isArray(this.value))
            return this.maxDateCount ? this.maxDateCount > (this.value ? this.value.length : 0) : true;
        else return true;
    }

    onMonthSelect(event: Event, index: number) {
        if (this.view === 'month') {
            this.onDateSelect(event, { year: this.currentYear, month: index as MonthNumbers, day: 1, selectable: true });
        } else {
            this.currentMonth = index;
            this.createMonths(this.currentMonth, this.currentYear);
            this.setCurrentView('date');
            this.onMonthChange.emit({ month: this.currentMonth, year: this.currentYear });
        }
    }

    onYearSelect(event: Event, year: number) {
        if (this.view === 'year') {
            this.onDateSelect(event, { year, month: 1, day: 1, selectable: true });
        } else {
            this.currentYear = year;
            this.setCurrentView('month');
            this.onYearChange.emit({ month: this.currentMonth, year: this.currentYear });
        }
    }

    updateInputfield() {
        let formattedValue = '';

        if (this.value) {
            if (this.isSingleSelection() && !Array.isArray(this.value)) {
                formattedValue = this.formatDateTime(this.value);
                this.buffer = this.datetimeToBuffer(this.value);
            } else if (this.isMultipleSelection() && Array.isArray(this.value)) {
                for (let i = 0; i < this.value.length; i++) {
                    const dateAsString = this.formatDateTime(this.value[i]);
                    formattedValue += dateAsString;
                    if (i !== this.value.length - 1)
                        formattedValue += this.multipleSeparator + ' ';

                }
            } else if (this.isRangeSelection() && Array.isArray(this.value)) {
                if (this.value && this.value.length) {
                    const startDate = this.value[0];
                    const endDate = this.value[1];

                    formattedValue = this.formatDateTime(startDate);
                    if (endDate)
                        formattedValue += ' ' + this.rangeSeparator + ' ' + this.formatDateTime(endDate);

                }
            }
        }

        this.inputFieldValue = formattedValue;
        this.updateFilledState();
        if (this.inputfieldViewChild && this.inputfieldViewChild.nativeElement)
            this.inputfieldViewChild.nativeElement.value = this.inputFieldValue;

    }

    formatDateTime(date: DateTime) {
        return date.setLocale(this.service.locale).toFormat(this.getDateFormat());
    }

    setCurrentHourPM(hours: number) {
        if (this.hourFormat === '12') {
            this.pm = hours > 11;
            if (hours >= 12)
                this.currentHour = hours === 12 ? 12 : hours - 12;
             else
                this.currentHour = hours === 0 ? 12 : hours;

        } else {
            this.currentHour = hours;
        }
    }

    setCurrentView(currentView: CalendarTypeView) {
        this.currentView = currentView;
        this.cd.detectChanges();
        this.alignOverlay();
    }

    selectDate(dateMeta: DateMeta) {
        let date = DateTime.fromObject({
            year:   dateMeta.year,
            month:  dateMeta.month,
            day:    dateMeta.day,
            hour:   dateMeta.hour,
            minute: dateMeta.minute,
            second: dateMeta.second
        });

        if (this.showTime && dateMeta.hour === undefined) {
            date = date.set({
                hour:   this.currentHour,
                minute: this.currentMinute,
                second: this.currentSecond,
            });
        }

        if (this.minDate && this.minDate > date) {
            date = this.minDate;
            this.setCurrentHourPM(date.hour);
            this.currentMinute = date.minute;
            this.currentSecond = date.second;
        }

        if (this.maxDate && this.maxDate < date) {
            date = this.maxDate;
            this.setCurrentHourPM(date.hour);
            this.currentMinute = date.minute;
            this.currentSecond = date.second;
        }

        if (this.isSingleSelection()) {
            this.updateModel(date);
        } else if (this.isMultipleSelection()) {
            this.updateModel(Array.isArray(this.value) ? [...this.value, date] : [date]);
        } else if (this.isRangeSelection()) {
            if (Array.isArray(this.value) && this.value.length) {
                let startDate = this.value[0];
                let endDate = this.value[1];

                if (!endDate && date >= startDate) {
                    endDate = date;
                } else {
                    startDate = date;
                    endDate = null;
                }

                this.updateModel([startDate, endDate]);
            } else {
                this.updateModel([date, null]);
            }
        }

        this.onSelect.emit(date);
    }

    updateModel(value: Value) {
        if (Array.isArray(value))
            value.forEach(x => x = x?.setLocale(this.service.locale));
        else
            value = value?.setLocale(this.service.locale);

        if (this.timeOnly && this.isSingleSelection()){
            if (!this.value)
                this.value = DateTime.now();
            const dt = <DateTime>value;
            this.value = (<DateTime>this.value).set({hour: dt.hour, minute: dt.minute, second: dt.second});
        } else {
            this.value = value;
        }

        if (this.isSingleSelection() && this.chunkSeparator && this.inputfieldViewChild?.nativeElement){
            this.buffer = this.datetimeToBuffer(<DateTime>this.value);
            this.writeBuffer();
        }
        this.onModelChange(this.value);
    }

    getFirstDayOfMonthIndex(month: number, year: number) {
        const day = DateTime.fromObject({year, month});
        const dayIndex = day.weekday + this.getSundayIndex();
        return dayIndex >= 7 ? dayIndex - 7 : dayIndex;
    }

    getDaysCountInMonth(month: number, year: number) {
        return DateTime.fromObject({year, month}).daysInMonth;
    }

    getDaysCountInPrevMonth(month: number, year: number) {
        const prev = this.getPreviousMonthAndYear(month, year);
        return this.getDaysCountInMonth(prev.month, prev.year);
    }

    getPreviousMonthAndYear(month: number, year: number) {
        let m; let y;

        if (month === 1) {
            m = 12;
            y = year - 1;
        } else {
            m = month - 1;
            y = year;
        }

        return { month: m, year: y };
    }

    getNextMonthAndYear(month: number, year: number) {
        let m; let y;

        if (month === 12) {
            m = 1;
            y = year + 1;
        } else {
            m = month + 1;
            y = year;
        }

        return { month: m, year: y };
    }

    getSundayIndex() {
        const firstDayOfWeek = this.getFirstDateOfWeek();

        return firstDayOfWeek > 0 ? 7 - firstDayOfWeek : 0;
    }

    isSelected(dateMeta: DateMeta): boolean {
        if (this.value) {
            if (this.isSingleSelection() && !Array.isArray(this.value)) {
                return this.isDateEquals(this.value, dateMeta);
            } else if (this.isMultipleSelection() && Array.isArray(this.value)) {
                let selected = false;
                for (const date of this.value) {
                    selected = this.isDateEquals(date, dateMeta);
                    if (selected)
                        break;

                }

                return selected;
            } else if (this.isRangeSelection() && Array.isArray(this.value)) {
                if (this.value[1]){
                    return this.isDateEquals(this.value[0], dateMeta) ||
                        this.isDateEquals(this.value[1], dateMeta) || this.isDateBetween(this.value[0], this.value[1], dateMeta);
                } else {
                    return this.isDateEquals(this.value[0], dateMeta);
                }
            }
        } else {
            return false;
        }
    }

    isComparable() {
        return this.value !== null && typeof this.value !== 'string';
    }

    isMonthSelected(month: number) {
        if (this.isComparable() && !this.isMultipleSelection()) {
            const [start, end] = this.isRangeSelection() && Array.isArray(this.value) ? this.value : [this.value, this.value];
            const selected = DateTime.fromObject({year: this.currentYear, month});
            return selected >= start && selected <= (end ?? start);
        }
        return false;
    }

    isMonthDisabled(month: number) {
        for (let day = 1; day < this.getDaysCountInMonth(month, this.currentYear) + 1; day++) {
            if (this.isSelectable(day, month, this.currentYear, false))
                return false;

        }
        return true;
    }

    isYearSelected(year: number) {
        if (this.isComparable()) {
            const value = this.isRangeSelection() && Array.isArray(this.value) ? this.value[0] : this.value;

            return !Array.isArray(value) ? value?.year === year : false;
        }

        return false;
    }

    isDateEquals(value: DateTime, dateMeta: DateMeta) {
        if (value && DateTime.isDateTime(value)) return value.day === dateMeta.day && value.month === dateMeta.month && value.year === dateMeta.year;
        else return false;
    }

    isDateBetween(start: DateTime, end: DateTime, dateMeta: DateMeta) {
        const between: boolean = false;
        if (start && end) {
            const date = DateTime.fromObject({
                year:   dateMeta.year,
                month:  dateMeta.month,
                day:    dateMeta.day,
                hour:   dateMeta.hour,
                minute: dateMeta.minute,
                second: dateMeta.second
            });
            return start <= date && end >= date;
        }

        return between;
    }

    isSingleSelection(): boolean {
        return this.selectionMode === 'single';
    }

    isRangeSelection(): boolean {
        return this.selectionMode === 'range';
    }

    isMultipleSelection(): boolean {
        return this.selectionMode === 'multiple';
    }

    isToday(today: DateTime, day: number, month: number, year: number): boolean {
        return today.day === day && today.month === month && today.year === year;
    }

    isSelectable(day: number, month: number, year: number, otherMonth: boolean): boolean {
        let validMin = true;
        let validMax = true;
        let validDate = true;
        let validDay = true;

        if (otherMonth && !this.selectOtherMonths)
            return false;


        if (this.minDate) {
            if (this.minDate.year > year) {
                validMin = false;
            } else if (this.minDate.year === year) {
                if (this.minDate.month > month) {
                    validMin = false;
                } else if (this.minDate.month === month) {
                    if (this.minDate.day > day)
                        validMin = false;

                }
            }
        }

        if (this.maxDate) {
            if (this.maxDate.year < year) {
                validMax = false;
            } else if (this.maxDate.year === year) {
                if (this.maxDate.month < month) {
                    validMax = false;
                } else if (this.maxDate.month === month) {
                    if (this.maxDate.day < day)
                        validMax = false;

                }
            }
        }

        if (this.disabledDates)
            validDate = !this.isDateDisabled(day, month, year);


        if (this.disabledDays)
            validDay = !this.isDayDisabled(day, month, year);


        return validMin && validMax && validDate && validDay;
    }

    isDateDisabled(day: number, month: number, year: number): boolean {
        if (this.disabledDates) {
            for (const disabledDate of this.disabledDates) {
                if (disabledDate.year === year && disabledDate.month === month && disabledDate.day === day)
                    return true;

            }
        }

        return false;
    }

    isDayDisabled(day: number, month: number, year: number): boolean {
        if (this.disabledDays) {
            const weekday = new Date(year, month, day);
            const weekdayNumber = weekday.getDay();
            return this.disabledDays.indexOf(weekdayNumber) !== -1;
        }
        return false;
    }

    onInputFocus(event: Event) {
        this.focus = true;
        this.writeBuffer();
        if (this.showOnFocus)
            this.showOverlay();

        this.onFocus.emit(event);
    }

    onInputClick() {
        if (this.showOnFocus && !this.overlayVisible)
            this.showOverlay();

    }

    onInputBlur(event: Event) {
        this.focus = false;
        this.onBlur.emit(event);
        if (!this.keepInvalid)
            this.updateInputfield();

        this.onModelTouched();
    }

    onButtonClick(event: Event, inputfield: HTMLInputElement) {
        if (!this.overlayVisible) {
            inputfield.focus();
            this.showOverlay();
        } else {
            this.hideOverlay();
        }
    }

    clear() {
        this.inputFieldValue = null;
        this.value = null;
        this.clearBuffer(0, this.buffer.length);
        this.onModelChange(this.value);
        this.onClear.emit();
    }

    onOverlayClick(event: Event) {
        this.overlayService.add({
            originalEvent: event,
            target:        this.el.nativeElement
        });
    }

    getMonthName(index: number) {
        return this.config.getTranslation('monthNames')[index - 1];
    }

    getYear(month: DateTime) {
        return this.currentView === 'month' ? this.currentYear : month.year;
    }

    switchViewButtonDisabled() {
        return this.numberOfMonths > 1 || this.disabled;
    }

    onPrevButtonClick(event: Event) {
        this.navigationState = { backward: true, button: true };
        this.navBackward(event);
    }

    onNextButtonClick(event: Event) {
        this.navigationState = { backward: false, button: true };
        this.navForward(event);
    }

    //#region user input
    initMask() {
        this.tests = [];
        this.partialPosition = this._mask.length;
        this.len = this._mask.length;
        this.firstNonMaskPos = null;
        this.defs = {
            _:   '[0-9]',
            9:   '[0-9]',
            a:   this.characterPattern,
            '*': `${this.characterPattern}|[0-9]`
        };

        const maskTokens = this._mask.split('');
        for (let i = 0; i < maskTokens.length; i++) {
            const c = maskTokens[i];
            if (c === '?') {
                this.len--;
                this.partialPosition = i;
            } else if (this.defs[c]) {
                this.tests.push(new RegExp(this.defs[c]));
                if (this.firstNonMaskPos === null)
                    this.firstNonMaskPos = this.tests.length - 1;

                if (i < this.partialPosition)
                    this.lastRequiredNonMaskPos = this.tests.length - 1;

            } else {
                this.tests.push(null);
            }
        }

        this.buffer = [];
        for (let i = 0; i < maskTokens.length; i++) {
            const c = maskTokens[i];
            if (c !== '?') {
                if (this.defs[c])this.buffer.push(this.getPlaceholder(i));
                else this.buffer.push(c);
            }
        }
    }

    getPlaceholder(i: number) {
        if (i < this.slotChar.length)
            return this.slotChar.charAt(i);

        return this.slotChar.charAt(0);
    }


    onMouseUp(_event: MouseEvent){
        this.selectChunk();
    }

    private selectChunk() {
        if (!(this.buffer?.length > 0))
            return;

        const pos = this.caret();
        if (!pos)
            return;
        const buffer = [...this.buffer];
        let startPos = 0;
        for (const separator of this.chunkSeparator) {
            const lastIndex = buffer.lastIndexOf(separator, pos.begin);
            if (lastIndex > startPos) startPos = lastIndex;
        }
        if (startPos > 0) startPos++;

        let endPos = buffer.length;
        for (const separator of this.chunkSeparator) {
            const index = buffer.indexOf(separator, pos.begin);
            if (index > -1 && index < endPos) endPos = index;
        }
        this.inputfieldViewChild.nativeElement.selectionStart = startPos;
        this.inputfieldViewChild.nativeElement.selectionEnd = endPos;
    }

    onInputKeydown(event: KeyboardEvent) {
        switch(event.key) {
            case "ArrowUp":
                event.preventDefault();
                this.increment();
                break;
            case "ArrowDown":
                event.preventDefault();
                this.decrement();
                break;
            case "ArrowLeft":
                event.preventDefault();
                this.goPrevBlock();
                break;
            case "ArrowRight":
                event.preventDefault();
                this.goNextBlock();
                break;
            case "Esc":
                if (this.overlayVisible) {
                    this.overlayVisible = false;
                    event.preventDefault();
                }
                break;
            case "Enter":
                if (this.overlayVisible) {
                    this.overlayVisible = false;
                    event.preventDefault();
                }
                break;
            case "Tab":
                if (this.disablePopup) break;
                DomHandler.getFocusableElements(this.contentViewChild.nativeElement).forEach((el) => (el.tabIndex = -1));
                if (this.overlayVisible)
                    this.overlayVisible = false;
                break;
            case "Backspace":
                const caret = this.caret();
                let offset = 1;
                for (let i = Math.max(caret.begin - offset, 0); i < caret.end; i++){
                    if (this.chunkSeparator.includes(this.buffer[i]))
                        offset = 2;
                    else
                        this.buffer[i] = this.slotChar;
                }
                this.writeBuffer();
                (event.target as HTMLInputElement).selectionStart = Math.max(caret.begin - offset - 1 , 0);
                (event.target as HTMLInputElement).selectionEnd = Math.max(caret.begin - offset, 0);
                this.selectChunk();
                event.preventDefault();
                break;
            default:
                this.isKeydown = true;
                break;
        }
    }

    private increment(){
        if (Array.isArray(this.value))
            return;

        if (!this.value) {
            const date = DateTime.now();
            if (!this.shouldSelectDate(date))
                return;
            this.selectDate(date);
            this.writeBuffer();
            this.updateUI();
            return;
        }
        const {block} = this.getBlock();
        if (!block) return;
        switch(block.type) {
            case "year":
                this.selectDate(this.value.plus({year: 1}));
                break;
            case "month":
                this.selectDate(this.value.set({month: (this.value.month % 12) + 1}));
                break;
            case "day":
                this.selectDate(this.value.set({day: (this.value.day + 1) % this.value.daysInMonth}));
                break;
            case "hour":
                this.selectDate(this.value.set({hour: (this.value.hour + 1) % 24}));
                break;
            case "minute":
                this.selectDate(this.value.set({minute: (this.value.minute + 1) % 60 }));
                break;
            case "second":
                this.selectDate(this.value.set({second: (this.value.second + 1) % 60}));
                break;
            case "dayPeriod":
                this.selectDate(this.value.set({hour: (this.value.hour + 12) % 24}));
        }
        this.writeBuffer();
        this.updateUI();
    }
    private decrement(){
        if (Array.isArray(this.value))
            return;

        if (!this.value) {
            const date = DateTime.now();
            if (!this.shouldSelectDate(date))
                return;
            this.selectDate(date);
            this.writeBuffer();
            this.updateUI();
            return;
        }
        const {block} = this.getBlock();
        if (!block) return;
        switch(block.type) {
            case "year":
                this.selectDate(this.value.minus({year: 1}));
                break;
            case "month":
                let month = this.value.month - 1;
                if (month < 1)
                    month = 12;
                this.selectDate(this.value.set({month}));
                break;
            case "day":
                let day = this.value.day - 1;
                if (day < 1)
                    day = this.value.daysInMonth;
                this.selectDate(this.value.set({day}));
                break;
            case "hour":
                let hour = this.value.hour - 1;
                if (hour < 0)
                    hour = 23;
                this.selectDate(this.value.set({hour}));
                break;
            case "minute":
                let minute = this.value.minute - 1;
                if (minute < 0)
                    minute = 59;
                this.selectDate(this.value.set({minute}));
                break;
            case "second":
                let second = this.value.second - 1;
                if (second < 0)
                    second = 59;
                this.selectDate(this.value.set({second}));
                break;
            case "dayPeriod":
                this.selectDate(this.value.set({hour: (this.value.hour + 12) % 24}));
        }
        this.writeBuffer();
        this.updateUI();
    }
    private goPrevBlock(){
        const {blockIndex} = this.getBlock();
        if (blockIndex < 0) return;
        let prevIndex = blockIndex;
        const end = this.parts.reduce((prev, curr, index) => {
            if (index < blockIndex - 1){
                prev += curr.value.length;
                prevIndex = index;
            }
            return prev;
        }, 0);
        this.inputfieldViewChild.nativeElement.selectionStart = end - this.parts[prevIndex].value.length;
        this.inputfieldViewChild.nativeElement.selectionEnd = end;
    }
    private goNextBlock(){
        const {blockIndex} = this.getBlock();
        if (blockIndex < 0) return;
        let prevIndex = blockIndex;
        const end = this.parts.reduce((prev, curr, index) => {
            if (index < Math.min(blockIndex + 3, this.parts.length)){
                prev += curr.value.length;
                prevIndex = index;
            }
            return prev;
        }, 0);
        this.inputfieldViewChild.nativeElement.selectionStart = end - this.parts[prevIndex].value.length;
        this.inputfieldViewChild.nativeElement.selectionEnd = end;
    }

    private getBlock(){
        const pos = this.caret();
        let blockIndex = 0;
        const block = this.parts[blockIndex];
        if (!block?.value)
            return {block: undefined, blockIndex: -1};
        let chars = block.value.length;
        while (chars <= pos.begin && blockIndex < this.parts.length - 1){
            blockIndex++;
            chars += this.parts[blockIndex].value.length;
        }
        return {block: this.parts[blockIndex], blockIndex};
    }

    onUserInput(event: Event) {
        if (!this.isKeydown)
            return;
        this.isKeydown = false;
        const val = (event.target as HTMLInputElement).value;
        this.parseUserInput(val);
    }

    parseUserInput(val: string) {
        try {
            const value = this.parseValueFromString(val);
            if (this.isValidSelection(value)) {
                this.updateModel(value);
                this.updateUI();
            }
        } catch(err) {}
        this.filled = val !== null && val.length;
        this.writeBuffer();
        this.onInput.emit(val);
    }

    onCopy(event: ClipboardEvent){
        if (this.value && !Array.isArray(this.value)){
            event.clipboardData.setData("text/plain", this.value.toISO());
            event.preventDefault();
        }
    }

    handleInputChange(event: ClipboardEvent) {
        if (this.asLabel)
            return;
        event.preventDefault();
        event.stopPropagation();
        this.parseUserInput(event.clipboardData.getData("text/plain"));
    }


    clearBuffer(start: number, end: number) {
        if (!this.keepBuffer) {
            let i;
            for (i = start; i < end && i < this.len; i++) {
                if (this.tests[i])
                    this.buffer[i] = this.getPlaceholder(i);

            }
        }
    }

    writeBuffer() {
        if (!this._mask)
            return;
        const pos = this.caret();
        this.inputfieldViewChild.nativeElement.value = this.buffer.join('');

        if (!pos)
            return;
        this.inputfieldViewChild.nativeElement.selectionStart = pos.begin;
        this.inputfieldViewChild.nativeElement.selectionEnd = pos.end;
    }

    caret(first?: number, last?: number) {
        let range; let begin; let end;
        if (!this.inputfieldViewChild.nativeElement.offsetParent ||
            this.inputfieldViewChild.nativeElement !== this.inputfieldViewChild.nativeElement.ownerDocument.activeElement)
            return;


        if (typeof first == 'number') {
            begin = first;
            end = typeof last === 'number' ? last : begin;
            if (this.inputfieldViewChild.nativeElement.setSelectionRange) {
                this.inputfieldViewChild.nativeElement.setSelectionRange(begin, end);
            } else if (this.inputfieldViewChild.nativeElement.createTextRange) {
                range = this.inputfieldViewChild.nativeElement.createTextRange();
                range.collapse(true);
                range.moveEnd('character', end);
                range.moveStart('character', begin);
                range.select();
            }
        } else {
            if (this.inputfieldViewChild.nativeElement.setSelectionRange) {
                begin = this.inputfieldViewChild.nativeElement.selectionStart;
                end = this.inputfieldViewChild.nativeElement.selectionEnd;
            } else {
                const selectedText = document.getSelection().toString();
                if (selectedText) {
                    begin = document.getSelection().anchorOffset;
                    end = begin + selectedText.length;
                }
            }

            return { begin, end };
        }
    }

    private datetimeToBuffer(date: DateTime): string[]{
        if (!this.chunkSeparator)
            return [];
        const exp = this.chunkSeparator.map(x => `\\${x.replace(' ', '\s')}`).join("|");
        const splitRegex = new RegExp(exp, "g");
        const format = date.toFormat(this.getDateFormat());
        const formatParts = format.split(splitRegex);

        const buffer = this.buffer.join('');
        const bufferParts = buffer.split(splitRegex);

        for (let i = 0; i < bufferParts.length; i++){
            const chars = bufferParts[i].split('');
            if (bufferParts[i].length === 2)
                formatParts[i] = formatParts[i]?.padStart(bufferParts[i].length, "0");
            else
                formatParts[i] = formatParts[i]?.padStart(bufferParts[i].length, "20");
            for (let c = 0; c < bufferParts[i].length && c < formatParts[i]?.length; c++)
                chars[c] = formatParts[i][c];
            bufferParts[i] = chars.join('');
        }

        let result = '';
        for (let index = 0; index < bufferParts.length; index++){
            result += bufferParts[index];
            if (this.chunkSeparator[index])
                result += this.chunkSeparator[index];
        }
        return result.split('');
    }

    onKeyPress(e: KeyboardEvent) {
        if (this.asLabel)
            return;
        if (!this._mask || this.tests.length === 0)
            return;

        const k = e.key;
        const caretPos = this.caret();
        let index;
        let next: number;

        if (e.ctrlKey || e.altKey || e.metaKey) {
            return;
        } else {
            if (caretPos.end - caretPos.begin !== 0)
                this.clearBuffer(caretPos.begin, caretPos.begin);

            if (this.chunkSeparator.includes(k)){
                next = this.buffer.findIndex((x, i) => this.chunkSeparator.includes(x) && i >= caretPos.begin) + 1;
                this.moveCursor(next);
                this.selectChunk();
                e.preventDefault();
                return;
            }
            index = this.seekNext(caretPos.begin - 1);
            if (index < this.len && k.length === 1) {
                if (this.tests[index].test(k)) {

                    this.buffer[index] = k;
                    this.writeBuffer();
                    next = this.seekNext(index);

                    this.moveCursor(next);
                    this.onInput.emit(e);
                }
            }
            e.preventDefault();
        }

        this.updateFilledState();

        this.parseUserInput(this.buffer.filter(x => x !== this.slotChar).join(''));
    }

    private moveCursor(next: number) {
        if (/android/i.test(DomHandler.getUserAgent())) {
            //Path for CSP Violation on FireFox OS 1.1
            const proxy = () => {
                this.caret(next);
            };

            setTimeout(proxy, 0);
        } else {
            this.caret(next);
        }
    }

    shiftL(begin: number, end: number) {
        let i; let j;

        if (begin < 0)
            return;


        for (i = begin, j = this.seekNext(end); i < this.len; i++) {
            if (this.tests[i]) {
                if (j < this.len && this.tests[i].test(this.buffer[j])) {
                    this.buffer[i] = this.buffer[j];
                    this.buffer[j] = this.getPlaceholder(j);
                } else {
                    break;
                }

                j = this.seekNext(j);
            }
        }
        this.writeBuffer();
        this.caret(Math.max(this.firstNonMaskPos, begin));
    }

    seekNext(pos: number) {
        while (++pos < this.len && !this.tests[pos]);
        return pos;
    }

    isCompleted(): boolean {
        for (let i = this.firstNonMaskPos; i <= this.lastRequiredNonMaskPos; i++) {
            if (this.tests[i] && this.buffer[i] === this.getPlaceholder(i))
                return false;
        }

        return true;
    }
    //#endregion


    onContainerButtonKeydown(event: KeyboardEvent) {
        switch(event.which) {
            //tab
            case 9:
                if (!this.inline)
                    this.trapFocus(event);

                break;

            //escape
            case 27:
                this.overlayVisible = false;
                event.preventDefault();
                break;

            default:
                //Noop
                break;
        }
    }

    onDateCellKeydown(event: KeyboardEvent, date: DateTime, groupIndex: number) {
        const cellContent = event.currentTarget as HTMLElement;
        const cell = cellContent.parentElement;

        switch(event.which) {
            //down arrow
            case 40: {
                cellContent.tabIndex = -1;
                const cellIndex = DomHandler.index(cell);
                const nextRow = cell.parentElement.nextElementSibling;
                if (nextRow) {
                    const focusCell = nextRow.children[cellIndex].children[0];
                    if (DomHandler.hasClass(focusCell, 'p-disabled')) {
                        this.navigationState = { backward: false };
                        this.navForward(event);
                    } else {
                        (nextRow.children[cellIndex].children[0] as HTMLElement).tabIndex = 0;
                        (nextRow.children[cellIndex].children[0] as HTMLElement).focus();
                    }
                } else {
                    this.navigationState = { backward: false };
                    this.navForward(event);
                }
                event.preventDefault();
                break;
            }

            //up arrow
            case 38: {
                cellContent.tabIndex = -1;
                const cellIndex = DomHandler.index(cell);
                const prevRow = cell.parentElement.previousElementSibling;
                if (prevRow) {
                    const focusCell = prevRow.children[cellIndex].children[0] as HTMLElement;
                    if (DomHandler.hasClass(focusCell, 'p-disabled')) {
                        this.navigationState = { backward: true };
                        this.navBackward(event);
                    } else {
                        focusCell.tabIndex = 0;
                        focusCell.focus();
                    }
                } else {
                    this.navigationState = { backward: true };
                    this.navBackward(event);
                }
                event.preventDefault();
                break;
            }

            //left arrow
            case 37: {
                cellContent.tabIndex = -1;
                const prevCell = cell.previousElementSibling;
                if (prevCell) {
                    const focusCell = prevCell.children[0] as HTMLElement;
                    if (DomHandler.hasClass(focusCell, 'p-disabled') || DomHandler.hasClass(focusCell.parentElement, 'p-datepicker-weeknumber')) {
                        this.navigateToMonth(true, groupIndex);
                    } else {
                        focusCell.tabIndex = 0;
                        focusCell.focus();
                    }
                } else {
                    this.navigateToMonth(true, groupIndex);
                }
                event.preventDefault();
                break;
            }

            //right arrow
            case 39: {
                cellContent.tabIndex = -1;
                const nextCell = cell.nextElementSibling;
                if (nextCell) {
                    const focusCell = nextCell.children[0] as HTMLElement;
                    if (DomHandler.hasClass(focusCell, 'p-disabled')) {
                        this.navigateToMonth(false, groupIndex);
                    } else {
                        focusCell.tabIndex = 0;
                        focusCell.focus();
                    }
                } else {
                    this.navigateToMonth(false, groupIndex);
                }
                event.preventDefault();
                break;
            }

            //enter
            //space
            case 13:
            case 32: {
                this.onDateSelect(event, date);
                event.preventDefault();
                break;
            }

            //escape
            case 27: {
                this.overlayVisible = false;
                event.preventDefault();
                break;
            }

            //tab
            case 9: {
                if (!this.inline)
                    this.trapFocus(event);

                break;
            }

            default:
                //no op
                break;
        }
    }

    onMonthCellKeydown(event: KeyboardEvent, index: number) {
        const cell = event.currentTarget as HTMLElement;
        switch(event.which) {
            //arrows
            case 38:
            case 40: {
                cell.tabIndex = -1;
                const cells = cell.parentElement.children;
                const cellIndex = DomHandler.index(cell);
                const nextCell = cells[event.which === 40 ? cellIndex + 3 : cellIndex - 3] as HTMLElement;
                if (nextCell) {
                    nextCell.tabIndex = 0;
                    nextCell.focus();
                }
                event.preventDefault();
                break;
            }

            //left arrow
            case 37: {
                cell.tabIndex = -1;
                const prevCell = cell.previousElementSibling as HTMLElement;
                if (prevCell) {
                    prevCell.tabIndex = 0;
                    prevCell.focus();
                } else {
                    this.navigationState = { backward: true };
                    this.navBackward(event);
                }

                event.preventDefault();
                break;
            }

            //right arrow
            case 39: {
                cell.tabIndex = -1;
                const nextCell = cell.nextElementSibling as HTMLElement;
                if (nextCell) {
                    nextCell.tabIndex = 0;
                    nextCell.focus();
                } else {
                    this.navigationState = { backward: false };
                    this.navForward(event);
                }

                event.preventDefault();
                break;
            }

            //enter
            case 13: {
                this.onMonthSelect(event, index);
                event.preventDefault();
                break;
            }

            //enter
            //space
            case 13:
            case 32: {
                this.overlayVisible = false;
                event.preventDefault();
                break;
            }

            //escape
            case 27: {
                this.overlayVisible = false;
                event.preventDefault();
                break;
            }

            //tab
            case 9: {
                if (!this.inline)
                    this.trapFocus(event);

                break;
            }

            default:
                //no op
                break;
        }
    }

    onYearCellKeydown(event: KeyboardEvent, index: number) {
        const cell = event.currentTarget as HTMLElement;

        switch(event.which) {
            //arrows
            case 38:
            case 40: {
                cell.tabIndex = -1;
                const cells = cell.parentElement.children;
                const cellIndex = DomHandler.index(cell);
                const nextCell = cells[event.which === 40 ? cellIndex + 2 : cellIndex - 2] as HTMLElement;
                if (nextCell) {
                    nextCell.tabIndex = 0;
                    nextCell.focus();
                }
                event.preventDefault();
                break;
            }

            //left arrow
            case 37: {
                cell.tabIndex = -1;
                const prevCell = cell.previousElementSibling as HTMLElement;
                if (prevCell) {
                    prevCell.tabIndex = 0;
                    prevCell.focus();
                } else {
                    this.navigationState = { backward: true };
                    this.navBackward(event);
                }

                event.preventDefault();
                break;
            }

            //right arrow
            case 39: {
                cell.tabIndex = -1;
                const nextCell = cell.nextElementSibling as HTMLElement;
                if (nextCell) {
                    nextCell.tabIndex = 0;
                    nextCell.focus();
                } else {
                    this.navigationState = { backward: false };
                    this.navForward(event);
                }

                event.preventDefault();
                break;
            }

            //enter
            //space
            case 13:
            case 32: {
                this.onYearSelect(event, index);
                event.preventDefault();
                break;
            }

            //escape
            case 27: {
                this.overlayVisible = false;
                event.preventDefault();
                break;
            }

            //tab
            case 9: {
                this.trapFocus(event);
                break;
            }

            default:
                //no op
                break;
        }
    }

    navigateToMonth(prev: boolean, groupIndex: number) {
        if (prev) {
            if (this.numberOfMonths === 1 || groupIndex === 0) {
                this.navigationState = { backward: true };
                this.navBackward(event);
            } else {
                const prevMonthContainer = this.contentViewChild.nativeElement.children[groupIndex - 1];
                const cells = DomHandler.find(prevMonthContainer, '.p-datepicker-calendar td span:not(.p-disabled):not(.p-ink)');
                const focusCell = cells[cells.length - 1];
                focusCell.tabIndex = 0;
                focusCell.focus();
            }
        } else {
            if (this.numberOfMonths === 1 || groupIndex === this.numberOfMonths - 1) {
                this.navigationState = { backward: false };
                this.navForward(event);
            } else {
                const nextMonthContainer = this.contentViewChild.nativeElement.children[groupIndex + 1];
                const focusCell = DomHandler.findSingle(nextMonthContainer, '.p-datepicker-calendar td span:not(.p-disabled):not(.p-ink)');
                focusCell.tabIndex = 0;
                focusCell.focus();
            }
        }
    }

    updateFocus() {
        let cell;

        if (this.navigationState) {
            if (this.navigationState.button) {
                this.initFocusableCell();

                if (this.navigationState.backward) DomHandler.findSingle(this.contentViewChild.nativeElement, '.p-datepicker-prev').focus();
                else DomHandler.findSingle(this.contentViewChild.nativeElement, '.p-datepicker-next').focus();
            } else {
                if (this.navigationState.backward) {
                    let cells;

                    if (this.currentView === 'month')
                        cells = DomHandler.find(this.contentViewChild.nativeElement, '.p-monthpicker .p-monthpicker-month:not(.p-disabled)');
                     else if (this.currentView === 'year')
                        cells = DomHandler.find(this.contentViewChild.nativeElement, '.p-yearpicker .p-yearpicker-year:not(.p-disabled)');
                     else
                        cells = DomHandler.find(this.contentViewChild.nativeElement, '.p-datepicker-calendar td span:not(.p-disabled):not(.p-ink)');


                    if (cells && cells.length > 0)
                        cell = cells[cells.length - 1];

                } else {
                    if (this.currentView === 'month')
                        cell = DomHandler.findSingle(this.contentViewChild.nativeElement, '.p-monthpicker .p-monthpicker-month:not(.p-disabled)');
                     else if (this.currentView === 'year')
                        cell = DomHandler.findSingle(this.contentViewChild.nativeElement, '.p-yearpicker .p-yearpicker-year:not(.p-disabled)');
                     else
                        cell = DomHandler.findSingle(this.contentViewChild.nativeElement, '.p-datepicker-calendar td span:not(.p-disabled):not(.p-ink)');

                }

                if (cell) {
                    cell.tabIndex = 0;
                    cell.focus();
                }
            }

            this.navigationState = null;
        } else {
            this.initFocusableCell();
        }
    }

    initFocusableCell() {
        const contentEl = this.contentViewChild?.nativeElement;
        let cell: HTMLElement;

        if (this.currentView === 'month') {
            const cells: HTMLElement[] = DomHandler.find(contentEl, '.p-monthpicker .p-monthpicker-month:not(.p-disabled)');
            const selectedCell = DomHandler.findSingle(contentEl, '.p-monthpicker .p-monthpicker-month.p-highlight');
            cells.forEach((c) => (c.tabIndex = -1));
            cell = selectedCell || cells[0];

            if (cells.length === 0) {
                const disabledCells = DomHandler.find(contentEl, '.p-monthpicker .p-monthpicker-month.p-disabled[tabindex = "0"]');
                disabledCells.forEach((c) => (c.tabIndex = -1));
            }
        } else if (this.currentView === 'year') {
            const cells = DomHandler.find(contentEl, '.p-yearpicker .p-yearpicker-year:not(.p-disabled)');
            const selectedCell = DomHandler.findSingle(contentEl, '.p-yearpicker .p-yearpicker-year.p-highlight');
            cells.forEach((c) => (c.tabIndex = -1));
            cell = selectedCell || cells[0];

            if (cells.length === 0) {
                const disabledCells = DomHandler.find(contentEl, '.p-yearpicker .p-yearpicker-year.p-disabled[tabindex = "0"]');
                disabledCells.forEach((c) => (c.tabIndex = -1));
            }
        } else {
            cell = DomHandler.findSingle(contentEl, 'span.p-highlight');
            if (!cell) {
                const todayCell = DomHandler.findSingle(contentEl, 'td.p-datepicker-today span:not(.p-disabled):not(.p-ink)');
                if (todayCell) cell = todayCell;
                else cell = DomHandler.findSingle(contentEl, '.p-datepicker-calendar td span:not(.p-disabled):not(.p-ink)');
            }
        }

        if (cell) {
            cell.tabIndex = 0;

            if (!this.preventFocus && (!this.navigationState || !this.navigationState.button)) {
                setTimeout(() => {
                    if (!this.disabled)
                        cell.focus();

                }, 1);
            }

            this.preventFocus = false;
        }
    }

    trapFocus(event: KeyboardEvent) {
        const focusableElements = DomHandler.getFocusableElements(this.contentViewChild.nativeElement);

        if (focusableElements && focusableElements.length > 0) {
            if (!focusableElements[0].ownerDocument.activeElement) {
                focusableElements[0].focus();
            } else {
                const focusedIndex = focusableElements.indexOf(focusableElements[0].ownerDocument.activeElement);

                if (event.shiftKey) {
                    if (focusedIndex === -1 || focusedIndex === 0) {
                        if (this.focusTrap) {
                            focusableElements[focusableElements.length - 1].focus();
                        } else {
                            if (focusedIndex === -1) return this.hideOverlay();
                            else if (focusedIndex === 0) return;
                        }
                    } else {
                        focusableElements[focusedIndex - 1].focus();
                    }
                } else {
                    if (focusedIndex === -1 || focusedIndex === focusableElements.length - 1) {
                        if (!this.focusTrap && focusedIndex !== -1) return this.hideOverlay();
                        else focusableElements[0].focus();
                    } else {
                        focusableElements[focusedIndex + 1].focus();
                    }
                }
            }
        }

        event.preventDefault();
    }

    onMonthDropdownChange(m: string) {
        this.currentMonth = parseInt(m, 10);
        this.onMonthChange.emit({ month: this.currentMonth + 1, year: this.currentYear });
        this.createMonths(this.currentMonth, this.currentYear);
    }

    onYearDropdownChange(y: string) {
        this.currentYear = parseInt(y, 10);
        this.onYearChange.emit({ month: this.currentMonth + 1, year: this.currentYear });
        this.createMonths(this.currentMonth, this.currentYear);
    }

    convertTo24Hour(hours: number, pm: boolean) {
        if (this.hourFormat === '12') {
            if (hours === 12)
                return pm ? 12 : 0;
             else
                return pm ? hours + 12 : hours;

        }
        return hours;
    };

    validateTime(hour: number, minute: number, second: number, pm: boolean) {
        let value = this.value;
        const convertedHour = this.convertTo24Hour(hour, pm);
        if (this.isRangeSelection() && Array.isArray(this.value))
            value = this.value[1] || this.value[0];

        if (this.isMultipleSelection() && Array.isArray(this.value))
            value = this.value[this.value.length - 1];
        value = value as DateTime;
        const valueDateString = value ? value.toISO() : null;
        if (this.minDate && valueDateString && this.minDate.toISO() === valueDateString) {
            if (this.minDate.hour > convertedHour)
                return false;

            if (this.minDate.hour === convertedHour) {
                if (this.minDate.minute > minute)
                    return false;

                if (this.minDate.minute === minute) {
                    if (this.minDate.second > second)
                        return false;

                }
            }
        }

        if (this.maxDate && valueDateString && this.maxDate.toISO() === valueDateString) {
            if (this.maxDate.hour < convertedHour)
                return false;

            if (this.maxDate.hour === convertedHour) {
                if (this.maxDate.minute < minute)
                    return false;

                if (this.maxDate.minute === minute) {
                    if (this.maxDate.second < second)
                        return false;

                }
            }
        }
        return true;
    }

    onTimePickerElementMouseDown(event: Event, type: number, direction: number) {
        if (!this.disabled) {
            this.repeat(event, null, type, direction);
            event.preventDefault();
        }
    }

    onTimePickerElementMouseUp(event: Event) {
        if (!this.disabled) {
            this.clearTimePickerTimer();
            this.updateTime();
        }
    }

    onTimePickerElementMouseLeave() {
        if (!this.disabled && this.timePickerTimer) {
            this.clearTimePickerTimer();
            this.updateTime();
        }
    }

    repeat(event: Event, interval: number, type: number, direction: number) {
        const i = interval || 500;

        this.clearTimePickerTimer();
        this.timePickerTimer = setTimeout(() => {
            this.repeat(event, 100, type, direction);
            this.cd.markForCheck();
        }, i) as unknown as number;

        switch(type) {
            case 0:
                if (direction === 1)this.incrementHour(event);
                else this.decrementHour(event);
                break;

            case 1:
                if (direction === 1)this.incrementMinute(event);
                else this.decrementMinute(event);
                break;

            case 2:
                if (direction === 1)this.incrementSecond(event);
                else this.decrementSecond(event);
                break;
        }

        this.updateInputfield();
    }

    clearTimePickerTimer() {
        if (this.timePickerTimer) {
            clearTimeout(this.timePickerTimer);
            this.timePickerTimer = null;
        }
    }

    incrementHour(event: Event) {
        const prevHour = this.currentHour;
        let newHour = this.currentHour + this.stepHour;
        let newPM = this.pm;

        if (this.hourFormat === '24') { newHour = newHour >= 24 ? newHour - 24 : newHour; }
        else if (this.hourFormat === '12') {
            // Before the AM/PM break, now after
            if (prevHour < 12 && newHour > 11)
                newPM = !this.pm;

            newHour = newHour >= 13 ? newHour - 12 : newHour;
        }

        if (this.validateTime(newHour, this.currentMinute, this.currentSecond, newPM)) {
            this.currentHour = newHour;
            this.pm = newPM;
            if (!Array.isArray(this.value))
                this.updateModel((this.value ?? DateTime.now()).set({hour: this.convertTo24Hour(this.currentHour, this.pm)}));
        }
        event.preventDefault();
    }

    decrementHour(event: Event) {
        let newHour = this.currentHour - this.stepHour;
        let newPM = this.pm;

        if (this.hourFormat === '24') { newHour = newHour < 0 ? 24 + newHour : newHour; }
        else if (this.hourFormat === '12') {
            // If we were at noon/midnight, then switch
            if (this.currentHour === 12)
                newPM = !this.pm;

            newHour = newHour <= 0 ? 12 + newHour : newHour;
        }

        if (this.validateTime(newHour, this.currentMinute, this.currentSecond, newPM)) {
            this.currentHour = newHour;
            this.pm = newPM;
            if (!Array.isArray(this.value))
                this.updateModel((this.value ?? DateTime.now()).set({hour: this.convertTo24Hour(this.currentHour, this.pm)}));
        }

        event.preventDefault();
    }

    incrementMinute(event: Event) {
        let newMinute = this.currentMinute + this.stepMinute;
        newMinute = newMinute > 59 ? newMinute - 60 : newMinute;
        if (this.validateTime(this.currentHour, newMinute, this.currentSecond, this.pm)){
            this.currentMinute = newMinute;
            if (!Array.isArray(this.value))
                this.updateModel((this.value ?? DateTime.now()).set({minute: this.currentMinute}));
        }


        event.preventDefault();
    }

    decrementMinute(event: Event) {
        let newMinute = this.currentMinute - this.stepMinute;
        newMinute = newMinute < 0 ? 60 + newMinute : newMinute;
        if (this.validateTime(this.currentHour, newMinute, this.currentSecond, this.pm)){
            this.currentMinute = newMinute;
            if (!Array.isArray(this.value))
                this.updateModel((this.value ?? DateTime.now()).set({minute: this.currentMinute}));
        }


        event.preventDefault();
    }

    incrementSecond(event: Event) {
        let newSecond = this.currentSecond + this.stepSecond;
        newSecond = newSecond > 59 ? newSecond - 60 : newSecond;
        if (this.validateTime(this.currentHour, this.currentMinute, newSecond, this.pm)){
            this.currentSecond = newSecond;
            if (!Array.isArray(this.value))
                this.updateModel((this.value ?? DateTime.now()).set({second: this.currentSecond}));
        }


        event.preventDefault();
    }

    decrementSecond(event: Event) {
        let newSecond = this.currentSecond - this.stepSecond;
        newSecond = newSecond < 0 ? 60 + newSecond : newSecond;
        if (this.validateTime(this.currentHour, this.currentMinute, newSecond, this.pm)){
            this.currentSecond = newSecond;
            if (!Array.isArray(this.value))
                this.updateModel((this.value ?? DateTime.now()).set({second: this.currentSecond}));
        }


        event.preventDefault();
    }

    updateTime() {
        let value: DateTime | DateTime[];
        if (this.isRangeSelection() && Array.isArray(this.value))
            value = this.value[1] || this.value[0];
        else if (this.isMultipleSelection() && Array.isArray(this.value))
            value = this.value[this.value.length - 1];
        else
            value = this.value as DateTime;
        value = value ? value : DateTime.now();

        if (!Array.isArray(value)){
            value = value.set({
                hour:   this.convertTo24Hour(this.currentHour, this.pm),
                minute: this.currentMinute,
                second: this.currentSecond
            });
        }
        if (this.isRangeSelection() && Array.isArray(this.value)) {
            if (this.value[1]) value = [this.value[0], value];
            else value = [value, null];
        }

        if (this.isMultipleSelection() && Array.isArray(this.value) && !Array.isArray(value))
            value = [...this.value.slice(0, -1), value];

        this.updateModel(value);
        this.onSelect.emit(value);
        this.updateInputfield();
    }

    toggleAMPM(event: Event) {
        const newPM = !this.pm;
        if (this.validateTime(this.currentHour, this.currentMinute, this.currentSecond, newPM)) {
            this.pm = newPM;
            this.updateTime();
        }
        event.preventDefault();
    }


    isValidSelection(value: Value): boolean {
        let isValid = true;
        if (this.isSingleSelection() && !Array.isArray(value)) {
            if (!this.isSelectable(value.day, value.month, value.year, false) || !value.isValid)
                isValid = false;

        } else if (Array.isArray(value) && value.every((v: { day: number, month: number, year: number }) => this.isSelectable(v.day, v.month, v.year, false))) {
            if (this.isRangeSelection())
                isValid = value.length > 1 && value[1] > value[0] ? !value.find(x => !x.isValid) : false;

        }
        return isValid;
    }

    parseValueFromString(text: string): Value {
        if (!text || text.trim().length === 0)
            return null;


        let value: Value;

        if (this.isSingleSelection()) {
            value = this.parseDateTime(text);
        } else if (this.isMultipleSelection()) {
            const tokens = text.split(this.multipleSeparator);
            value = [];
            for (const token of tokens)
                value.push(this.parseDateTime(token.trim()));

        } else if (this.isRangeSelection()) {
            const tokens = text.split(' ' + this.rangeSeparator + ' ');
            value = [];
            for (let i = 0; i < tokens.length; i++)
                value[i] = this.parseDateTime(tokens[i].trim());

        }

        return value;
    }

    parseDateTime(text: string): DateTime {
        let date = DateTime.fromISO(text);
        if (!date.isValid)
            date = DateTime.fromFormat(text, this.getDateFormat());
        return date;
    }

    populateTime(value: DateTime, timeString: string, ampm: string) {
        if (this.hourFormat === '12' && !ampm)
            throw new Error('Invalid Time');


        this.pm = ampm === 'PM' || ampm === 'pm';
        const time = this.parseTime(timeString);
        value = value.set({
            ...time
        });
    }

    isValidDate(date: DateTime) {
        return DateTime.isDateTime(date) && date.isValid;
    }

    updateUI() {
        let propValue = this.value;
        if (Array.isArray(propValue))
            propValue = propValue[0];


        const val = this.defaultDate
            && this.isValidDate(this.defaultDate)
            && !this.value ? this.defaultDate : propValue
            && this.isValidDate(propValue) ? propValue : DateTime.now();

        this.currentMonth = val.month;
        this.currentYear = val.year;
        this.createMonths(this.currentMonth, this.currentYear);

        if (this.showTime || this.timeOnly) {
            this.setCurrentHourPM(val.hour);
            this.currentMinute = val.minute;
            this.currentSecond = val.second;
        }
    }

    showOverlay() {
        if (this.disablePopup)
            return;
        if (!this.overlayVisible) {
            this.updateUI();

            if (!this.touchUI)
                this.preventFocus = true;

            //TODO: calculate bounds and make it start either left or right 

            this.overlayVisible = true;
        }
    }

    hideOverlay() {
        this.overlayVisible = false;
        this.clearTimePickerTimer();

        if (this.touchUI)
            this.disableModality();


        this.cd.markForCheck();
    }

    toggle() {
        if (!this.inline) {
            if (!this.overlayVisible) {
                this.showOverlay();
                this.inputfieldViewChild.nativeElement.focus();
            } else {
                this.hideOverlay();
            }
        }
    }

    onOverlayAnimationStart(event: AnimationEvent) {
        switch(event.toState) {
            case 'visible':
            case 'visibleTouchUI':
                if (!this.inline) {
                    this.overlay = event.element;
                    this.overlay.setAttribute(this.attributeSelector, '');
                    this.appendOverlay();
                    this.updateFocus();
                    if (this.autoZIndex) {
                        if (this.touchUI) ZIndexUtils.set('modal', this.overlay, this.baseZIndex || this.config.zIndex.modal);
                        else ZIndexUtils.set('overlay', this.overlay, this.baseZIndex || this.config.zIndex.overlay);
                    }

                    this.alignOverlay();
                    this.onShow.emit(event);
                }
                break;

            case 'void':
                this.onOverlayHide();
                this.onClose.emit(event);
                break;
        }
    }

    onOverlayAnimationDone(event: AnimationEvent) {
        switch(event.toState) {
            case 'visible':
            case 'visibleTouchUI':
                if (!this.inline) {
                    this.bindDocumentClickListener();
                    this.bindDocumentResizeListener();
                    this.bindScrollListener();
                }
                break;

            case 'void':
                if (this.autoZIndex)
                    ZIndexUtils.clear(event.element);

                break;
        }
    }

    appendOverlay() {
        if (this.appendTo) {
            if (this.appendTo === 'body') document.body.appendChild(this.overlay);
            else DomHandler.appendChild(this.overlay, this.appendTo);
        }
    }

    restoreOverlayAppend() {
        if (this.overlay && this.appendTo)
            this.el.nativeElement.appendChild(this.overlay);

    }

    alignOverlay() {
        if (this.touchUI) {
            this.enableModality(this.overlay);
        } else if (this.overlay) {
            const element = this.asLabel ? this.labelViewChild.nativeElement : this.inputfieldViewChild.nativeElement;
            if (this.appendTo) {
                if (this.view === 'date') {
                    this.overlay.style.width = DomHandler.getOuterWidth(this.overlay) + 'px';
                    this.overlay.style.minWidth = DomHandler.getOuterWidth(element) + 'px';
                } else {
                    this.overlay.style.width = DomHandler.getOuterWidth(element) + 'px';
                }

                DomHandler.absolutePosition(this.overlay, element);
            } else {
                DomHandler.relativePosition(this.overlay, element);
            }
        }
    }

    enableModality(element: HTMLElement) {
        if (!this.maskOverlay && !this.touchUI) {
            this.maskOverlay = document.createElement('div');
            this.maskOverlay.style.zIndex = String(parseInt(element.style.zIndex, 10) - 1);
            const maskStyleClass = 'p-component-overlay p-datepicker-mask p-datepicker-mask-scrollblocker p-component-overlay p-component-overlay-enter';
            DomHandler.addMultipleClasses(this.maskOverlay, maskStyleClass);

            this.maskOverlayClickListener = this.renderer.listen(this.maskOverlay, 'click', () => {
                this.disableModality();
            });
            document.body.appendChild(this.maskOverlay);
            DomHandler.addClass(document.body, 'p-overflow-hidden');
        }
    }

    disableModality() {
        if (this.maskOverlay) {
            DomHandler.addClass(this.maskOverlay, 'p-component-overlay-leave');
            this.animationEndListener = this.destroyMask.bind(this);
            this.maskOverlay.addEventListener('animationend', this.animationEndListener);
        }
    }

    destroyMask() {
        if (!this.maskOverlay)
            return;


        document.body.removeChild(this.maskOverlay);
        const bodyChildren = document.body.children;
        let hasBlockerMasks: boolean;
        for (let i = 0; i < bodyChildren.length; i++) {
            const bodyChild = bodyChildren[i];
            if (DomHandler.hasClass(bodyChild, 'p-datepicker-mask-scrollblocker')) {
                hasBlockerMasks = true;
                break;
            }
        }

        if (!hasBlockerMasks)
            DomHandler.removeClass(document.body, 'p-overflow-hidden');


        this.unbindAnimationEndListener();
        this.unbindMaskClickListener();
        this.maskOverlay = null;
    }

    unbindMaskClickListener() {
        if (this.maskOverlayClickListener) {
            this.maskOverlayClickListener();
            this.maskOverlayClickListener = null;
        }
    }

    unbindAnimationEndListener() {
        if (this.animationEndListener && this.maskOverlay) {
            this.maskOverlay.removeEventListener('animationend', this.animationEndListener);
            this.animationEndListener = null;
        }
    }

    writeValue(value: Value): void {
        if (Array.isArray(value))
            value.forEach(x => x = x.setLocale(this.service.locale));
        else
            value = value?.setLocale(this.service.locale);
        this.value = value;
        if (this.value && typeof this.value === 'string') {
            try {
                this.value = this.parseValueFromString(this.value);
            } catch{
                if (this.keepInvalid)
                    this.value = value;

            }
        }

        this.updateInputfield();
        this.updateUI();
        this.cd.markForCheck();
    }

    registerOnChange(fn: Func): void {
        this.onModelChange = fn;
    }

    registerOnTouched(fn: Func): void {
        this.onModelTouched = fn;
    }

    setDisabledState(val: boolean): void {
        this.disabled = val;
        this.cd.markForCheck();
    }

    /**
     * @TODO: issue with 12/24h format. Somehow defaults to 12h format
     * @Remark parsing time format with locale will result in 12h time regardless of locale
     */
    getDateFormat() {
        let format = this.dateFormat;
        if (!format){
            if (this.timeOnly){
                if (this.hourFormat === "12"){
                    if (this.showSeconds)
                        format = "h:mm:ss a";//DateTime.parseFormatForOpts(DateTime.TIME_WITH_SECONDS);
                    else
                        format = "h:mm a";//DateTime.parseFormatForOpts(DateTime.TIME_SIMPLE);
                } else {
                    if (this.showSeconds)
                        format = "HH:mm:ss";//DateTime.parseFormatForOpts(DateTime.TIME_24_WITH_SECONDS);
                    else
                        format = "HH:mm";//DateTime.parseFormatForOpts(DateTime.TIME_24_SIMPLE);
                }
            } else if (this.showTime){
                if (this.hourFormat === "12"){
                    if (this.showSeconds){
                        format = DateTime.parseFormatForOpts(DateTime.DATE_SHORT, {locale: this.service.locale}).replace("yyyyy", "yyyy")
                            + ", h:mm:ss a";
                    } else {
                        format = DateTime.parseFormatForOpts(DateTime.DATE_SHORT, {locale: this.service.locale}).replace("yyyyy", "yyyy")
                            + ", h:mm a";
                    }
                } else {
                    if (this.showSeconds){
                        format = DateTime.parseFormatForOpts(DateTime.DATE_SHORT, {locale: this.service.locale}).replace("yyyyy", "yyyy")
                            + ", HH:mm:ss";
                    } else {
                        format = DateTime.parseFormatForOpts(DateTime.DATE_SHORT, {locale: this.service.locale}).replace("yyyyy", "yyyy")
                            + ", HH:mm";
                    }
                }
            } else {
                format = DateTime.parseFormatForOpts(DateTime.DATE_SHORT, {locale: this.service.locale}).replace("yyyyy", "yyyy");
            }
        }
        if (typeof format === "object")
            return DateTime.parseFormatForOpts(format, {locale: this.service.locale}).replace("yyyyy", "yyyy");
        return format;
    }

    getIntl(locale: string) {
        if (typeof this.dateFormat === "object") {
            const format = Object.fromEntries(Object.entries(this.dateFormat).map(x => {
                if (x[1] === "numeric") x[1] = "2-digit";
                return x;
            }));
            return new Intl.DateTimeFormat(locale, format);
        }

        if (this.timeOnly) {
            return new Intl.DateTimeFormat(locale, {
                hour12: this.hourFormat === "12",
                hour:   "2-digit",
                minute: "2-digit",
                second: this.showSeconds ? "2-digit" : undefined
            });
        }

        return new Intl.DateTimeFormat(locale, {
            hour12: this.showTime && this.hourFormat === "12",
            hour:   this.showTime ? "2-digit" : undefined,
            minute: this.showTime ? "2-digit" : undefined,
            second: this.showTime && this.showSeconds ? "2-digit" : undefined,
            day:    "2-digit",
            month:  "2-digit",
            year:   "numeric"
        });
    }

    getFirstDateOfWeek() {
        return this._firstDayOfWeek || this.getTranslation(TranslationKeys.FIRST_DAY_OF_WEEK);
    }

    parseTime(value: string) {
        const tokens: string[] = value.split(':');
        const validTokenLength = this.showSeconds ? 3 : 2;

        if (tokens.length !== validTokenLength)
            throw new Error('Invalid time');


        let h = parseInt(tokens[0], 10);
        const m = parseInt(tokens[1], 10);
        const s = this.showSeconds ? parseInt(tokens[2], 10) : null;

        if (isNaN(h) || isNaN(m) || h > 23 || m > 59 || (this.hourFormat === '12' && h > 12) || (this.showSeconds && (isNaN(s) || s > 59))) {
            throw new Error('Invalid time');
        } else {
            if (this.hourFormat === '12') {
                if (h !== 12 && this.pm)
                    h += 12;
                 else if (!this.pm && h === 12)
                    h -= 12;

            }

            return { hour: h, minute: m, second: s };
        }
    }

    // Ported from jquery-ui datepicker parseDate
    parseDate(value: string, format: string | Intl.DateTimeFormatOptions) {
        if (format === null || value === null)
            throw new Error('Invalid time');


        value = typeof value === 'object' ? (value as object).toString() : value + '';
        if (value === '')
            return null;
        if (typeof format === "string")
            return DateTime.fromFormat(value, format);
        const formatString = new Intl.DateTimeFormat(undefined, format).formatToParts().map(part => part.value).join('');
        return DateTime.fromFormat(value, formatString);
    }

    updateFilledState() {
        this.filled = this.inputFieldValue && this.inputFieldValue !== '';
    }

    onTodayButtonClick(event: Event) {
        const date: DateTime = DateTime.now();
        const dateMeta = {
            day:        date.day,
            month:      date.month,
            year:       date.year,
            otherMonth: date.month !== this.currentMonth || date.year !== this.currentYear,
            today:      true, selectable: true
        };

        this.onDateSelect(event, dateMeta);
        this.onTodayClick.emit(event);
    }

    onClearButtonClick(event: Event) {
        this.clear();
        this.hideOverlay();
    }

    createResponsiveStyle() {
        if (this.numberOfMonths > 1 && this.responsiveOptions) {
            if (!this.responsiveStyleElement) {
                this.responsiveStyleElement = document.createElement('style');
                this.responsiveStyleElement.type = 'text/css';
                document.body.appendChild(this.responsiveStyleElement);
            }

            let innerHTML = '';
            if (this.responsiveOptions) {
                const responsiveOptions = [...this.responsiveOptions]
                    .filter((o) => !!(o.breakpoint && o.numMonths))
                    .sort((o1, o2) => -1 * o1.breakpoint.localeCompare(o2.breakpoint, undefined, { numeric: true }));

                for (let i = 0; i < responsiveOptions.length; i++) {
                    const { breakpoint, numMonths } = responsiveOptions[i];
                    let styles = `
                        .p-datepicker[${this.attributeSelector}] .p-datepicker-group:nth-child(${numMonths}) .p-datepicker-next {
                            display: inline-flex !important;
                        }
                    `;

                    for (let j = numMonths; j < this.numberOfMonths; j++) {
                        styles += `
                            .p-datepicker[${this.attributeSelector}] .p-datepicker-group:nth-child(${j + 1}) {
                                display: none !important;
                            }
                        `;
                    }

                    innerHTML += `
                        @media screen and (max-width: ${breakpoint}) {
                            ${styles}
                        }
                    `;
                }
            }

            this.responsiveStyleElement.innerHTML = innerHTML;
        }
    }

    destroyResponsiveStyleElement() {
        if (this.responsiveStyleElement) {
            this.responsiveStyleElement.remove();
            this.responsiveStyleElement = null;
        }
    }

    bindDocumentClickListener() {
        if (!this.documentClickListener) {
            this.zone.runOutsideAngular(() => {
                const documentTarget: string = this.el ? this.el.nativeElement.ownerDocument : 'document';

                this.documentClickListener = this.renderer.listen(documentTarget, 'mousedown', (event) => {
                    if (this.isOutsideClicked(event) && this.overlayVisible) {
                        this.zone.run(() => {
                            this.hideOverlay();
                            this.onClickOutside.emit(event);

                            this.cd.markForCheck();
                        });
                    }
                });
            });
        }
    }

    unbindDocumentClickListener() {
        if (this.documentClickListener) {
            this.documentClickListener();
            this.documentClickListener = null;
        }
    }

    bindDocumentResizeListener() {
        if (!this.documentResizeListener && !this.touchUI) {
            this.documentResizeListener = this.onWindowResize.bind(this);
            window.addEventListener('resize', this.documentResizeListener);
        }
    }

    unbindDocumentResizeListener() {
        if (this.documentResizeListener) {
            window.removeEventListener('resize', this.documentResizeListener);
            this.documentResizeListener = null;
        }
    }

    bindScrollListener() {
        if (!this.scrollHandler) {
            this.scrollHandler = new ConnectedOverlayScrollHandler(this.containerViewChild.nativeElement, () => {
                if (this.overlayVisible)
                    this.hideOverlay();

            });
        }

        this.scrollHandler.bindScrollListener();
    }

    unbindScrollListener() {
        if (this.scrollHandler)
            this.scrollHandler.unbindScrollListener();

    }

    isOutsideClicked(event: Event) {
        return !(this.el.nativeElement.isSameNode(event.target) ||
        this.isNavIconClicked(event) ||
        this.el.nativeElement.contains(event.target) ||
        (this.overlay && this.overlay.contains(<Node>event.target)));
    }

    isNavIconClicked(event: Event) {
        return (
            DomHandler.hasClass(event.target, 'p-datepicker-prev') ||
            DomHandler.hasClass(event.target, 'p-datepicker-prev-icon') ||
            DomHandler.hasClass(event.target, 'p-datepicker-next') ||
            DomHandler.hasClass(event.target, 'p-datepicker-next-icon')
        );
    }

    onWindowResize() {
        if (this.overlayVisible && !DomHandler.isTouchDevice())
            this.hideOverlay();

    }

    onOverlayHide() {
        this.currentView = this.view;

        if (this.maskOverlay)
            this.destroyMask();


        this.unbindDocumentClickListener();
        this.unbindDocumentResizeListener();
        this.unbindScrollListener();
        this.overlay = null;
    }

    ngOnDestroy() {
        if (this.scrollHandler) {
            this.scrollHandler.destroy();
            this.scrollHandler = null;
        }

        if (this.translationSubscription)
            this.translationSubscription.unsubscribe();


        if (this.overlay && this.autoZIndex)
            ZIndexUtils.clear(this.overlay);


        this.destroyResponsiveStyleElement();
        this.clearTimePickerTimer();
        this.restoreOverlayAppend();
        this.onOverlayHide();
        this.#localeSubscription?.unsubscribe();
    }
}

@NgModule({
    imports:      [CommonModule, ButtonModule, SharedModule, RippleModule],
    exports:      [GcDatepickerComponent, ButtonModule, SharedModule],
    declarations: [GcDatepickerComponent]
})
export class DatepickerModule {}
