import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { SelectButtonChangeEvent } from "primeng/selectbutton";
import { DatePeriodMode } from "src/util/datePeriod";

interface IDatePeriod {
    label: string;
    value: DatePeriodMode;
    isVisible: boolean;
};

@Component({
  selector:    'gc-date-period-selection',
  templateUrl: './gc-date-period-selection.component.haml',
  styleUrls:   ['./gc-date-period-selection.component.sass'],
})
export class GcDatePeriodSelectionComponent implements OnInit{
    #datePeriodes: IDatePeriod[] = [
        {label: "Tag", value: 'day', isVisible: false },
        {label: "Woche", value: 'week', isVisible: false},
        {label: "Monat", value: 'month', isVisible: false},
        {label: "Zeitspanne", value: 'periodOfDates', isVisible: false},
    ];
    #dateMode: DatePeriodMode = 'month';
    get visibleDatePeriodes(): IDatePeriod[] {
        return this.#datePeriodes.filter(p => p.isVisible);
    }

    @Input() get dateMode(): DatePeriodMode {
        return this.#dateMode;
    }
    set dateMode(mode: DatePeriodMode) {
        this.#dateMode = mode;
    }

    @Input() get visibleItems(): DatePeriodMode[] {
        return this.#datePeriodes.filter(p => p.isVisible).map(p => p.value);
    }
    set visibleItems(items: DatePeriodMode[]) {
        const itemsVisible = items ?? [];
        this.#datePeriodes.forEach(p => p.isVisible = itemsVisible.some(v => v === p.value));
    }

    @Output() dateModeChanged: EventEmitter<DatePeriodMode> = new EventEmitter();

    constructor() {}

    ngOnInit() {

    }

    changeMode(ev: SelectButtonChangeEvent){
        const mode = ev.value;
        switch(mode){
            case 'day':
            case 'week':
            case 'month':
            case 'periodOfDates':
                this.dateMode = mode;
                this.dateModeChanged.emit(mode);
                break;
        }
    }

}
