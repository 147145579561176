import { Component,EventEmitter,Input, Output } from '@angular/core';
import { AppService } from 'src/services/app.service';

@Component({
    selector:    'gc-qr-dialog',
    templateUrl: './gc-qr-dialog.component.haml',
    styleUrls:   ['./gc-qr-dialog.component.sass'],
})
export class GCQrDialogComponent{
    @Input() title: string;
    private _isVisible: boolean = false;
    public get isVisible(): boolean {
        return this._isVisible;
    }
    @Input() public set isVisible(value: boolean) {
        if (this._isVisible === value) return;
        this._isVisible = value;
        if (!value)this.onWantToClose(value);
    }
    @Input() isModal: boolean = true;

    @Input() qrdata: string = '';

    @Output() wantToClose = new EventEmitter();

    constructor(appService: AppService){}

    copyLink() {
        navigator.clipboard.writeText(this.qrdata).catch(e => console.error(e));
    }

    onWantToClose(state: boolean){
        this.wantToClose.emit(state.toString());
    }
}
