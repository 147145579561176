import { Component, EventEmitter, Input, Output, ViewChild, forwardRef} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { IUser, VisibilityName, WithFiles } from '@vierkant-software/types__api';
import { GcPersonSearchDropdownComponent } from '../gc-person-search/dropdown/gc-person-search-dropdown.component';
import { IPersonSearch, SearchField } from '../gc-person-search/component/gc-person-search.base.component';

/**
 * Is included in the gc-person-search-module
 */
@Component({
    selector:    'gc-user-select',
    templateUrl: './gc-user-select.component.haml',
    styles:      [".edit-button { min-height: 42px; min-width: 42px; justify-content: center;}"],
    providers:   [
    {
      provide:     NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => GcUserSelectComponent),
      multi:       true
    }
  ]
})
export class GcUserSelectComponent implements ControlValueAccessor, IPersonSearch {
    private _user: WithFiles<IUser[]>;

    @Input() public value: string;
    @Input() public fields?: SearchField[];
    @Input() public activeFields?: SearchField[];
    @Input() public lockedFields?: SearchField[];
    @Input() public departments?: string[] | true;
    @Input() public visibility?: VisibilityName;

    @Output() public valueChanged: EventEmitter<string> = new EventEmitter<string>();
    @Output() public userSelected: EventEmitter<WithFiles<IUser>> = new EventEmitter<WithFiles<IUser>>();
    @ViewChild("search") public search: GcPersonSearchDropdownComponent;

    public onChanged = (id: string) => this.valueChanged.emit(id);
    public onTouched = () => {};

    constructor(){}

    public get user(): WithFiles<IUser[]> {
        return this._user;
    }

    @Input() public set user(value: WithFiles<IUser[]>) {
        if ((!value && !this._user) ||
            (
                value?.data.length === this._user?.data.length &&
                value?.data.every(u => this.user?.data.find(x => u.ID === x.ID))
            )
        ) return;
        this._user = value;
        this.value = this._user?.data[0].ID;
        this.onChanged(this.value);
        if (value) {
            this.userSelected.emit({
                data:    value.data[0],
                __files: value.__files
            });
        }
    }

    public registerOnChange(fn: (id: string) => void): void {
        this.onChanged = (id) => {
            fn?.(id);
            this.valueChanged.emit(id);
        };
    }

    public registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    public selectUser(ids: string[]) {
        const id = ids.shift();
        if ([null, undefined].includes(id) || id === this.value) return;

        const index = this.search.searchResult.findIndex(x => x.ID === id);
        this.user = {
            data:    [{...this.search.searchResult.at(index)}],
            __files: [this.search.avatars.at(index)]
        };
    }

    public unselectUser() {
        this.user = null;
    }

    public writeValue(id: string): void {
        this.value = id;
    }
}
