import { Component, HostBinding, Input } from "@angular/core";
import { Gap, getGap } from "../util";


@Component({
    selector:  'gc-row',
    template:  `<ng-content></ng-content>`,
    styleUrls: ['row.component.sass']
})
export class GcRowContainerComponent {
    @Input() gap: Gap | string = "default";
    @HostBinding("class.flex-wrap") @Input() wrap = true;
    @HostBinding("style.--layout-row-gap") get gapVar() {
        return getGap(this.gap);
    };
}
