import { DateTime } from "luxon";
import { formatDateTime } from "src/util/formatting/date";
import { formatUser } from "src/util/formatting/user";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ErrorTranslations = { [key: string]: ErrorTranslations | string | ((a: Record<string, any>) => string) };


export const error_translations: ErrorTranslations = {
    PERMISSION_DENIED: `Fehlende Berechtigung.`,
    STUDIO_NOT_FOUND:  "Eine Firma mit dieser Firmen-ID existiert nicht",
    INVALID:           {
        _NOT_IMPLEMENTED:  `Diese Funktion ist noch nicht implementiert.`,
        AUTH_TOKEN:        `Der Authentifizierungstoken ist ungültig.`,
        NO_PERMISSION:     `Sie haben keine Berechtigung für diese Aktion.`,
        NOT_ACTIVE:        `Die Komponente ist nicht aktiv.`,
        OLD_PASSWORD:      `Das alte Passwort ist falsch.`,
        NEW_PASSWORD:      `Das neue Passwort ist ungültig.`,
        HAS_CHILDREN:      `Die Komponente hat noch untergeordnete Komponenten.`,
        MIN_DRAFT_VERSION: 'Der Vorgang hat eine zu alte Version und kann nicht mehr verwendet werden.',
        NO_UPGRADE:        'Die aktuelle Version müsste aktualisiert werden, aber keine Aktualisierung ist definiert.',
        CREDENTIAL:        {
            EXISTS:  `Die Zugangsdaten existieren bereits.`,
            DEFAULT: `Die Zugangsdaten sind ungültig.`,
        },
        USERNAME_ALREADY_EXISTS:     `Dieser Benutzername ist bereits vergeben.`,
        // eslint-disable-next-line max-len
        CONTRACT_ACTIVE:             `Ein Mitarbeiter kann zu jeder Zeit nur einen aktiven Vertrag haben. Bitte überprüfen Sie die angegebenen Daten, hier liegt eine Überschneidung vor.`,
        WORK_HOURS_TYPE:             `Die Arbeitszeitenart hat einen ungültigen Wert.`,
        LIMITS_BREAKTIMES:           `Es wird mindestens einer Pause erwarted.`,
        RANGE_TO_BIG:                `Die gewählte Zeitspanne ist zu groß. Bitte wählen Sie maximal ein Jahr aus.`,
        TARGETTIME_CALCULATION_TYPE: `Der Typ für die Sollzeitberechnung ist ungültig..`,

        TARGETTIME_CALCULATION_SHIFT_LENGTH_UNIQUE: `Die Werte für die Schichtlänge für die Sollzeitberechnung müssen eindeutig sein.`,
        TARGETTIME_CALCULATION_BREAK_LENGTH:        `Die Pausenlänge einer Schicht muss größer oder gleich der Pausenlänge einer kürzeren Schicht sein.`,
        TARGETTIME_CALCULATION_SHIFT_LONGER_BREAK:  `Für die Sollzeitberechnung muss die Schichtlänge länger sein als die Pausenlänge.`,
        DATEV_IS_ACTIVE:                            "Datev ist aktiv und darf nicht noch einmal aktiviert.",
        DATEV_NOT_ACTIVE:                           "DATEV ist inaktiv.",
        NO_RESPONCE:                                `Keine Antwort erhalten.`,
        EAU_WRONG_STATE:                            `Der Status der eAU hat für die Anfrage den falschen Status.`,
        TERMINATION_AFTER_CONTRACT_END:             `Der Kündigungstermin kann nicht nach dem Vertragsende liegen`,
        CONTRACT_END_BEFORE_BEGIN:                  `Vertragsende kann nicht vor Vertragsbeginn liegen`,
        CORETIME_IN_FLEXTIME:                       `Die Kernarbeitszeit darf nicht außerhalb der Rahmenarbeitszeit liegen`,
        // eslint-disable-next-line max-len
        CLIENT_VERSION:                             `Veraltete Version. Bitte laden Sie die Webseite neu. Sollte der Fehler weiterhin bestehen, wenden Sie sich an unseren Support oder Ihren Administrator.`,
        // eslint-disable-next-line max-len
        METHOD_DEPRECATED:                          `Veraltete Version. Bitte laden Sie die Webseite neu. Sollte der Fehler weiterhin bestehen, wenden Sie sich an unseren Support oder Ihren Administrator.`,
        // eslint-disable-next-line max-len
        DATE_OUTSIDE_TIMEFRAME:                     (a) => `Das Datum '${formatDateTime(a.date, DateTime.DATE_SHORT)}' muss innerhalb der Zeitspanne '${formatDateTime(a.timeframe.start, DateTime.DATE_SHORT)}–${formatDateTime(a.timeframe.end, DateTime.DATE_SHORT)}' liegen.`,
        CHECKIN_STATUS_CHANGED:                     (a) => `Status von ${formatUser(a.user)} hat sich geändert.`,
        HAS_TRUSTED_WORKTIME:                       (a) => `${formatUser(a.user)} hat Vertrauensarbeitszeit.`,
        WORK_HOURS_TYPE_CHANGE:                     `Der Arbeitszeitentyp kann nicht mehr geändert werden. Das Profil ist bereits in Verwendung.`
    },
    NOT_FOUND: {
        ID:                               (a) => `Der identifier ${a?.ID} wurde nicht gefunden.`,
        USER_ID:                          (a) => `Der Benutzer mit der ID ${a?.userId} wurde nicht gefunden.`,
        CREDENTIAL:                       (a) => `Die Zugangsdaten ${a?.id} wurden nicht gefunden.`,
        EMPLOYEE:                         (a) => `Der Mitarbeiter ${a?.id} wurde nicht gefunden.`,
        CUSTOMER:                         (a) => `Der Kunde ${a?.id} wurde nicht gefunden.`,
        CONTRACT:                         (a) => `Der Vertrag ${a?.id} wurde nicht gefunden.`,
        CONTRACT_TEMPLATE:                (a) => `Die Vertragsvorlage ${a?.id} wurde nicht gefunden.`,
        CATEGORY:                         (a) => `Die Kategorie ${a?.id} wurde nicht gefunden.`,
        TAG:                              (a) => `Das Tag ${a?.id} wurde nicht gefunden.`,
        PERIOD:                           (a) => `Der Zeitraum ${a?.id} wurde nicht gefunden.`,
        GROUP:                            (a) => `Die Gruppe ${a?.id} wurde nicht gefunden.`,
        ROLE:                             (a) => `Die Rolle ${a?.id} wurde nicht gefunden.`,
        DEVICE_GROUP:                     (a) => `Die Gerätegruppe ${a?.id} wurde nicht gefunden.`,
        REST_TIME:                        (a) => `Die Ruhezeit ${a?.id} wurde nicht gefunden.`,
        DIRECT_DEBIT_GROUP:               (a) => `Die Lastschriftgruppe ${a?.id} wurde nicht gefunden.`,
        ACCOUNTING_ACCOUNT:               (a) => `Das Buchhaltungskonto ${a?.id} wurde nicht gefunden.`,
        DRAFT:                            (a) => `Der Entwurf ${a?.id} wurde nicht gefunden.`,
        AUTHORIZATION:                    (a) => `Die Authorisierung ${a?.id} wurde nicht gefunden.`,
        EBICS_CONTACT:                    (a) => `Der EBICS-Kontakt ${a?.id} wurde nicht gefunden.`,
        SETTING:                          (a) => `Die Einstellung ${a?.id} wurde nicht gefunden.`,
        STUDIO_CATEGORY:                  (a) => `Die Firmengruppe ${a?.id} wurde nicht gefunden.`,
        STUDIO:                           (a) => `Die Firma ${a?.id} wurde nicht gefunden.`,
        EVENT:                            (a) => `Das Event ${a?.id} wurde nicht gefunden.`,
        TIME_TRACKING_SET:                (a) => `Das Zeiterfassungsset ${a?.id} wurde nicht gefunden.`,
        EMPLOYEE_CONTRACT_TEMPLATE:       (a) => `Die Vertragsvorlage ${a?.id} wurde nicht gefunden.`,
        EMPLOYEE_CONTRACT:                (a) => `Der Vertrag ${a?.id} wurde nicht gefunden.`,
        CONTRACT_FEE_ADJUSTMENT:          (a) => `Die Vertragsanpassung ${a?.id} wurde nicht gefunden.`,
        CONTRACT_FEE_ADJUSTMENT_TEMPLATE: (a) => `Die Vertragsanpassungsvorlage ${a?.id} wurde nicht gefunden.`,
        PAYMENT_OPTION:                   (a) => `Die Zahlungsoption ${a?.id} wurde nicht gefunden.`,
        REST_TIME_CONTRACT:               (a) => `Die Ruhezeit ${a?.id} wurde nicht gefunden.`,
        STATEMENTS_OF_ACCOUNT:            (a) => `Die Kontoauszüge ${a?.id} wurde nicht gefunden.`,
        USERS_NOTE_ID:                    (a) => `Die Notiz ${a?.id} wurde nicht gefunden.`,
        CURRENT_ATTENDANCE_ID:            (a) => `Die aktuelle Anwesenheit ${a?.id} wurde nicht gefunden.`,
        ABSENCE_INFO_ID:                  (a) => `Die Abwesenheitsinformation ${a?.id} wurde nicht gefunden.`,
        TERMINAL:                         (a) => `Das Terminal ${a?.id} wurde nicht gefunden.`,
        ABSENCESET:                       (a) => `Das Abwesenheitsset ${a?.id} wurde nicht gefunden.`,
        USER_ABSENCE_ACCOUNT:             (a) => `Das Abwesenheitskonto ${a?.id} wurde nicht gefunden.`,
        SESSION:                          (a) => `Die Sitzung ${a?.id} wurde nicht gefunden.`,
        // TODO: @Peter this error message is too specific for its name
        // please change the name to fit the attendance list or throw a different error for the frontend
        // eslint-disable-next-line max-len
        HASH_VALUES:                      (a) => `Die Anfrage ist abgelaufen. Aus Sicherheitsgründen werden die angefragten Daten nur bis zum Ablauf des Countdowns gespeichert und müssen dann neu angefragt werden.`,
    },
    CONFLICT: {
        CONTRACT_END_BEFORE_BEGIN:  `Vertragsende kann nicht vor Vertragsbeginn liegen`,
        STANDINTIME_VS_ABSENCETIME: `Zeitrahmen der Abwesenheit der Vertretung stimmen nicht überein`,
        NOT_LATEST_VERSION:         `Speichern nicht möglich. Dies ist nicht die aktuellste Objekt-Version.`,
    },
    APP: {
        TERMINAL_NEEDED: `Diese Aktion benötigt ein Terminal`,
    },
    CALENDAR: {
        NOTALLOWED:         `Sie haben keine Berechtigung für diese Aktion.`,
        EVENTINPAST:        `Das Event kann nicht in der Vergangenheit liegen.`,
        SPACEAVAILABLE:     `Der Zeitraum ist nicht verfügbar.`,
        NOSPACE:            `Der Zeitraum ist nicht verfügbar.`,
        NOSPACEBUTWAITLIST: `Der Zeitraum ist nicht verfügbar.`,
        NOWAITLIST:         `Der Zeitraum ist nicht verfügbar.`,
        WAITLISTLATE:       `Der Zeitraum ist nicht verfügbar.`,
        WAITLISTEARLY:      `Der Zeitraum ist nicht verfügbar.`,
        LOCATIONCONFLICT:   `Der Zeitraum ist nicht verfügbar.`,
        INVALIDINPUT:       `Der Zeitraum ist nicht verfügbar.`,
        NORESOURCE:         (a) => `Es wurde keine ${a?.type} für diesen Termin angegeben`,
    },
    SHIFT: {
        EXPORT_RANGE: ({maxRange}) =>
                `Der gewählte Zeitraum überschreitet das Maximum${ maxRange?.isValid ? ' von ' + maxRange.shiftTo("weeks").toHuman() : '.'}.`,
    },

    CONTRACT: {
        ORGCHART_GROUP_IN_TEMPLATE: `Die Verwendung einer Organigrammgruppe in einer Vertragsvorlage ist nicht zulässig.`,
        ORGCHART_GROUP_IN_CONTRACT: `Es ist nicht erlaubt, einem Vertrag eine Organigrammgruppe zuzuordnen.`,
    },
    DOCUMENT: {
        MUST_BE_IN_RECYLCE_BIN: "Das Dokument muss zum löschen im Papierkorb liegen.",
        DELETE_MANUALLY:        "Das Dokument darf nicht mehr manuell gelöscht werden.",
        EDIT_IS_NOT_ALLOWED:    "Das Dokument darf nicht mehr bearbeitet werden.",
    },
    GROUP: {
        NOT_AN_ORGCHART_GROUP: (a) => `Die Gruppe '${a?.title}' mit der ID '${a?.id}' ist keine Organigrammgruppe.`,
        SAVE_FAILED:           "Die Gruppe konnte nicht gespeichert werden."
    },
    USER: {
        SAVE:                  "Benutzerdaten konnten nicht erstellt oder gespeichert werden.",
        UPDATE:                "Benutzerdaten konnten nicht aktualisiert werden.",
        NO_USER_LOGGED_IN:     "Kein Benutzer angemeldet.",
        ASSIGNED_USER_CHANGED: "Die zugeordnete Person darf nicht geändert werden."
    },
    DATEV: {
        GENERIC: (a) => `${a.code} ${a.message}: ${a.details}.`,
    },

    UNKNOWN_ERROR:         (e) => `Ein unbekannter Fehler ist aufgetreten. Bitte wenden sie sich an den Support. FehlerID: ${e?.ErrorID}`,
    TOAST_DEFAULT_SUMMARY: "Fehler",
    BROKEN_TRANSLATION:    "Kein Babelfisch gefunden.",
    // internal errors of frontend. Those will not be send from the backend
    ADMIN:                 {
        INVALID_SESSION_HEADER: "Session abgelaufen",
        INVALID_SESSION:        "Ihre login session ist abgelaufen. Bitte melden Sie sich erneut an."
    }
};
