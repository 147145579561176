import {
  ApplicationRef,
  Component,
  ComponentRef,
  ElementRef,
  EnvironmentInjector,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild,
  ViewEncapsulation,
  createComponent,
  forwardRef
} from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { OverlayPanel } from "primeng/overlaypanel";
import { ISearchQueryEmployee, Pagination, SearchQueryResult, VisibilityName, WorkHoursType } from "@vierkant-software/types__api";
import { GcPersonSearchComponent } from "../component/gc-person-search.component";
import { SearchBaseComponent, SearchField, SelectMode } from "../component/gc-person-search.base.component";

@Component({
  selector:      'gc-person-search-dropdown',
  templateUrl:   './gc-person-search-dropdown.component.haml',
  styleUrls:     ['./../component/gc-person-search.component.sass', './gc-person-search-dropdown.component.sass'],
  encapsulation: ViewEncapsulation.Emulated,
  providers:     [
    {
      provide:     NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => GcPersonSearchDropdownComponent),
      multi:       true
    }
  ]
})
export class GcPersonSearchDropdownComponent extends SearchBaseComponent implements OnDestroy {
  private static instances: GcPersonSearchDropdownComponent[] = [];
  public static close(){
    GcPersonSearchDropdownComponent.instances.forEach(x => x.overlay.hide());
  }

  @Output() public selectionChange = new EventEmitter<string[]>();
  @Output() public selectedItems = new EventEmitter<SearchQueryResult[]>();
  @Input() public  employment?: Partial<ISearchQueryEmployee>;
  @Input() public  departments?: string[] | true;
  @Input() public  fields?: SearchField[];
  @Input() public  activeFields?: SearchField[];
  @Input() public  lockedFields?: SearchField[];
  @Input() public trustedWorkTimes?:  "yes" | "no" | "any";
  @Input() public workhoursType?: WorkHoursType;
  @Input() public  loading: boolean;
  @Input() public  visibility?: VisibilityName;
  @Input() public  maxSelect?: number;
  @Input() public  minSelect?: number;
  @ViewChild('op', {static: true}) public overlay: OverlayPanel;
  @Input() public  pagination: Pagination;
  @ViewChild('popup', {static: true}) public searchElement: ElementRef<HTMLElement>;
  private _searchValue: string;
  public get searchValue(): string {
    return this._searchValue;
  }
  @Input() public set searchValue(value: string) {
    this._searchValue = value;
    this.searchComponent.setInput("searchValue", this.searchValue);
  }
  @Input() public  selectionMode: SelectMode = "single";
  @Input() public  showAvatar: boolean;

  public showMask: boolean;

  public get searchResult(){
    return this._searchComponent?.instance.searchResult;
  }
  public get avatars(){
    return this._searchComponent?.instance.avatars;
  }
  private _searchComponent: ComponentRef<GcPersonSearchComponent>;
  public get searchComponent(): ComponentRef<GcPersonSearchComponent> {
    if (!this._searchComponent){
      this._searchComponent = createComponent(GcPersonSearchComponent, {
        environmentInjector: this.injector,
      });
      this._searchComponent.instance.onChange = (x: string[]) => this.value = x;
      this.appRef.attachView(this._searchComponent.hostView);
    }
    return this._searchComponent;
  }
  constructor(
    private appRef: ApplicationRef,
    private injector: EnvironmentInjector
  ){
    super();
    GcPersonSearchDropdownComponent.instances.push(this);
  }

  public ngOnDestroy(): void {
    const index = GcPersonSearchDropdownComponent.instances.findIndex(x => x === this);
    if (index >= 0) GcPersonSearchDropdownComponent.instances.splice(index, 1);
  }

  public showOverlay(event: FocusEvent){
    this.showMask = true;
    this.overlay.show(event);
  }

  onPopupShow(event: unknown){
    this.searchComponent.setInput("value", this.value);
    this.searchComponent.setInput("searchValue", this.searchValue);
    this.searchComponent.setInput("departments", this.departments);
    this.searchComponent.setInput("employment", this.employment);
    this.searchComponent.setInput("fields", this.fields);
    this.searchComponent.setInput("activeFields", this.activeFields);
    this.searchComponent.setInput("lockedFields", this.lockedFields);
    this.searchComponent.setInput("trustedWorkTimes", this.trustedWorkTimes);
    this.searchComponent.setInput("workhoursType", this.workhoursType);
    this.searchComponent.setInput("pagination", this.pagination);
    this.searchComponent.setInput("visibility", this.visibility);
    this.searchComponent.setInput("minSelect", this.minSelect);
    this.searchComponent.setInput("maxSelect", this.maxSelect);
    this.searchComponent.setInput("selectionMode", this.selectionMode);
    this.searchComponent.setInput("showAvatar", this.showAvatar);
    this.searchComponent.instance.selectionChange = this.selectionChange;
    this.searchComponent.instance.selectedItems = this.selectedItems;
    this.overlay.container.firstChild.appendChild(this.searchComponent.location.nativeElement);
  }

  onPopupHide(event: unknown) {
    this.showMask = false;
  }

  public clear(): void {
    super.clear();
    this.searchComponent.setInput("searchValue", this.searchValue);
  }
}
