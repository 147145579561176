import { Component, EventEmitter, Input, Output, ViewChild, ViewEncapsulation, forwardRef } from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { SearchBaseComponent, SearchField, SelectMode } from "../component/gc-person-search.base.component";
import { ISearchQueryEmployee, Pagination, SearchQueryResult, VisibilityName, WorkHoursType } from "@vierkant-software/types__api";
import { GcPersonSearchComponent } from "../component/gc-person-search.component";

@Component({
  selector:      'gc-person-search',
  templateUrl:   './gc-person-search-input.component.haml',
  styleUrls:     ['./../component/gc-person-search.component.sass'],
  encapsulation: ViewEncapsulation.Emulated,
  providers:     [
    {
      provide:     NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => GcPersonSearchInputComponent),
      multi:       true
    }
  ],
})
export class GcPersonSearchInputComponent extends SearchBaseComponent {
  @Output() public selectionChange = new EventEmitter<string[]>();
  @Output() public selectedItems = new EventEmitter<SearchQueryResult[]>();
  @Input() public fields?: SearchField[];
  @Input() public activeFields?: SearchField[];
  @Input() public lockedFields?: SearchField[];
  @Input() public trustedWorkTimes?:  "yes" | "no" | "any";
  @Input() public workhoursType?: WorkHoursType;
  @Input() public departments?: string[] | true;
  @Input() public employment?: Partial<ISearchQueryEmployee>;
  @Input() public loading: boolean;
  @Input() public maxSelect?: number;
  @Input() public minSelect?: number;
  @Input() public pagination: Pagination;
  @Input() public searchValue: string;
  @Input() public selectionMode: SelectMode = "single";
  @Input() public showAvatar: boolean;
  @Input() public visibility?: VisibilityName;

  @Input() public set value(value: string[]){
    super.value = value;
  }

  public get value(){
    return super.value;
  }

  public get searchResult() {
    return this.search.searchResult;
  }

  public get avatars() {
    return this.search.avatars;
  }

  @ViewChild("search", {static: true}) private search: GcPersonSearchComponent;
}
