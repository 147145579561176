import { Component } from "@angular/core";

/**
 * @example use `.full-page` on component to ignore the wrapper padding of the page
 */
@Component({
    selector: 'gc-scroll-container',
    template: `
<div class="content">
    <ng-content></ng-content>
</div>
`,
    styleUrls: ['scroll-container.component.sass']
})
export class GcScrollContainerComponent {}
