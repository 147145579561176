

import { NgModule } from '@angular/core';
import { GcTooltipContainerComponent, GcTooltipComponent, GcTooltipDirective } from './gc-tooltip.directive';
import { CommonModule } from '@angular/common';

@NgModule({
    imports:      [CommonModule],
    declarations: [GcTooltipDirective, GcTooltipComponent, GcTooltipContainerComponent],
    exports:      [GcTooltipDirective, GcTooltipContainerComponent]

})
export class GcTooltipModule { }
