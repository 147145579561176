import { Component, ContentChild, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { Globals } from 'src/app/services/globals.service';

@Component({
  selector:    'gc-compare-dialog',
  templateUrl: './compare-dialog.component.haml',
  styleUrls:   ['./compare-dialog.component.sass'],
})
export class CompareDialogComponent<T> {
  @Input() set visible(value: boolean) {
    if (value !== this.#visible)
      this.visibleChange.emit(value);
    this.#visible = value;
  }
  get visible(): boolean {
    return this.#visible;
  }
  #visible: boolean = false;
  @Input() current: T = undefined;
  @Input() existing: T = undefined;
  @Output() confirm: EventEmitter<T> = new EventEmitter();
  @Output() visibleChange: EventEmitter<boolean> = new EventEmitter();
  @Input() labelLeft: string = '';
  @Input() labelRight: string = '';
  @Input() headerLabel: string = '';
  @Input() redButtonLabel: string = '';
  @Input() greenButtonLabel: string = '';
  @ContentChild('content', { static: false}) contentTemplateRef: TemplateRef<unknown>;

  constructor(public global: Globals){

  }

  onCancel() {
    this.visible = false;
  }

  onConfirm() {
    this.confirm.emit(this.existing);
    this.visible = false;
  }
}
