import { Component, Input, OnChanges } from '@angular/core';
import { IGenericStats, IGenericStatsData, IStatsHeader, StatsFormatType } from '@vierkant-software/types__api';
import { DateTime } from 'luxon';
import { MessageService } from 'primeng/api';
import { Globals } from 'src/app/services/globals.service';

interface FEIStatsHeader extends IStatsHeader {
  index: number;
}
interface IntervalDates {
  start: DateTime;
  end: DateTime;
}
@Component({
  selector:    'gc-stats-view-row-expand',
  templateUrl: './gc-stats-view-row-expand.component.haml',
  styleUrls:   ['./gc-stats-view-row-expand.component.sass'],
})
export class GcStatsViewRowExpandComponent implements OnChanges {
  @Input() genericStats: IGenericStats;
  @Input() header: string = '';
  @Input() showInfo: boolean = true;
  @Input() rowSelectionLabel: string = '';
  @Input() columnSelectionLabel: string = '';
  @Input() selectedInterval: IntervalDates;
  @Input() export: boolean = true;


  filteredData: IGenericStatsData[] = [];
  filter = (term: string, values: IGenericStatsData[]) => {
    if ([null, undefined, ""].includes(term?.trim())) return values;
    const words = term.toLowerCase().split(" ");
    return values.filter(x => {
      if (!Array.isArray(x)) return false;
      if (typeof x[0] !== "string") return false;
      return words.every(word => (x[0] as string).toLowerCase().indexOf(word) >= 0);
    });
  };

  selectedMainColumns: FEIStatsHeader[];
  selectedSubColumns: FEIStatsHeader[];

  mainCols: FEIStatsHeader[];
  subCols: FEIStatsHeader[];
  baseCols: FEIStatsHeader[];
  selectedCols: FEIStatsHeader[];

  selectedData: IGenericStatsData[];

  currentInterval: IntervalDates = {
    start: DateTime.now(),
    end:   DateTime.now(),
  };

  infoEnabled: boolean = true;
  filterOpen: boolean = true;

  selectedSubCols: FEIStatsHeader[];

  constructor(public globals: Globals, private messageService: MessageService) { }

  ngOnChanges() {
    this.currentInterval = {...this.selectedInterval};
    this.selectedMainColumns = this.genericStats.headers.map((x,i) => ({...x, index: i}));
    this.selectedSubColumns = this.genericStats.subHeaders.map((x,i) => ({...x, index: i}));
    this.mainCols = this.genericStats.headers.map((x,i) => ({...x, index: i}));
    this.subCols = this.genericStats.subHeaders.map((x,i) => ({...x, index: i}));
    this.selectedData = this.genericStats.data;
    //TODO gotta be actually compatible with columnGroups => implement component when needed
    this.baseCols = this.mainCols.filter(element => element.columnGroupID && this.subCols.find(o => o.columnGroupID === element.columnGroupID));
    this.selectedCols = this.baseCols;
  }

  dateChanged() {
    let msg: string;
    // eslint-disable-next-line @typescript-eslint/keyword-spacing
    if (this.currentInterval.start < this.selectedInterval.start || this.currentInterval.end > this.selectedInterval.end) {
      msg =
        "Das Datum muss zwischem dem "
        + this.selectedInterval.start.toFormat('dd.MM.yyyy')
        + " und dem "
        + this.selectedInterval.end.toFormat('dd.MM.yyyy')
        + " liegen";
      this.messageService.add({severity: "error", summary: "Jahresgrenze Überschritten", detail: msg});
      return;
    }
    this.filterDate();
  }

  filterDate() {
    //FIXME array0 & array1 = startTime & endTime?
    this.selectedData = this.genericStats.data.map((element: IGenericStatsData[]) => {
      element = [...element];
      element[element.length - 1] = (<IGenericStatsData[]>element[element.length - 1])
        .filter((item: IGenericStatsData[]) => item[0] >= this.currentInterval.start && item[1] <= this.currentInterval.end);
      return element;
    });
  }

  changeTableCols() {
    this.selectedSubCols = this.subCols.filter(element => this.selectedCols.find(o => o.columnGroupID === element.columnGroupID));
    this.selectedMainColumns = this.mainCols.filter(element => !element.columnGroupID);
    this.selectedMainColumns = this.sortColumns([...this.selectedMainColumns, ...this.selectedCols]);
    this.selectedSubColumns = this.subCols.filter(element => !element.columnGroupID);
    this.selectedSubColumns =  this.sortColumns([...this.selectedSubColumns, ...this.selectedSubCols]);
  }

  sortColumns(cols: FEIStatsHeader[]) {
    return cols.sort((a,b) => a.index - b.index);
  }

  asFormat(format: StatsFormatType) {
    const dt = Globals.DateTime;
    return Globals.DateTime[format as keyof typeof dt];
  }

  exportData() {
    //do the magic here
  }
}
