import { Component, ContentChild, Input, TemplateRef, ViewEncapsulation,} from '@angular/core';
import { Gap } from '../layout/util';

@Component({
    selector:      "gc-settings-group",
    styleUrls:     ['./gc-settings-group.component.sass'],
    encapsulation: ViewEncapsulation.None,
    template:      `
    <gc-column class="gc-settings-group" [gap]="gap">
        <div *ngIf="header" class="header">
            <ng-container [ngTemplateOutlet]="headerTemplateRef" [ngTemplateOutletContext]="{$implicit: header}">
                <ng-container *ngIf="!headerTemplateRef">
                    <h1>{{header}}</h1>
                </ng-container>
            </ng-container>
        </div>
        <gc-row class="entries">
            <ng-content></ng-content>
        </gc-row>
    </gc-column>`
})
export class GCSettingsGroupComponent{
    @ContentChild('header', { static: false}) public headerTemplateRef: TemplateRef<unknown>;
    @Input() public header: unknown;
    @Input() public gap: Gap = "default";
}
