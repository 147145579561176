import { Component,Input,OnInit } from '@angular/core';

export enum  Color{
    gray,
    blue,
    green,
    yellow,
    purple,
}

@Component({
    selector:    'app-gradient-button',
    templateUrl: './gradient-button.component.haml',
    styleUrls:   ['./gradient-button.component.sass'],
})
export class GradientButtonComponent implements OnInit {
    //default value
    @Input() color: Color = Color.gray;

    Color = Color;

    constructor(){}
    ngOnInit(): void {
    }
}
