import { CommonModule } from "@angular/common";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ButtonModule } from "primeng/button";
import { StepsModule } from 'primeng/steps';
import { IAppService } from "../types/IAppService";
import { DraftHostComponent } from './components/draft-host-component';
import { DraftService } from "./draft.service";
import { APP_SERVICE, DebugFlags, DRAFT_DEBUG } from "../types/token";
import { GcReactiveForms } from "src/app/modules/ngx-components";
@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ButtonModule,
        StepsModule,
        GcReactiveForms,
    ],
    declarations: [
        DraftHostComponent,
    ],
    providers: [
        DraftService,
    ],
})
export class DraftModule {
    static forRoot(appService: new(...args: unknown[]) => IAppService, options?: { debug?: DebugFlags }): ModuleWithProviders<DraftModule> {
        return {
            ngModule:  DraftModule,
            providers: [
                DraftService,
                {
                    provide:     APP_SERVICE,
                    useExisting: appService
                },
                {
                    provide:  DRAFT_DEBUG,
                    useValue: options?.debug
                },
            ]
        };
    }
}
