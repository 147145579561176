import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { Duration } from "luxon";
import { Observable, Subject, Subscription, debounceTime, merge } from "rxjs";

@Component({
    selector:      'gc-searchbar',
    templateUrl:   './gc-search.component.haml',
    styleUrls:     ['./gc-search.component.sass'],
    providers:     [{ provide: NG_VALUE_ACCESSOR, useExisting: GCSearchComponent, multi: true }],
    encapsulation: ViewEncapsulation.None,
})
export class GCSearchComponent<T = unknown> implements ControlValueAccessor, OnInit, OnDestroy {
    @Input() public debounceTime: Duration = Duration.fromObject({milliseconds: 250});
    @Input() public filter: (term: string, arr: T[]) => (T[] | Promise<T[]>);
    @Input() public autoFocus: boolean;
    @Input() public loading: boolean;
    @Input() public value: T[];
    @Input() public placeholder: string = "Suche";
    private _data: T[];
    public get data(): T[] {
        return this._data;
    }
    @Input()
    public set data(value: T[]) {
        this._data = value;
        if (this.filter)this._searchSubjectNoDebounce.next(this.searchValue);
    }
    @Output() public filtered: EventEmitter<T[]> = new EventEmitter();

    private _searchValue: string;
    private _searchSubject: Subject<string> = new Subject<string>();
    private _searchSubjectNoDebounce: Subject<string> = new Subject<string>();
    private _searchObservable: Observable<T[]> = merge(
        this._searchSubjectNoDebounce,
        this._searchSubject.pipe(debounceTime(this.debounceTime.toMillis()))
    ).pipe(this.search());
    private _searchSubscription: Subscription;

    public onChange = (_value: T[]) => {};
    public onTouch = () => {};

    constructor() {
        this._searchSubscription = this._searchObservable.subscribe(x => void this.writeValue(x).catch(e => e.displayErrorToast?.()));
    }

    @Input()
    public set searchValue(value: string) {
        this.loading = true;
        this._searchValue = value;
        this._searchSubject.next(value);
        this.searchValueChange.emit(value);
    }

    public get searchValue(): string {
        return this._searchValue;
    }
    @Output()
    public searchValueChange = new EventEmitter<string>();

    public clear(){
        this.searchValue = undefined;
    }

    public registerOnChange(fn: ((val: T[]) => void)): void {
        this.onChange = fn;
    }

    public registerOnTouched(fn: (() => void)): void {
        this.onTouch = fn;
    }

    public update() {
        this._searchSubject.next(this.searchValue);
    }

    public async writeValue(val: T[]) {
        // angular setup control might overwrite value with `null/undefined`.
        // in that case we try filtering the data manually
        if (!val || !val.length) val = await Promise.resolve(this.filter('', this.data));
        if (this.value === val || !val) return;
        this.value = val;
        this.emitValue(this.value);
    }

    private emitValue(values: T[]) {
        this.filtered?.emit(values);
        this.onChange?.(values);
    }

    private search(){
        const self = this;
        return (source: Observable<string>): Observable<T[]> =>
        new Observable(subscriber => {
            source.subscribe({
            next(value) {
                Promise.resolve(self.filter(value, self.data))
                    .then(result => subscriber.next(result))
                    .catch((error) => subscriber.error(error))
                    .finally(() => self.loading = false);
            },
            error(error) {
                subscriber.error(error);
            },
            complete() {
                subscriber.complete();
            }
            });
        });
    }

    public ngOnInit(): void {
        this._searchSubjectNoDebounce.next(this.searchValue);
    }

    public ngOnDestroy(): void {
        this._searchSubscription?.unsubscribe();
    }
}
