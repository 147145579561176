/* eslint-disable max-len */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Globals } from 'src/app/services/globals.service';
import { IContractTemplateExt, IContractTemplateRuntime, ISubcontractTemplate, ISubContractTemplateForPreview, TimeUnit } from '@vierkant-software/types__api';
import { Color } from "src/app/components/gradient-button/gradient-button.component";

type ContractTemplate = IContractTemplateExt & { image: string };

export enum SelectOptions {
  additional = 0,
  info
}

@Component({
  selector:    'gc-contract-card',
  templateUrl: './gc-contract-card.component.haml',
  styleUrls:   ['./gc-contract-card.component.sass'],
})
export class GcContractCardComponent implements OnInit{
  color = Color;
  @Output() contractIdChanged = new EventEmitter();
  form = this.fb.group({
    contract_ID:        [''], //this._template.ID, 
    selectedRunTime_ID: [''], //this.activeRuntime?.ID
    selectButton:       [],
    subModules:         [[]]
	});

  selectOptions = SelectOptions;
  selectButtonOptions = [
    {
      label: 'Zusätzlich',
      value: SelectOptions.additional,
    },
    {
      label: 'Info',
      value: SelectOptions.info,
    }
  ];

  contracts: {[key: string]: ISubContractTemplateForPreview};

  TimeUnit = TimeUnit;


  @Input() selected: boolean = false;

  activeRuntime: number = 0;
  @Input() template: ContractTemplate;

  constructor(
    private fb: FormBuilder,
    public globals: Globals
  ) {}

  ngOnInit() {
    // FIXME @Chris - handle style in .haml for template.image === null, issue #163
    if (!this.template.isEmployeeContract) {
      this.form.patchValue({
        selectedRunTime_ID: this.template.runtimes?.[0]?.ID,
        selectButton:       0
      });
    }
  }

  get activeRuntimeID() {
    return this.form.get('selectedRunTime_ID').value;
  }

  @Input()
  set activeRuntimeID(data: string) {
    this.form.patchValue({
      selectedRunTime_ID: data
    });
  }

  @Input()
  set subContracts(data: ISubContractTemplateForPreview[]){
    this.contracts = Object.fromEntries(data?.map((entry) => [entry.ID, entry]) ?? []);
  }

  getLabelForDropdown(runtimeID: string) {
    const tmp = this.template.runtimes.find(o => o.ID === runtimeID)?.runtime;
    return tmp.value + ' ' + this.globals.timeUnit.default[tmp.unit];
  }

  //TODO checkboxen
  getRuntimeState(subContract: ISubcontractTemplate) {
    const tmp = subContract.runtimeIdList.find(o => o.runtimeId === this.activeRuntimeID)?.state;
    return tmp;
  }

  getPriceForRuntime(paymentType: string) {
    return (<Record<string, { amount: number }>><unknown>this.template.runtimes.find(o => o.ID === this.activeRuntimeID))?.[paymentType]?.amount;
  }

  getData<T extends keyof IContractTemplateRuntime>(dataType: T): IContractTemplateRuntime[T] {
    return this.template.runtimes.find(o => o.ID === this.activeRuntimeID)?.[dataType];
  }

  // FIXME @Chris - handle different Templates properly! issue #164
  selectContract() {
    this.contractIdChanged.emit({
      contractTemplateId:               this.template.ID,
      selectedRuntimeId:                this.activeRuntimeID,
      isEmployeeContract:               this.template.isEmployeeContract,
      vacationsDaysPerYear:             undefined,
      calculateVacationProportionately: undefined,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      absenceEntitlements:              (<any>this.template).absenceEntitlements ?? [],
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      credentialGroups:                 (<any>this.template).credentialGroups,
    });
  }
}
