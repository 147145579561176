import { Component,Input,OnInit } from '@angular/core';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

export type Color = 'gray' | 'blue' | 'teal' | 'green' | 'yellow' | 'purple' | 'red';

@Component({
    selector:    'app-card-item',
    templateUrl: './card-item.component.haml',
    styleUrls:   ['./card-item.component.sass'],
})
export class CardItemComponent implements OnInit {
    /**
     * define prime icons here
     *
     * @example "star"
     */
    @Input() primeIcon?: string;
    @Input() color: Color = 'gray';
    //checked is for the blue
    @Input() checked: boolean = false;
    @Input() title: string = "Title";
    @Input() faIcon?: IconProp; //define font-awesome icons here

    constructor(
        private library: FaIconLibrary,
    ){
		library.addIconPacks(fas);
    }
    ngOnInit(): void {
    }
}
