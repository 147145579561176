import { Component,Input, OnDestroy, OnInit } from '@angular/core';
import { DateTime, Duration, DurationLikeObject, ToHumanDurationOptions } from 'luxon';
import { Subscription } from 'rxjs';
import { AppService } from 'src/services/app.service';
import { formatTime } from 'src/util/formatting/time';
import { toDateTime } from 'src/util/toDateTime';
type DateLike = DateTime | Date | number;
type Value = Duration | DateLike | DurationLikeObject | [DateLike, DateLike];

@Component({
    selector: 'gc-time',
    template: '<span>{{ _internvalParsedValue }}</span>',
})
export class GcTimeComponent implements OnInit, OnDestroy {
    private isNegative: boolean;
    @Input() set rescale(value: boolean){
        if (value && this._internalValue.valueOf() < 0) return;
        this._rescale = value;
        this.updateValue();
    }
    @Input() set units(value: (keyof DurationLikeObject)[]){
        this._units = value;
        this.updateValue();
    }
    @Input() public set format(value: string | ToHumanDurationOptions){
        this._internalFormat = value;
        this.updateValue();
    }
    public get format(){
        return this._internalFormat;
    }

    @Input() public set value(value: Value) {
        this.isNegative = false;
        if (Array.isArray(value)){
            value = toDateTime(value[1]).diff(toDateTime(value[0]));
        } else if (value instanceof Date || DateTime.isDateTime(value)) {
            value = toDateTime(value);
            value = Duration.fromObject({hours: value.hour, minutes: value.minute, seconds: value.second});
        } else if (typeof value === "number") {
            value = Duration.fromMillis(value);
            console.log(value);
        } else if (typeof value === "object" && !Duration.isDuration(value)) {
            value = Duration.fromDurationLike(value as DurationLikeObject);
        }

        /**
         * TODO:  check the behaviour of luxon.Duration with negative values.
         * We should see if we can adjust the formatting instead of altering the passed value.
         * - This also seems to be an open discussion on the luxon repo (since 2019).
         * - There also seems to be an issue with the luxon prop `conversionAccuracy`
         * - Check how it behaves if we normalize all units, current conversion seems to create odd result
         * - Rescaling a negative number causes issues
         * - `toFormat` also seems to change the object
         */
        if (Duration.isDuration(value) && value.valueOf() < 0) {
            value = value.negate();
            this.isNegative = true;
            this.rescale = false;
        }

        this._internalValue = value as Duration;
        this.updateValue();
    }

    _internvalParsedValue: string;
    private _internalFormat: string | ToHumanDurationOptions = "h'h' m'min'";
    private _internalValue: Duration;
    private _units: (keyof DurationLikeObject)[] = ["hours", "minutes"];
    private _rescale: boolean = true;
    private _localeSubscription: Subscription;

    constructor(private appSerivce: AppService){}

    ngOnInit(): void {
        this._localeSubscription = this.appSerivce.$Locale.subscribe(x => this.updateValue());
    }

    ngOnDestroy(): void {
        this._localeSubscription?.unsubscribe();
    }

    private updateValue() {
        this._internvalParsedValue = `${this.isNegative ? '-' : ''} ${formatTime(this._internalValue, this.format, this._units, undefined, this._rescale)}`
            .trim();
    }
}
