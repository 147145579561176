import { Component, ContentChild, HostBinding, Input, TemplateRef,} from '@angular/core';

/**
 * @example Simple KV
 * ```html 
 * <gc-kv key="My Key" value="My Value"></gc-kv>
 * ```
 * 
 * @example Content Projection
 * ```html 
 * <gc-kv key="My Key">
 *      My Value
 * </gc-kv>
 * ```
 * 
 * @example Templating
 * ```html 
 * <gc-kv key="My Key" value="My Value">
 *      <ng-template #header let-data>
 *          {{data}}
 *      </ng-template>
 *      <ng-template #content let-data>
 *          {{data}}
 *      </ng-template>
 * </gc-kv>
 * ```
 */
@Component({
    selector:  'gc-kv',
    styleUrls: ['./gc-kv.component.sass'],
    template:  `
    <span class="gc-kv" *ngIf="value || (!value && !hideOnEmpty)">
        <div class="key">
            <ng-container [ngTemplateOutlet]="headerTemplateRef" [ngTemplateOutletContext]="{$implicit: key}">
                <ng-container *ngIf="!headerTemplateRef">
                    {{key}}
                </ng-container>
            </ng-container>
        </div>
        <div class="value" [ngClass]= "{'less-intensity': highlightEmpty && [null, undefined].includes(value) && ref.children.length === 0}">
            <div class="value-container" #ref>
                <ng-content></ng-content>
            </div>
            <ng-container *ngIf="ref.children.length === 0" [ngTemplateOutlet]="contentTemplateRef" [ngTemplateOutletContext]="{$implicit: value ?? nullValue}">
                <ng-container *ngIf="!contentTemplateRef">
                    {{value ?? nullValue}}
                </ng-container>
            </ng-container>
        </div>
    </span>`
})
export class GcKVComponent{
    @ContentChild('content', { static: false}) public contentTemplateRef: TemplateRef<unknown>;
    @ContentChild('header', { static: false}) public headerTemplateRef: TemplateRef<unknown>;
    @Input() @HostBinding('class.container') public container: boolean = false;
    @Input() public hideOnEmpty: boolean = false;
    @Input() public highlightEmpty: boolean = true;
    @Input() public key: unknown;
    @Input() public nullValue: string = 'Keine Angabe';
    @Input() public value: unknown;
}
