import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PrimeNGConfig } from 'primeng/api';
import { APIStates, AppService } from 'src/services/app.service';
import { DraftService } from 'src/app/modules/ngx-draft';
import { IDraftInfo } from 'src/app/modules/ngx-draft/lib/draft.service';
import { menuItems, gcTreeNode } from 'src/app/menu-config';
// import { Permission } from '@vierkant-software/types__api';
import { animate, style, transition, trigger } from '@angular/animations';
import { DateTime } from 'luxon';
import { sortBy } from 'src/util/sort';

// type ExtraLabel = "newFeature" | "beta";
type Display = "hidden" | "minimized" | "default";
type SidebarConfig = {
    closeOtherMenus?: boolean;
    showFavorite?: boolean;
    maxLevel: number;
};

/**
 * This is a top level class comment
 * 
 * @remark Test
 */
@Component({
  selector:    'gc-left-sidebar',
  templateUrl: './left-sidebar.component.haml',
  styleUrls:   ['./left-sidebar.component.sass'],
  animations:  [
        trigger(
            'toggleAnimation',
            [
                transition(
                'void => *',
                [
                    style({ height: 0}),
                    animate('250ms cubic-bezier(0.86, 0, 0.07, 1)',
                        style({ height: '*'}))
                ]
                ),
                transition(
                '* => void',
                [
                    style({ height: '*'}),
                    animate('250ms cubic-bezier(0.86, 0, 0.07, 1)',
                        style({ height: 0}))
                ]
                )
            ]
        )
    ]
})
export class LeftSidebarComponent implements OnInit {
    @HostBinding('attr.role') public readonly role = "navigation";
    private __defaultConfig: Required<SidebarConfig> = {
        closeOtherMenus: true,
        showFavorite:    false,
        maxLevel:        3,
    };
    private _config: SidebarConfig = this.__defaultConfig;
    private temporaryOpen = false;
    public items: gcTreeNode[] = menuItems;
    public selected: gcTreeNode;
    public static instance: LeftSidebarComponent;
    public display: Display = "default";
    public drafts: IDraftInfo[];
    now = DateTime.now();
    // public extraLabel: Record<ExtraLabel,
    //     {
    //         label: string;
    //         background: string;
    //         color: string;
    //     } > = {
    //         newFeature: {
    //             label:      "Neu",
    //             background: "var(--gc-color-success)",
    //             color:      "var(--gc-color-success-contrast"
    //         },
    //         beta: {
    //             label:      "Beta",
    //             background: "var(--gc-color-info)",
    //             color:      "var(--gc-color-info-contrast"
    //         }
    //     };

    private _pin: boolean = !(localStorage.getItem('sidebar-pin') === "false");
    public get pin(): boolean {
        return this._pin;
    }
    public set pin(value: boolean) {
        this._pin = value;
        localStorage.setItem('sidebar-pin', value.toString());
        if (value){
            this.temporaryOpen = false;
            this.display = "default";
        } else {
            this.temporaryOpen = true;
        }
    }


    public get showSidebar(){
        return this.appService.pageService.showLeftSideNavigation;
    }

    public get config(): SidebarConfig {
        return this._config;
    }

    /**
     * This is a test
     * 
     * @example This shows how to use this property
     * ```html
     * <gc-left-sidebar [config]="{showFavorite: true, maxLevel: 2}"></gc-left-sidebar>
     * ```
     */
    @Input() public set config(value: SidebarConfig) {
        this._config = {...this.__defaultConfig, ...value};
    }

    public get isGreen() {
        return this.drafts.length < 4;
    }

    public get isRed() {
        return 10 <= this.drafts.length;
    }

    public get isYellow() {
        return 4 <= this.drafts.length && this.drafts.length < 10;
    }

    public get logo(){
        return this.display === 'default' ? './assets/img/logo/zeitbox_wide.svg' : './assets/img/logo/zeitbox_uhr.svg';
    }


    constructor(
        private primengConfig: PrimeNGConfig,
        private appService: AppService,
        private draftService: DraftService,
        private router: Router,
    ) {
        LeftSidebarComponent.instance = this;
        this.initDrafts();
        this.pin = this._pin;
        appService.$APIState.subscribe(x => this.items = this.filterMenuItem(menuItems));
    }

    public ngOnInit() {
        this.primengConfig.ripple = true;
    }

    getDate(iso: string) {
        return DateTime.fromISO(iso).endOf('day');
    }

    public getTooltip(entry: gcTreeNode){
        return entry.label.replace("&shy;", "");
    }

    public async clickEntryTitle(entry: gcTreeNode){
        if (!entry) return;
        if (entry.children?.length > 0){
            const oldValue = entry.expanded;

            // keep open till we click a link if the sidebar was minimized
            this.peek();
            // close all other entries, if it is a top-level-entry
            if (this.config.closeOtherMenus && this.items.includes(entry))this.items.forEach(x => x.expanded = false);

            // toggle
            entry.expanded = !oldValue;
            return;
        }
        if (entry.data){
            if (this.temporaryOpen)this.minimize();
            this.selected = entry;
        }
    }

    public async deleteDraft(event: MouseEvent, draftId: string) {
        event.stopPropagation();
        const redirect = draftId === this.draftService.draftId;
        await this.draftService.destroyDraft(draftId);
        event.stopPropagation();
        if (redirect)
            await this.router.navigate(['/']);
    }

    // public getExtraFlag(label: ExtraLabel){
    //     return this.extraLabel[label];
    // }

    public initDrafts() {
        this.draftService.$draftArchiveUpdate.subscribe((drafts: IDraftInfo[]) => this.drafts = sortBy(drafts, x => !x.active, x => -x.modified.valueOf()));
        this.appService.$APIState.subscribe(state => {
        // TODO clear only on certain events
        this.draftService.setLoginState([APIStates.loggedIn].includes(state.type));
        });
    }

    public isSelectedPath(entry: gcTreeNode): boolean{
        if (entry === this.selected) return true;
        return !!entry.children?.find(x => this.isSelectedPath(x)) ?? false;
    }

    public hintMinimize(){
        if (this.display === "default" && !this.temporaryOpen) return;
        this.minimize();
    }

    public minimize(){
        if (this.display === "minimized") return;
        this.display = "minimized";
        this.items.forEach(x => x.expanded = false);
        this.temporaryOpen = false;
    }

    public peek(){
        if (this.display === "minimized")
            this.temporaryOpen = true;

        this.display = "default";
    }

    filterMenuItem(items: gcTreeNode[]): gcTreeNode[] {
        return items.map(element => {
            let children: gcTreeNode[] = [];
            if (element.children) {
                children = this.filterMenuItem(element.children);
                if (children.length > 0)
                    return { ...element, children };
            }
            if (
                ((element.feature === undefined || !element.feature) && element.data) ||
                this.appService.getFeature(element.feature)
            ) {
                if (
                    ((element.permissions === undefined || element.permissions.length === 0) && element.data) ||
                    this.appService.getFrontendPermissions(element.permissions ?? [])
                    )
                        return { ...element, children };
            }

        }).filter(x => x);
    }

    // getPermissions(permissions: Permission[]) {
    //     return this.appService.getPermissions(permissions);
    // }
}
