import { RegisterRRPCError } from "@vierkant-software/remoterpc";
import { environment } from "src/environments/environment";
import { error_translations } from "src/environments/errors.de";
import { AppService } from "src/services/app.service";

declare global {
    export interface Error {
        getErrorText?(): string;
        displayErrorToast?(): void;
    }
}

// eslint-disable-next-line @typescript-eslint/space-before-function-paren
Error.prototype.getErrorText = function() { return "Unbekannter interner Client Fehler."; };
// eslint-disable-next-line @typescript-eslint/space-before-function-paren
Error.prototype.displayErrorToast = function(summary?: string, severity?: string) {
    if (!environment.production) console.error(this);
    // do not display error toast for maintenance message
    if (this instanceof APIError && this.status === "MAINTENANCE")
        return;
    AppService.instance.messageService.add({
        severity: "error",
        summary:  "System Client Error",
        detail:   this.getErrorText(),
    });
};

@RegisterRRPCError("BaseError")
export class BaseError extends Error {
    type: string;
    msg: string;
    status: string;
    severity: string;
    args: Record<string, unknown>;

    getErrorText(): string {
        let e = this.status.split(':').reduce((prev, cur) => prev?.[cur],error_translations) ?? error_translations.UNKNOWN_ERROR;
        if (typeof e === 'object' && typeof e.DEFAULT === 'string')
            e = e.DEFAULT;
        if (typeof e === 'string') {
            return e;
        } else if (typeof e === 'function') {
            try {
                return e(this.args);
            } catch(x) {
                console.error(this, x);
                return "Kein Babelfisch gefunden.";
            }
        } else {
            return (error_translations.UNKNOWN_ERROR as (a: unknown) => string) (this.args);
        }
    }

    displayErrorToast(summary?: string, severity?: string) {
        if (!environment.production) console.error(this);
        if (this instanceof APIError && this.status === "INVALID_SESSION")
            return;
        AppService.instance.messageService.add({
            severity: severity ?? ["info", "warn", "error"].includes(this.severity) ? this.severity : "error",
            summary:  summary ?? (error_translations.TOAST_DEFAULT_SUMMARY as string),
            detail:   this.getErrorText(),
        });
    }

    get message() {
        return this.constructor.name + '(' + this.status + '): ' + this.msg;
    }
}

@RegisterRRPCError("IpcError")
export class IpcError extends BaseError {
}

@RegisterRRPCError("APIError")
export class APIError extends IpcError {
}

@RegisterRRPCError("ApplicationError")
export class ApplicationError extends APIError {
}

@RegisterRRPCError("CalendarError")
export class CalendarError extends ApplicationError {

}

@RegisterRRPCError("CalendarAPIError")
export class CalendarAPIError extends APIError{
}

@RegisterRRPCError("ConflictError")
export class ConflictError extends ApplicationError {
}

@RegisterRRPCError("InvalidError")
export class InvalidError extends ApplicationError {
    static ClientVersion() {
        const e = new InvalidError("ClientVersion is too low");
        e.type = 'InvalidError';
        e.status = 'INVALID:CLIENT_VERSION',
        e.severity = 'error';
        e.msg = 'ClientVersion is too low, please update your client';
        return e;
    }
    static MinDraftVersion() {
        const e = new InvalidError("DraftVersion is below minimum version");
        e.type = 'InvalidError';
        e.status = 'INVALID:MIN_DRAFT_VERSION',
        e.severity = 'error';
        e.msg = 'DraftVersion is below minimum version';
        return e;
    }
    static NoUpgrade() {
        const e = new InvalidError("DraftVersion can not be upgraded");
        e.type = 'InvalidError';
        e.status = 'INVALID:NO_UPGRADE',
        e.severity = 'error';
        e.msg = 'Version can not be upgraded';
        return e;
    }
}

@RegisterRRPCError("NotFoundError")
export class NotFoundError extends ApplicationError {
}

@RegisterRRPCError("AreaError")
export class AreaError extends ApplicationError {
}

@RegisterRRPCError("ShiftError")
export class ShiftError extends ApplicationError {
}

@RegisterRRPCError("UserError")
export class UserError extends ApplicationError {
}

@RegisterRRPCError("ContractError")
export class ContractError extends ApplicationError {
}

@RegisterRRPCError("DocumentError")
export class DocumentError extends ApplicationError {
}

@RegisterRRPCError("GroupError")
export class GroupError extends ApplicationError {
}

@RegisterRRPCError("DatevError")
export class DatevError extends ApplicationError {
}
