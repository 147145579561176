import { Component, HostBinding, Injector, OnInit } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { PrimeNGConfig} from 'primeng/api';
import { AppComponent } from 'src/app/app.component';
import { IPage } from 'src/app/page.interface';
import { SidebarComponent } from 'src/app/sidebar/siderbar.component';
import { APIState, APIStates, AppService } from 'src/services/app.service';
import { SubType } from 'src/util/types/global';
import { LeftSidebarComponent } from '../left-sidebar/left-sidebar.component';
import { GCValidators } from 'src/app/validators';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

interface City {
  name: string;
  code: string;
}

@Component({
  selector:    'app-top-bar',
  templateUrl: './top-bar.component.haml',
  styleUrls:   ['./top-bar.component.sass'],
})
export class TopBarComponent implements OnInit {
  @HostBinding('attr.role') public readonly role = "banner";
  static instance: TopBarComponent;
  private leftSidebar?: LeftSidebarComponent;
  private rightSideBar?: SidebarComponent;
  private mainComponent?: IPage;

  //display: boolean = true;

  get display() {
    return this.appService.pageService.showTopBar;
  }

  value1: string = '';
  cities: City[];
  selectedCity: City;
  items: MenuItem[];
  user: SubType<APIState, APIStates.loggedIn> = null;

  userAvatar: SafeUrl;
  studioName: string;

  //for change password
  passDialog: boolean = false;
  userPass: FormGroup;
  medium = /\S{6,}/;
  strong = /\S{8,}/;

  public infoVisible = false;

  constructor(
    private primengConfig: PrimeNGConfig,
    private appService: AppService,
    private injector: Injector,
    private router: Router,
    private appComponent: AppComponent,
    private formBuilder: FormBuilder,
  ) {
    this.appService.$APIState.forEach((res: APIState) => {
      switch(res.type) {
        case APIStates.connected:
          this.studioName = res.studioName;
          break;
        case APIStates.loggedIn:
          this.studioName = res.studioName;
          this.user = res;
          this.userAvatar = res.avatar;
          break;
      }
    }).catch(x => null);

    TopBarComponent.instance = this;
    /*this.appService.$component.forEach(x => {
      this.mainComponent = x;
      if (x?.showTopBar !== undefined)
        this.display = x.showTopBar;
    }).catch(x => null);*/
    this.cities = [
      {name: 'Application', code: 'app'},
      {name: 'This Site', code: 'site'}
    ];

    this.userPass = this.formBuilder.group({
        oldPassword:        ['', GCValidators.required],
        newPassword:        ['', [GCValidators.required, Validators.minLength(8)]],
        confirmNewPassword: ['', [GCValidators.required, Validators.minLength(8)]]
    }, { validators: (form: FormGroup) => {
            const error = this.isSame(form) ? false : {required: true};
            return error;
        }
    });
  }

  ngOnInit() {
    this.leftSidebar = this.injector.get(LeftSidebarComponent);
    this.rightSideBar = this.injector.get(SidebarComponent);
    this.primengConfig.ripple = true;
    this.items = [
      {
          label: 'File',
          items: [{
                  label: 'New',
                  icon:  'pi pi-fw pi-plus',
                  items: [
                      {label: 'Project'},
                      {label: 'Other'},
                  ]
              },
              {label: 'Open'},
              {label: 'Quit'}
          ]
      },
      {
          label: 'Edit',
          icon:  'pi pi-fw pi-pencil',
          items: [
              {label: 'Delete', icon: 'pi pi-fw pi-trash'},
              {label: 'Refresh', icon: 'pi pi-fw pi-refresh'}
          ]
      }
    ];
  }

  //for changing the [disabled] state of dialog button
  isFormValid(){
    return this.userPass.valid;
  }

  showChangePassword(){
    this.passDialog = !this.passDialog;
  }

  onCancel(){
    this.passDialog = false;
    this.userPass.reset();
  }

  isSame(form: FormGroup = this.userPass){
    const a = form.get('newPassword').value;
    const b = form.get('confirmNewPassword').value;
    if ([null, undefined, ''].includes(a) || [null, undefined, ''].includes(b))
        return true;
    return  a === b;
  }

  async onConfirm(){
    try {
        await this.appService.api.AuthWorker.changePassword(this.userPass.value.oldPassword, this.userPass.value.newPassword);
        this.userPass.reset();
        this.passDialog = false;
    } catch(error) {
        this.appService.messageService.add({
            severity: 'error',
            summary:  'Passwort konnte nicht geaendert werden.',
            detail:   error.msg
        });
        console.error(error);
    }
  }

  async logout() {
    // await this.internalAppService.__logout();
    // await this.router.navigate(['/login']);
    await this.router.navigate(['/logout']);
  }

  // openStudioSwitchSidebar() {
  //   this.appComponent.studioSwitchVisible = StudioSwitchVisible.sidebar;
  // }

}
