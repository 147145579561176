import { Component, HostBinding, Input } from "@angular/core";
import { Gap, getGap } from "../util";


@Component({
    selector:  'gc-column',
    template:  `<ng-content></ng-content>`,
    styleUrls: ['column.component.sass']
})
export class GcColumnContainerComponent {
    @Input() gap: Gap | string = "default";
    @HostBinding("style.--layout-column-gap") get gapVar() {
        return getGap(this.gap);
    };
}
