import { Component, OnInit } from '@angular/core';

@Component({
  selector:    'gc-business-card',
  templateUrl: './gc-business-card.component.haml',
  styleUrls:   ['./gc-business-card.component.sass'],
})
export class GcBusinessCardComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
