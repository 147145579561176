import { EnvironmentInjector, Injectable, Injector, runInInjectionContext, signal } from '@angular/core';
import { BehaviorSubject, EMPTY, firstValueFrom, Observable } from 'rxjs';
import { ClientSettingsType, FeatureFlags, FrontendPermissions, ICredentialInfo, IUser, Permission, RPC } from '@vierkant-software/types__api';
import { ApiService } from './api.service';
import { v4 as uuid } from 'uuid';
import { IPage } from 'src/app/page.interface';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, NavigationExtras, Router, ResolveFn } from '@angular/router';
import { ExitState } from 'src/app/modules/ngx-draft';
import { Permissions } from 'src/util/Permissions';
import { Subscription } from 'rxjs';
import { PageService } from './page.service';
import { onModalStateChanged } from 'src/util/types/hooks';

import { ConfirmationService, MessageService, PrimeNGConfig } from 'primeng/api';
import { AppComponent } from 'src/app/app.component';
import { ErrorTranslations, error_translations } from 'src/environments/errors.de';
import { BitField } from 'src/util/bitfield/bitfield';
import { SettingsService } from './settings.service';
import { IDraftConflict, DraftConflictResolution } from 'src/app/modules/ngx-draft/types/IAppService';
import { APP_VERSION } from 'src/environments/version';
//#region API State definitions
export enum APIStates {
  loggedOut,
  disconnected,
  connected,
  loggedIn,
  switchingStudio,
  externalStudio,
}

export type APIState =
  { type: APIStates.loggedOut } |
  { type: APIStates.disconnected } |
  { type: APIStates.connected } & IStudioInfo |
  { type: APIStates.loggedIn } & IStudioInfo & IUserInfo |
  { type: APIStates.switchingStudio } & IStudioInfo |
  { type: APIStates.externalStudio } & IStudioInfo & IUserInfo
;
//#endregion

export interface IStudioInfo {
  studio: string;
  studioName: string;
  studioLogo: SafeUrl;
}

export interface IUserInfo {
  user: IUser;
  avatar?: SafeUrl;
}

export type Language = string;
class Languages {
  static DE_DE: Language = "de-DE";
  static EN_US: Language =  "en-US";
}

export abstract class AppService {
  public static instance: AppService;
  protected abstract apiService: ApiService;
  protected abstract router: Router;
  protected abstract modal_show: boolean;
  protected abstract rootActivatedRoute: ActivatedRoute;
  public abstract pageService: PageService;
  protected abstract location: Location;
  protected abstract environmentInjector: EnvironmentInjector;

  public modal_options: {
    isModal?: boolean;
    closeBackdrop?: boolean;
    closeOnEscape?: boolean;
    height?: string;
    width?: string;
    overflow?: string;
    exitState?: ExitState;
  } = {};
  protected mainComponent: BehaviorSubject<IPage & onModalStateChanged>;
  protected apiState: APIStates;
  protected $apiState: BehaviorSubject<APIState>;
  protected __locale: string;
  protected $locale: BehaviorSubject<string>;
  protected __mainAPI: RPC;
  protected __mainStudio: string;
  protected currentPermissions: Uint8Array;
  protected currentFrontendPermissions: Uint8Array;
  protected maxPermissions: Uint8Array;
  protected features: BitField;
  #deviceId: string;
  protected studioInfos: { [studio: string]: IStudioInfo } = {};
  public abstract messageService: MessageService;
  public get languages(): Language[] {
    return [Languages.DE_DE, Languages.EN_US];
  }
  protected get defaultLanguage(): Language{
    const availableLanguages = this.languages;
    let lang = localStorage.getItem("lang") ?? navigator.language;
    lang = availableLanguages.find(x => lang && x.startsWith(lang));
    if (!lang)
      lang = availableLanguages[0];
    localStorage.setItem("lang", lang);
    return lang;
  }

  public abstract getTranslation(key: string): unknown;
  public abstract confirmationService: ConfirmationService;

  public get state(): APIStates {
    return this.apiState ?? APIStates.disconnected;
  }

  clientIsValid = signal(true);

  //#region routerOutlet component awareness
  public get $component(): Observable<IPage> {
    return this.mainComponent.asObservable();
  }
  //#endregion

  public get $events() {
    return this.apiService.$events;
  }

  public get $connectionStatus() {
    return this.apiService.$status;
  }

  //#region API
  public get $APIState() {
    return this.$apiState.asObservable();
  }

  public get $Locale() {
    return this.$locale.asObservable();
  }

  public get locale() {
    return this.__locale;
  }

  get api(): RPC {
    if (!this.__mainAPI)
      throw new Error('not connected.');
    return this.__mainAPI;
  }

  public get deviceId() {
    if (this.#deviceId) return this.#deviceId;
    this.#deviceId = localStorage.getItem('deviceId');
    console.log('deviceId:', this.#deviceId);
    if (!this.#deviceId) {
      this.#deviceId = uuid();
      console.log('deviceId new:', this.#deviceId);
      localStorage.setItem('deviceId', this.#deviceId);
    }
    return this.#deviceId;
  }

  public get myUserId() {
    const state = this.$apiState.getValue();
    if (state.type === APIStates.loggedIn)
      return state.user.ID;
    return undefined;
  }
  //#endregion

  get studioInfo(): IStudioInfo {
    if (this.__mainStudio)
      return this.studioInfos[this.__mainStudio];
    return;
  }

  //#region Permissions
  public getPermission(perm: Permission): boolean {
    // eslint-disable-next-line no-bitwise
    return (this.currentPermissions[perm / 8 |0] & (1 << (perm % 8))) > 0;
  }

  public getPermissions(perms: Permission[]): boolean {
    return perms.some(x => this.getPermission(x));
  }

  public getFrontendPermission(perm: FrontendPermissions): boolean {
    // eslint-disable-next-line no-bitwise
    return (this.currentFrontendPermissions[perm / 8 |0] & (1 << (perm % 8))) > 0;
  }

  public getFrontendPermissions(perms: FrontendPermissions[]): boolean {
    return perms.some(x => this.getFrontendPermission(x));
  }

  public getMissingFrontendPermissions(perms: FrontendPermissions[]): string {
    return perms.filter(x => !this.getFrontendPermission(x)).map(x => FrontendPermissions[x]).join(', ');
  }

  public getFeature(feature: FeatureFlags): boolean {
    return this.features.isSet(feature);
  }
  //#endregion

  public async navigateModal(uri: string[] | null, options?: AppService['modal_options'], extras: NavigationExtras = {}) {
    this.modal_show = !!uri;
    this.modal_options = options ?? {};
    await this.router.navigate([{ outlets: { modal: uri }}], {...extras, ...{relativeTo: this.rootActivatedRoute.parent, skipLocationChange: true} });
    return this.mainComponent?.value?.onModalStateChanged?.(!uri, options?.exitState);
  }

  public async reloadCurrentUrl() {
    const url = this.location.pathname;
    await this.router.navigate(['/empty'], { skipLocationChange: true });
    return this.router.navigateByUrl(url, { skipLocationChange: true });
  }

  public async reRunResolvers(activatedRoute: ActivatedRoute, environmentInjector?: EnvironmentInjector) {
    let result: Record<string, unknown> = {};
    AppComponent.instance.loading = true;
    for (const route of activatedRoute.pathFromRoot) {
      if (route.routeConfig?.data)
        result = { ...result, ...route.routeConfig.data };
      if (route.routeConfig?.resolve) {
        await Promise.all(
          Object.entries(route.routeConfig.resolve).map(async ([key, resolver]) => {
            let instance: {
              resolve: ResolveFn<unknown>;
            };
            if (typeof resolver.prototype?.constructor?.name === 'string') {
              const injector = environmentInjector ?? Injector.create({ providers: [resolver], parent: this.environmentInjector });
              instance = injector.get(resolver, undefined, { optional: true, skipSelf: true });
            }
            const method = instance?.resolve ?? resolver;
            const res = runInInjectionContext(
              (environmentInjector ?? this.environmentInjector),
              () => method.apply(instance, [activatedRoute.snapshot, this.router.routerState.snapshot, true])
            );
            console.log(res);
            if (res === EMPTY)
              throw new Error('Error while running ' + key + ' in route ' + route.routeConfig);
            else if (res === undefined)
              return; // Ignore undefined results
            else if (res instanceof Promise)
              result[key] = await res;
            else if (res instanceof Observable)
              result[key] = await firstValueFrom(res);
            else
              result[key] = res;
          })
        );
      }
    }
    console.log(result);
    if (Object.keys(result).length > 0)
      (activatedRoute.data as BehaviorSubject<unknown>).next(result);
    AppComponent.instance.loading = false;
  }

  /** query a client specific setting from SettingService.
   *  This is ment to be used in resolvers. In pages/components use @GcSetting decorator instead.
   */
  getSetting<T>(type: ClientSettingsType): T{
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const value = SettingsService.instance.settings[type] as any;
    if (typeof value === 'object' && !value.isLuxonDateTime && !value.isLuxonDuration)
      return SettingsService.instance.getObjectProxy(type) as T;
    else return SettingsService.instance.settings[type] as T;
  }

  async resolveDuplicatedLockIdentifier(conflicts: IDraftConflict[], displayInfo: undefined | never): Promise<DraftConflictResolution> {
    return await AppComponent.instance.raiseLockIssue(conflicts);
  }
}

@Injectable()
export class InternalAppService extends AppService {
  public rootActivatedRoute: ActivatedRoute;
  public modal_show: boolean = false;

  #connSubs: Subscription;

  constructor(
    private primengConfig: PrimeNGConfig,
    protected apiService: ApiService,
    private sanitizer: DomSanitizer,
    private translate: TranslateService,
    protected router: Router,
    public pageService: PageService,
    public messageService: MessageService,
    public confirmationService: ConfirmationService,
    protected location: Location,
    protected environmentInjector: EnvironmentInjector,
  ) {
    super();
    AppService.instance = this;
    this.$apiState = new BehaviorSubject({ type: APIStates.loggedOut });
    this.mainComponent = new BehaviorSubject(undefined);
    this.currentPermissions = new Uint8Array();
    this.currentFrontendPermissions = new Uint8Array();
    this.features = new BitField(0);
    this.setupLocale();

    this.apiService.$status.subscribe(x => {
      switch(x) {
        case 'connected':
          this.__mainAPI.AuthWorker.validateClientVersion("admin", APP_VERSION).then(x => this.clientIsValid.set(x)).catch(console.error);
          break;
      }
    });
  }

  private setupLocale(){
    try {
      this.__locale = this.defaultLanguage;
      this.translate.addLangs(this.languages);
      this.translate.use(this.defaultLanguage);
      this.$locale = new BehaviorSubject(this.__locale);
    } catch(error) {
      console.error(error);
    }
  }

  public __activate(component: IPage, inModal = false) {
    if (!component)
      return console.warn("Warning: activating undefined component");
    if (inModal)
      return false;
    this.mainComponent.next(component);
    this.pageService.__activate(component).catch(console.error);
  }

  public getTranslation(key: string) {
    return this.primengConfig.getTranslation(key) as string;
  }

  // now called before the bootstrapping of the app is complete
  async __initAPI() {
    this.apiState = APIStates.disconnected;
    const studio = localStorage.getItem('studio');
    if (!studio) return;
    if (localStorage.getItem('__debug') && await this.__loadSession(studio)) return;
    await this.__setStudio(studio);
    /*
    if (!this.getPermission(1)) return;     //TODO permission autologin
    const refreshtoken = localStorage.getItem('refreshtoken');
    if (!refreshtoken) return;
    this.__login(refreshtoken);     //TODO credentialType.refreshtoken
    */
  }

  #setAPIstate(state: APIState) {
    this.apiState = state.type;
    this.$apiState.next(state);
  }

  __setLocale(locale: string) {
    try {
          const lang = this.languages.find(x => locale && x.startsWith(locale));
        if (!lang){ // prevent setting invalid or undefined locale
          console.error("Invalid locale: " + locale);
          return;
        }
        localStorage.setItem("lang", lang);
        this.__locale = lang;
        this.translate.use(lang);
        this.$locale.next(lang);
    } catch(error) {
      console.error(error);
    }
  }

  public async __setStudio(studio: string) {
    if (![APIStates.disconnected, APIStates.loggedOut, APIStates.connected].includes(this.apiState))
      throw new Error('API is in wrong state to set an preflight studio [' + APIStates[this.apiState] + ']');
    this.__mainAPI = this.apiService.getInterface(studio, null);
    try {
      const info = await this.__mainAPI.AuthWorker.preAuth(this.deviceId);
      this.clientIsValid.set(await this.__mainAPI.AuthWorker.validateClientVersion("admin", APP_VERSION));
      if (info.studio !== undefined && info.studio !== studio) {
        studio = info.studio;
        this.__mainAPI = this.apiService.getInterface(studio, null);
      }
      this.#setPermissions(info);
      const studioInfo = {
        studio,
        studioName: info.studioName,
        studioLogo: this.sanitizer.bypassSecurityTrustResourceUrl(info.__files[0] ?? './assets/img/placeholder/image.png'),
      };
      this.studioInfos[studio] = studioInfo;
      this.__mainStudio = studio;
      this.#setAPIstate({ type: APIStates.connected, ...studioInfo });
      localStorage.setItem('studio', studio);
      return true;
    } catch(e) {
      return e;
    }
  }

  public get __Studio() {
    return localStorage.getItem('studio');
  }

  public async __login(credentials: ICredentialInfo, remember: boolean = false) {
    if (![APIStates.disconnected, APIStates.loggedOut, APIStates.connected].includes(this.apiState))
      throw new Error('API is in wrong state to login [' + APIStates[this.apiState] + ']');
    try {
      const res = await this.__mainAPI.AuthWorker.auth(credentials, this.deviceId);
      if (!Permissions.hasAllPerms([Permission.App_Login_Frontend], res.permissions))
        throw new Error('no permission');
      this.#setPermissions(res);
      this.__mainAPI = this.apiService.getInterface(this.__mainStudio, res.ID);
      this.#subscribeReconnectHandler();
      this.#setAPIstate({
        type:   APIStates.loggedIn,
        ...this.studioInfos[this.__mainStudio],
        user:   res.user,
        avatar: res.__files[0] ? this.sanitizer.bypassSecurityTrustResourceUrl(res.__files[0]) : undefined,
      });
      /* TODO refreshtoken from backend
      if (remember && session.refreshtoken)
      localStorage.setItem('refreshtoken', session.refreshtoken);
      */
     if (localStorage.getItem('__debug')) localStorage.setItem('__sessionInfo', JSON.stringify(res));
     return true;
    } catch(e) {
      console.log('exception __login:', e);
      return 'login failed';
    }
  }

  async __activateSession(studio: string, sessionID: string) {
    try {
      if (localStorage.getItem('__debug'))
        throw new Error('Master Login is not allowed in debug mode');
      if (![APIStates.disconnected, APIStates.loggedOut, APIStates.connected].includes(this.apiState))
        throw new Error('API is in wrong state to set an preflight studio [' + APIStates[this.apiState] + ']');
      await this.__setStudio(studio);
      this.__mainAPI = this.apiService.getInterface(this.__mainStudio, sessionID);
      const res = await this.__mainAPI.AuthWorker.refreshSession(this.deviceId);
      if (!Permissions.hasAllPerms([Permission.App_Login_Frontend], res.permissions))
        throw new Error('no permission');
      this.#setPermissions(res);
      this.#subscribeReconnectHandler();
      this.#setAPIstate({
        type:   APIStates.loggedIn,
        ...this.studioInfos[this.__mainStudio],
        user:   res.user,
        avatar: this.sanitizer.bypassSecurityTrustResourceUrl(res.__files[0] ?? './assets/img/placeholder/profile.png'),
      });
      this.pageService.setOption({showLeftSideNavigation: true, showTopBar: true});
    } catch(e) {
      console.log('exception __activateSession:', e);
    }
    await this.router.navigateByUrl('/');
  }

  #subscribeReconnectHandler() {
    this.#connSubs = this.apiService.$status.subscribe(x => {
      switch(x) {
        case 'connected':
          this.__mainAPI.CommWorker.registerDeviceAndSession()
            .catch(err => console.error(err));
          break;
      }
    });
  }

  public async __logout() {
    this.#setAPIstate({ type: APIStates.loggedOut });
    this.api.CommWorker.unregisterDeviceAndSession(this.deviceId).catch(console.error);
    this.#connSubs?.unsubscribe();
    localStorage.removeItem('refreshtoken');
    localStorage.removeItem('__sessionInfo');
  }

  private async __loadSession(studio: string) {
    try {
      await this.__setStudio(studio);
      const result = JSON.parse(localStorage.getItem('__sessionInfo'));
      if (!result?.ID) return false;
      this.#setPermissions(result);
      this.__mainAPI = this.apiService.getInterface(this.__mainStudio, result.ID);
      this.#subscribeReconnectHandler();
      this.#setAPIstate({
        type:   APIStates.loggedIn,
        ...this.studioInfos[studio],
        user:   result.user,
        avatar: this.sanitizer.bypassSecurityTrustResourceUrl(result.__files[0] ?? './assets/img/placeholder/profile.png')
      });
      this.pageService.setOption({ showLeftSideNavigation: true, showTopBar: true });
      console.log('sid:', result.ID);
      return true;
    } catch(e) {
      console.log('exception __loadSession:', e);
      return false;
    }
  }

  #setPermissions(session: { permissions: Uint8Array, frontendPermissions?: Uint8Array, features?: string }) {
    this.currentPermissions = session.permissions;
    if (session.frontendPermissions !== undefined)
      this.currentFrontendPermissions = session.frontendPermissions;
    if (session.features !== undefined)
      this.features = new BitField(session.features, 0);
    this.maxPermissions = session.permissions;  // TODO backend
  }

  //#region Resolver Exception Handling
  #resolverExceptions: Record<string, string[]> = {};

  public __clearResolverException(outlet: string) {
    delete this.#resolverExceptions[outlet];
  }

  public __addResolverException(outlet: string, msg: string) {
    if (!this.#resolverExceptions[outlet])
      this.#resolverExceptions[outlet] = [];
    this.#resolverExceptions[outlet].push(msg);
  }

  public __getResolverExceptions() {
    const ex = Object.values(this.#resolverExceptions).flat(1);
    this.#resolverExceptions = {};
    return ex;
  }
  //#endregion

  async invalidSession() {
    await this.__logout();
    this.messageService.add({
      severity: "warn",
      summary:  (error_translations.ADMIN as ErrorTranslations).INVALID_SESSION_HEADER as string,
      detail:   (error_translations.ADMIN as ErrorTranslations).INVALID_SESSION as string,
    });
    this.router.navigate(['login']).catch(console.error);
  }

  async maintenance() {
    await this.__logout();
    this.router.navigate(['/maintenance'], { skipLocationChange: true }).catch(console.error);
  }

}
