import { Component,Input,OnInit } from '@angular/core';
import { GDuration } from '@vierkant-software/types__api';

@Component({
    selector:    'gc-price',
    templateUrl: './gc-price.component.haml',
    styleUrls:   ['./gc-price.component.sass'],
})
export class GcPriceComponent implements OnInit {
    @Input() price: number;
    @Input() unit?: GDuration;
    @Input() currency = "€"; //TODO: settings
    @Input() size = "5rem";

    get beforeDigitPrice(){
        return Math.floor(this.price);
    }
    get afterDigitPrice(){
        return (this.price % 1).toFixed(2).substring(2);
    }

    constructor(){}
    ngOnInit(): void {
    }
}
