import { Component, Input } from '@angular/core';
import { EventTemplateDTO, IDepartmentDTO, IUser, VisibilityName } from '@vierkant-software/types__api';
import { Duration } from 'luxon';
import { RRule } from 'rrule';
import { AppService } from 'src/services/app.service';

@Component({
    selector:    'gc-event-template-summary',
    templateUrl: './gc-event-template-summary.component.haml',
    styleUrls:   ['./gc-event-template-summary.component.sass'],
})
export class GCEventTemplateSummaryComponent {
    public _departments: Record<string, IDepartmentDTO> = {};
    public recurrence: RRule;
    private _value: EventTemplateDTO;
    public get value(): EventTemplateDTO {
        return this._value;
    }

    /**
     * If possible try to preload/assign departments and users, if not possible they will be loaded after assigning the event
     */
    @Input() public set value(value: EventTemplateDTO) {
        const oldID = this._value?.event?.ID;
        this.recurrence = value?.event.templateSettings?.recurrence ? RRule.fromString(value.event.templateSettings.recurrence) : undefined;
        this._value = value;
        if (value.departments) {
            this._departments = value.departments.reduce((curr, val) => {
                curr[val.departmentID] = val;
                return curr;
            }, {} as Record<string, IDepartmentDTO>);
        }
        else if (!value.users) {
            if (value.event.ID === oldID) return;
            this.fetchData().catch(e => e?.displayErrorToast());
        }
    }
    @Input() showTitle = true;

    constructor(private app: AppService) {}

    public get departments() {
        return this._departments;
    }

    public get users() {
        return this.value?.users as IUser[];
    }

    public durationString(duration: Duration) {
        return duration.reconfigure({locale: this.app.locale}).toHuman();
    }

    public getDurationPerPerson() {
        return this.value.event.templateSettings.durationPerPerson?.as('minutes');
    }

    public getRecurrenceDay() {
        const dayNames = AppService.instance.getTranslation("dayNames") as string[];
        return this.recurrence?.options.byweekday?.sort((a,b) => a - b)
            .map(x => dayNames[(x + 1) % 7]).join(', ');
    }

    public getRecurrenceMinute() {
        return this.recurrence?.options.byminute?.map(x => 'xx:' + String(x).padStart(2, '0')).join(', ');
    }

    public isWholeDay(duration: Duration) {
        return duration.as("days") === 1;
    }

    /**
     * Departments and Users should be provided through the `{@link value}` property and preloaded, 
     * but if they cannot be provided they will be loaded when the event is assigned
     */
    public async fetchData() {
        if (this.value.event.templateSettings.users_ID) {
            const users = (await this.app.api.UserWorker.getUsers(this.value.event.templateSettings.users_ID));
            this.value.users = users.data as IUser & { imageIndex?: number }[];
        }
        if (this.value.event.templateSettings.departments_ID) {
            this.value.departments = (await this.app.api.OrgchartWorker.getDepartmentListForLoggedinUser(VisibilityName.calendar, "active"))
                .filter(x => this.value.event.templateSettings.departments_ID.includes(x.departmentID));
            this._departments = this.value.departments.reduce((curr, val) => {
                curr[val.departmentID] = val;
                return curr;
            }, {} as Record<string, IDepartmentDTO>);
        }
    }
}
