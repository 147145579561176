console.warn('cleanup');

import { Injectable } from "@angular/core";
import { AttendanceState, EventType, IAttendanceDTO, NotifyChannel } from "@vierkant-software/types__api";
import { DateTime } from "luxon";
import { ReplaySubject, Subscription } from "rxjs";
import { APIStates, AppService } from "src/services/app.service";
import { IAttendance, IAttendanceLists, State } from "./interface";

@Injectable({
    providedIn: 'root',
})

export class AttendanceService {

    #attendanceList: IAttendance[];
    #missingList: IAttendance[];
    #absentList: IAttendance[];
    #timer: unknown | null = null;
    #eventSubs: Subscription | null = null;
    #replaySubjectSubs: Subscription | null = null;
    #connSubs: Subscription | null = null;
    #includeTrustedEmployees = false;
    #imagesCache: { userID: string, image: string | undefined }[] = [];
    #listLocked = false;
    #imagesLocked = false;

    get absentList(){
        return this.#absentList;
    }
    get attendanceList(){
        return this.#attendanceList;
    }
    get missingList(){
        return this.#missingList;
    }

    constructor(
        private appService: AppService,
    ) {
        this.appService.$APIState.forEach(state => {
            switch(state.type) {
                case APIStates.loggedIn:
                case APIStates.externalStudio:
                case APIStates.loggedOut:
                    if (this.#eventSubs)
                        this.unsubscribe().catch(c => null);
                    break;
            }
        }).catch(e => null);

        this.#subscribeReconnectHandler();
    }

    #subscribeReconnectHandler() {
        this.#connSubs = this.appService.$connectionStatus.subscribe(x => {
            switch(x) {
                case 'connected':
                    if (this.#eventSubs)
                        this.subscribe().catch(e => console.error("error while reconnected: ", e));
                break;
            }
        });
    }

    async subscribe(): Promise<IAttendanceLists>{
        await this.unsubscribe();
        const rep = new ReplaySubject(100);
        await this.appService.api.CommWorker.subscribeToChannel(NotifyChannel.attendance);
        this.#eventSubs = this.appService.$events.subscribe(ev => {
            if (ev.data.type === EventType.attendance)
                rep.next(ev.data.data);

        });
        const resultServer = await this.appService.api.TimekeepWorker.getAttendanceList(this.#includeTrustedEmployees,true);
        if (resultServer.data.length > 0 && resultServer.__files?.length && resultServer.__files.length === resultServer.data.length){
            for (let i = 0; i < resultServer.data.length; i++){
                const att = resultServer.data[i];
                if (!att?.user?.ID?.length) continue;
                const image = resultServer.__files[i];
                await this.addImageToCache(att.user.ID,image);
            }
            console.log('RS: ', resultServer);
        }

        const attendanceList = resultServer.data
            .filter(a => a.state === AttendanceState.presence || a.state === AttendanceState.inBreak)
            .map(d => this.attendanceDtoToIAttendance(d));
        const missingList = resultServer.data.filter(a => a.state === AttendanceState.absence).map(d => this.attendanceDtoToIAttendance(d));
        const absentList = resultServer.data
            .filter(a => a.state === AttendanceState.plannedAbsence)
            .map(d => this.attendanceDtoToIAttendance(d));
        for (const att of attendanceList)
            this.loadUserImage(att).catch(e => null);
        for (const att of missingList)
            this.loadUserImage(att).catch(e => null);
        for (const att of absentList)
            this.loadUserImage(att).catch(e => null);

        this.#attendanceList = attendanceList;
        this.#missingList = missingList;
        this.#absentList = absentList;
        this.#replaySubjectSubs = rep.subscribe((data) => {
            this.addOrRemoveAttendanceToList(data as IAttendanceDTO).catch(e => console.error(e));
        });
        const result: IAttendanceLists = {
            attendanceList: [] = this.#attendanceList,
            absentList:     [] = this.#absentList,
            missingList:    [] = this.#missingList,
        };

        this.startTimer();
        return result;
    }

    async unsubscribe(){
        try {
            this.stopTimer();
            await this.clearData();

            try {
                if (this.#eventSubs){
                    this.#eventSubs.unsubscribe();
                    this.#eventSubs = null;
                    await this.appService.api.CommWorker.unsubscribeFromChannel(NotifyChannel.attendance);
                }
            } catch(e){
                console.error(e);
            }

            try {
                if (this.#replaySubjectSubs){
                    this.#replaySubjectSubs.unsubscribe();
                    this.#replaySubjectSubs = null;
                }
            } catch(e){
                console.error(e);
            }
        } catch(e){
            console.error(e);
        }
        finally {
            await this.lockList(false);
            await this.lockImages(false);
        }
    }

    private attendanceDtoToIAttendance(dto: IAttendanceDTO): IAttendance {
        const att: IAttendance = {
            ...dto,
            state:      this.attendanceStateTostring(dto.state),
            clientTime: DateTime.now(),
        };
        return att;
    }

    private attendanceStateTostring(dtoState: AttendanceState): State {
        //State = "absence" | "presence" | "break" | "sick" | "holiday";
        let state: State = "absence";
        switch(dtoState){
            case AttendanceState.absence:
                state = "absence";
                break;
            case AttendanceState.presence:
                state = "presence";
                break;
            case AttendanceState.inBreak:
                state = "break";
                break;
            case AttendanceState.sick:
                state = "sick";
                break;
            case AttendanceState.holiday:
                state = "holiday";
                break;
            case AttendanceState.plannedAbsence:
                state = "abwesend";
                break;
        }
        return state;
    }

    private stringToAttendanceState(state: State): AttendanceState {
        if (!state?.length) return AttendanceState.none;
        //State = "absence" | "presence" | "break" | "sick" | "holiday";
        switch(state){
            case "absence":
                return AttendanceState.absence;
            case "presence":
                return AttendanceState.presence;
            case "break":
                return AttendanceState.inBreak;
            case "sick":
                return AttendanceState.sick;
            case "holiday":
                return AttendanceState.holiday;
            case "abwesend":
                return AttendanceState.plannedAbsence;
            default:
                return AttendanceState.none;
        }
    }

    private async clearData(){
        try {
            await this.lockList(true);
            await this.lockImages(true);

            this.#attendanceList = undefined;
            this.#absentList = undefined;
            this.#missingList = undefined;
            if (this.#imagesCache.length)this.#imagesCache.splice(0);

        } catch(e){
            console.error(e);
        } finally {
            await this.lockList(false);
            await this.lockImages(false);
        }
    }
    private async addOrRemoveAttendanceToList(dto: IAttendanceDTO){
        if (!dto?.user?.ID?.length) return;
        try {
            await this.lockList(true);

            const remove = dto.state === AttendanceState.none || (dto.hasTrustedWorkTimes && !this.#includeTrustedEmployees);
            const indexAttList = this.#attendanceList.findIndex(a => a.user.ID === dto.user.ID);
            const indexAbsentList = this.#absentList.findIndex(a => a.user.ID === dto.user.ID);
            const indexMissingList = this.#missingList.findIndex(a => a.user.ID === dto.user.ID);
            if (remove){
                if (indexAttList >= 0){
                    this.#attendanceList.splice(indexAttList, 1);
                    this.#attendanceList = [...this.#attendanceList];
                }
                if (indexAbsentList >= 0){
                    this.#absentList.splice(indexAbsentList, 1);
                    this.#absentList = [...this.#absentList];
                }
                if (indexMissingList >= 0){
                    this.#missingList.splice(indexMissingList, 1);
                    this.#missingList = [...this.#missingList];
                }
                return;
            }

            const newAtt =  this.attendanceDtoToIAttendance(dto);
            if (!newAtt?.user?.ID?.length) return;
            newAtt.image = await this.getUserImage(newAtt?.user?.ID);
            let isCopyOfArray = false;
            switch(dto.state){
                case AttendanceState.presence:
                case AttendanceState.inBreak: {
                        let attendanceList = this.#attendanceList;
                        if (indexAttList >= 0){
                            const att = attendanceList[indexAttList];
                            this.copyAttendance(newAtt,att);
                            isCopyOfArray = false;
                        } else {
                            attendanceList = [...this.#attendanceList];
                            attendanceList.push(newAtt);
                            isCopyOfArray = true;
                        }
                        if (attendanceList.length > 1){
                            if (!isCopyOfArray){
                                attendanceList = [...this.#attendanceList];
                                isCopyOfArray = true;
                            }
                            attendanceList.sort((a,b) => {
                                const aState = this.stringToAttendanceState(a.state);
                                const bState = this.stringToAttendanceState(b.state);
                                if (aState > bState) return 1;
                                if (aState < bState) return -1;

                                if (a.from > b.from) return -1;
                                if (a.from < b.from) return 1;

                                const al = (a.user?.lastname ?? "").trim().toLowerCase();
                                const bl = (b.user?.lastname ?? "").trim().toLowerCase();
                                if (al < bl) return -1;
                                if (al > bl) return 1;

                                const af = (a.user?.firstname ?? "").trim().toLowerCase();
                                const bf = (b.user?.firstname ?? "").trim().toLowerCase();
                                if (af < bf) return -1;
                                if (af > bf) return 1;

                                return 0;
                            });
                        }
                        this.#attendanceList = attendanceList;
                        if (indexAbsentList >= 0){
                            this.#absentList.splice(indexAbsentList, 1);
                            this.#absentList = [...this.#absentList];
                        }
                        if (indexMissingList >= 0){
                            this.#missingList.splice(indexMissingList, 1);
                            this.#missingList = [...this.#missingList];
                        }
                    }
                    break;
                case AttendanceState.absence: {
                        let missingList = this.#missingList;
                        if (indexMissingList >= 0){
                            const att = missingList[indexMissingList];
                            this.copyAttendance(newAtt,att);
                            isCopyOfArray = false;
                        } else {
                            missingList = [...this.#missingList];
                            missingList.push(newAtt);
                            isCopyOfArray = true;
                        }
                        if (missingList.length > 1){
                            if (!isCopyOfArray){
                                missingList = [...this.#missingList];
                                isCopyOfArray = true;
                            }
                            missingList.sort((a,b) => {
                                if (a.from > b.from) return 1;
                                if (a.from < b.from) return -1;

                                const al = (a.user?.lastname ?? "").trim().toLowerCase();
                                const bl = (b.user?.lastname ?? "").trim().toLowerCase();
                                if (al < bl) return -1;
                                if (al > bl) return 1;

                                const af = (a.user?.firstname ?? "").trim().toLowerCase();
                                const bf = (b.user?.firstname ?? "").trim().toLowerCase();
                                if (af < bf) return -1;
                                if (af > bf) return 1;

                                return 0;
                            });
                        }
                        this.#missingList = missingList;
                        if (indexAttList >= 0){
                            this.#attendanceList.splice(indexAttList, 1);
                            this.#attendanceList = [...this.#attendanceList];
                        }
                        if (indexAbsentList >= 0){
                            this.#absentList.splice(indexAbsentList, 1);
                            this.#absentList = [...this.#absentList];
                        }
                    }
                    break;
                //TODO check since there is no difference between sick and holiday anymore
                case AttendanceState.plannedAbsence:
                case AttendanceState.sick:
                case AttendanceState.holiday: {
                        let absentList = this.#absentList;
                        if (indexAbsentList >= 0){
                            const att = absentList[indexAbsentList];
                            this.copyAttendance(newAtt,att);
                            isCopyOfArray = false;
                        } else {
                            absentList = [...this.#absentList];
                            absentList.push(newAtt);
                            isCopyOfArray = true;
                        }
                        if (absentList.length > 1){
                             if (!isCopyOfArray){
                                absentList = [...this.#absentList];
                                isCopyOfArray = true;
                            }
                            absentList.sort((a,b) => {
                                if (a.from > b.from) return -1;
                                if (a.from < b.from) return 1;

                                const al = (a.user?.lastname ?? "").trim().toLowerCase();
                                const bl = (b.user?.lastname ?? "").trim().toLowerCase();
                                if (al < bl) return -1;
                                if (al > bl) return 1;

                                const af = (a.user?.firstname ?? "").trim().toLowerCase();
                                const bf = (b.user?.firstname ?? "").trim().toLowerCase();
                                if (af < bf) return -1;
                                if (af > bf) return 1;

                                return 0;
                            });
                        }
                        this.#absentList = absentList;
                        if (indexAttList >= 0){
                            this.#attendanceList.splice(indexAttList, 1);
                            this.#attendanceList = [...this.#attendanceList];
                        }
                        if (indexMissingList >= 0){
                            this.#missingList.splice(indexMissingList, 1);
                            this.#missingList = [...this.#missingList];
                        }
                    }
                    break;
                default:
                    if (indexAttList >= 0){
                        this.#attendanceList.splice(indexAttList, 1);
                        this.#attendanceList = [...this.#attendanceList];
                    }
                    if (indexAbsentList >= 0){
                        this.#absentList.splice(indexAbsentList, 1);
                        this.#absentList = [...this.#absentList];
                    }
                    if (indexMissingList >= 0){
                        this.#missingList.splice(indexMissingList, 1);
                        this.#missingList = [...this.#missingList];
                    }
                    break;
            }
        } catch(e){
            console.error(e);
        } finally {
            await this.lockList(false);
        }
    }

    private async getUserImage(userID: string): Promise<string | undefined>{
        try {
            if (!userID?.length) return undefined;
            let att = this.#attendanceList?.find(a => a?.user?.ID === userID);
            if (att?.image?.length) return att.image;
            att = this.#absentList?.find(a => a?.user?.ID === userID);
            if (att?.image?.length) return att.image;
            att = this.#missingList?.find(a => a?.user?.ID === userID);
            if (att?.image?.length) return att.image;
            let img = this.getImageFromCache(userID);
            if (img?.length) return img;
            if (img === null) return undefined;
            const result = await this.appService.api.UserWorker.getUser(userID,true);
            if (result?.__files?.length === 1){
                img = result.__files[0];
                await this.addImageToCache(userID,img);
            }

            return img;
        } catch(e){
            console.error(e);
        }

        return undefined;
    }

    private copyAttendance(source: IAttendance, destination: IAttendance){
        destination.clientTime = source.clientTime;
        destination.from = source.from;
        destination.image = source.image;
        destination.serverTime = source.serverTime;
        destination.state = source.state;
        destination.till = source.till;
        destination.user = source.user;
        destination.lastLocationID = source.lastLocationID;
        destination.lastLocationTitle = source.lastLocationTitle;
    }

    private async loadUserImage(data: IAttendance){
        if (!data?.user?.ID?.length) return;
        if (data.image?.length) return;
        data.image = await this.getUserImage(data.user.ID);
    }

    private async addImageToCache(userID: string, image: string | undefined){
        try {
            await this.lockImages(true);

            if (!userID?.length) return;
            if (!this.#imagesCache)this.#imagesCache = [];
            const data = this.#imagesCache.find(d => d.userID === userID);
            if (!data)this.#imagesCache.push({userID, image});
            else if (image?.length) data.image = image;
            else if (!data.image?.length) data.image = image;
        } catch(e){
            console.error(e);
        } finally {
            await this.lockImages(false);
        }
    }

    private getImageFromCache(userID: string): string | undefined | null {
        if (!userID?.length) return undefined;
        const data = this.#imagesCache.find(d => d.userID === userID);
        if (data){
            if (!data.image?.length) return null;
            return data.image;
        }

        return undefined;
    }

    private stopTimer(){
        try {
            if (this.#timer) clearTimeout(this.#timer as number);
        } catch(e){
            console.error(e);
        }
    }

    private startTimer(){
        try {
            this.stopTimer();
            //reload every 1 hour complete
            this.#timer = setTimeout(() => {
                try {
                    this.subscribe().catch(e => console.error(e));
                } catch(e){
                    console.error(e);
                }

                this.startTimer();
            }, 60 * 60 * 1000);
        } catch(e){
            console.error(e);
        }
    }

    private async lockList(lock: boolean){
        if (!lock){
            this.#listLocked = false;
        } else {
            let counter = 0;
            while (this.#listLocked){
                //wait
                await new Promise(f => setTimeout(f, 10));
                counter++;
                if (counter > 2000) break;
            }
        }
    }

    private async lockImages(lock: boolean){
        if (!lock){
            this.#imagesLocked = false;
        } else {
            let counter = 0;
            while (this.#imagesLocked){
                //wait
                await new Promise(f => setTimeout(f, 10));
                counter++;
                if (counter > 2000) break;
            }
        }
    }
}
