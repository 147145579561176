import { Component,ElementRef,HostListener,Input,OnInit, ViewChild } from '@angular/core';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

@Component({
    selector:    'gc-draggable-fab',
    templateUrl: './gc-draggable-fab.component.haml',
    styleUrls:   ['./gc-draggable-fab.component.sass'],
})
export class GcDraggableFabComponent implements OnInit {

    @Input() onClick: () => void = () => {};
    @Input() faIcon?: IconProp;
    @Input() primeIcon?: string;
    @Input() target?: HTMLElement;
    @ViewChild("fab", {read: ElementRef, static: true}) fab: ElementRef<HTMLElement>;
    offsetLeft: number;
    offsetTop: number;
    oldPositionX: number;
    oldPositionY: number;

    // FIXME add "display/hide" property

    constructor(
        private library: FaIconLibrary,
    ){
		library.addIconPacks(fas);
    }
    ngOnInit(): void {
        // FIXME: position is correct but sidebar slides back too slow.
        setTimeout(() => this.setFABPosition(), 100);
    }

    #moveBinding = this.move.bind(this);

    mouseDown(e: MouseEvent | TouchEvent){
        this.offsetTop = this.fab.nativeElement.offsetTop;
        this.offsetLeft = this.fab.nativeElement.offsetLeft;
        if (e.type === "mousedown"){
            this.oldPositionY = (e as MouseEvent).clientY;
            this.oldPositionX = (e as MouseEvent).clientX;
        } else {
            this.oldPositionY = (e as TouchEvent).touches[0].clientY;
            this.oldPositionX = (e as TouchEvent).touches[0].clientX;
        }

        if (e.type === "mousedown")
            window.addEventListener("mousemove", this.#moveBinding);
        else
            window.addEventListener("touchmove", this.#moveBinding);
        this.fab.nativeElement.style.transition = "none";
    }

    mouseUp(e: MouseEvent | TouchEvent){
        if (e.type === "mouseup")
            window.removeEventListener("mousemove", this.#moveBinding);
        else
            window.removeEventListener("touchmove", this.#moveBinding);
    }

    move(e: MouseEvent | TouchEvent){
        if (e.type === "touchmove"){
            this.setPosition(
                (e as TouchEvent).touches[0].clientX,
                (e as TouchEvent).touches[0].clientY);
        } else {
            this.setPosition(
                this.offsetLeft + (e as MouseEvent).clientX - this.oldPositionX,
                this.offsetTop + (e as MouseEvent).clientY - this.oldPositionY);
        }
    }

    click(event: MouseEvent | PointerEvent){
        if (this.oldPositionY !== event.clientY || this.oldPositionX !== event.clientX)
            return;
        this.onClick();
    }

    setPosition(x: number, y: number) {
        this.fab.nativeElement.style.top  = (y / document.documentElement.clientHeight * 100) + "%";
        this.fab.nativeElement.style.left = (x / document.documentElement.clientWidth * 100) + "%";
    }

    @HostListener('window:resize', ['$event'])
    private setFABPosition(){
        if (!this.target)
            return;
        const element = this.target;
        const rect = element.getBoundingClientRect() as DOMRect;
        const x = rect.x + rect.width - 10;
        const y = rect.y;
        this.setPosition(x, y);
    }
}
