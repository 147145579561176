import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService, PrimeNGConfig } from 'primeng/api';
import { AppService } from 'src/services/app.service';
import { TreeNode } from 'primeng/api';
import { IUser } from '@vierkant-software/types__api';
import { gcTreeNode } from 'src/util/types/global';
import { animate, style, transition, trigger } from '@angular/animations';
import { DateTime } from 'luxon';

type ExtraLabel = "newFeature" | "beta";

@Component({
  selector:    'gc-general-sidebar',
  templateUrl: './gc-general-sidebar.component.haml',
  styleUrls:   ['./gc-general-sidebar.component.sass'],
  animations:  [
    trigger(
        'toggleAnimation',
        [
            transition(
            'void => *',
            [
                style({ height: 0}),
                animate('250ms cubic-bezier(0.86, 0, 0.07, 1)',
                    style({ height: '*'}))
            ]
            ),
            transition(
            '* => void',
            [
                style({ height: '*'}),
                animate('250ms cubic-bezier(0.86, 0, 0.07, 1)',
                    style({ height: 0}))
            ]
            )
        ]
    )
    ]
})
export class GcGeneralSidebarComponent implements OnInit {

  extraLabel: Record<ExtraLabel, {label: string, background: string, color: string}> = {
    newFeature: {label: "Neu", background: "var(--gc-color-success)", color: "var(--gc-color-success-contrast"},
    beta:       {label: "Beta", background: "var(--gc-color-info)", color: "var(--gc-color-info-contrast"}
  };
  selected: TreeNode;

  @Input() userInfo: IUser;
  @Input() menuItemsList: gcTreeNode[];
  @Input() avatarUrl: string;

  now = DateTime.now();

  constructor(private primengConfig: PrimeNGConfig, private appService: AppService, private router: Router, private messageService: MessageService) {}


  getDate(iso: string) {
    return DateTime.fromISO(iso).endOf('day');
  }
  // getExtra(item: gcTreeNode) {
  //   return item.extraLabel ? this.extraLabel[item.extraLabel] : {label: false, background: false, color: false};
  // }

  ngOnInit() {
    this.primengConfig.ripple = true;
  }

  async nodeSelect(event: Event & {node: TreeNode}) {
    if (event.node.children?.length === 0) {
      await this.router.navigate(event.node.data);
      if (event.node.data.length < 1) {
        this.messageService.add({
          severity: 'error',
          detail:   'Not implemented yet!'
        });
      };
    } else {
      this.selected.expanded = !this.selected.expanded;
    }
  }

  setFavorite(event: Event) {
    event.stopPropagation();
    this.messageService.add({severity: "error", summary: "Set Favorite", detail: "not implemented yet"});
  }

  public getTooltip(entry: gcTreeNode){
        return entry.label.replace("&shy;", "");
    }

  public async clickEntryTitle(entry: gcTreeNode){
      if (!entry) return;
      if (entry.children?.length > 0){
          const oldValue = entry.expanded;
          // close all other entries, if it is a top-level-entry
          this.menuItemsList.forEach(x => x.expanded = false);
          // toggle
          entry.expanded = !oldValue;
          return;
      }
      if (entry.data){
          this.selected = entry;
          await this.router.navigate(entry.data);
      }
  }

  // public getExtraFlag(label: ExtraLabel){
  //     return this.extraLabel[label];
  // }

  public isSelectedPath(entry: gcTreeNode): boolean{
      if (entry === this.selected) return true;
      return !!entry.children?.find(x => this.isSelectedPath(x)) ?? false;
  }
}
