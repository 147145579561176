import { Component, Input } from '@angular/core';

@Component({
  selector:    'gc-li',
  templateUrl: './gc-li.component.haml',
  styleUrls:   ['./gc-li.component.sass'],
})
export class GcLiComponent {
  @Input() value: unknown;
  @Input() label: string;
  @Input() inactiveLabel: string;
  @Input() displayFalse = false;
  @Input() asHeader = false;
  @Input() labelAddition: string;

  constructor() { }
}
