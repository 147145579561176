import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LeftSidebarComponent } from './left-sidebar/left-sidebar.component';
import { TopBarComponent } from './top-bar/top-bar.component';
import { LanguageSelectorComponent } from './language-selector/language-selector.component';
import { SharedModule } from '../shared.module';
import { MenuTreeviewComponent } from './menu-treeview/menu-treeview.component';
import { DuplicateSidebarComponent } from '../sidebar/components/duplicates/duplicate.sidebar.component';
import { CardItemComponent } from './card-item/card-item.component';
import { GradientButtonComponent } from './gradient-button/gradient-button.component';
import { GcFileUploadComponent } from './gc-forms/gc-file-upload/file-upload.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CompareDialogComponent } from './compare-dialog/compare-dialog.component';
import { GcPriceComponent } from './gc-price/gc-price.component';
import { ContractAdjustmentInfoSidebarComponent } from '../sidebar/components/contractAdjustmentInfo/contractAdjustmentInfo.sidebar.component';
import { GcContractCardComponent } from './gc-contract-card/gc-contract-card.component';
import { LawButtonComponent } from './law-button/law-button.component';
import { LawSidebarComponent } from '../sidebar/components/law/law.sidebar.component';
import { PersonShortcutSidebarComponent } from '../sidebar/components/person-shortcut/person-shortcut.sidebar.component';
import { GcInfoComponent } from './gc-info/gc-info.component';

import { AbsPipe } from '../pipes/abs.pipe';
import { ConsolePipe } from '../pipes/console.pipe';
import { RuntimePipe } from '../pipes/runtime.pipe';
import { ByteSizePipe } from '../pipes/byteSize.pipe';
import { Enum2StringPipe } from '../pipes/enum2string.pipe';
import { GcBusinessCardComponent } from './gc-business-card/gc-business-card.component';

import { DualMultiSelectComponent } from './dual-multi-select/dual-multi-select.component';
import { GcReactiveForms } from 'src/app/modules/ngx-components';
import { GcIconComponent } from './gc-icon/gc-icon.component';
import { GCOverviewComponent } from './gc-overview/gc-overview.component';
import { StudioSwitchSidebarComponent } from './gc-studio-switch-sidebar/gc-studio-switch-sidebar.component';
import { GcDraggableFabComponent } from './gc-draggable-fab/gc-draggable-fab.component';
import { DatetimeComponent } from './gc-datetime/datetime.component';
import { TimepickerModule } from './gc-timepicker/timepicker.component';
import { DatepickerModule } from './gc-datepicker/datepicker.component';
import { GcDateComponent } from './gc-date/gc-date.component';
import { GcTimeComponent } from './gc-time/gc-time.component';
import { GcAddressComponent } from './gc-address/gc-address.component';
import { GcUserComponent } from './gc-user/gc-user.component';
import { GcAutoCompleteModule } from './gc-autocomplete/autocomplete';
import { GcContactComponent } from './gc-contact/gc-contact.component';
import { ProjectDuplicateSidebarComponent } from '../sidebar/components/project-duplicate/project-duplicate.sidebar.component';
import { GcDatePeriodSelectionComponent } from './gc-date-period-selection/gc-date-period-selection.component';
import { GcDateRangeComponent } from './gc-date-range/gc-date-range.component';
import { GcSingleSelectComponent } from './gc-single-select/gc-single-select.component';
import { PrintPreviewComponent } from './print-preview/print-preview.component';
import { GcVarDirective } from './gc-var/gc-var.directive';
import { GcGeneralSidebarComponent } from './gc-general-sidebar/gc-general-sidebar.component';
import { GcTooltipModule } from './gc-tooltip/gc-tooltip-module';
import { GcForOfDirective } from './gc-for/gc-for.directive';
import { GcTooltipContainerComponent, GcTooltipDirective } from './gc-tooltip/gc-tooltip.directive';
import { GcKVComponent } from './gc-kv/gc-kv.component';
import { GcAbsenceSidebarComponent } from '../sidebar/components/absence/absence.sidebar.component';
import { GcQrComponent } from './gc-qr/gc-qr.component';
import { GCQrDialogComponent } from './gc-qr-dialog/gc-qr-dialog.component';
import { GcUserListComponent } from './gc-user-list/gc-user-list.component';
import { GcLiComponent } from './gc-li/gc-li.component';
import { GcOrgChartComponent } from './gc-org-chart/gc-org-chart.component';
import { GcPersonSearchComponent } from './gc-person-search/component/gc-person-search.component';
import { GcPersonSearchDropdownComponent } from './gc-person-search/dropdown/gc-person-search-dropdown.component';
import { GcPersonSearchInputComponent } from './gc-person-search/input-text/gc-person-search-input.component';
import { GcUserSelectComponent } from './gc-user-select/gc-user-select.component';
import { GCOverviewPanelComponent } from './gc-panel/gc-overview-panel..component';
import { GcStatsViewRowExpandComponent } from './gc-stats-view-row-expand/gc-stats-view-row-expand.component';
import { GCSettingsGroupComponent } from './gc-settings-group/gc-settings-group.component';
import { gcIsFeatureDirective } from './feature.directive';
import { gcIsPermittedDirective } from './permission.directive';
import { GCSearchComponent } from './gc-search/gc-search.component';
import { MultiSelectModule } from 'primeng/multiselect';
import { GCEventTemplateSummaryComponent } from './gc-event-template-summary/gc-event-template-summary.component';
import { GCCalendarSettingsComponent } from './gc-calendar-settings/gc-calendar-settings.component';
import { GcAnnouncementComponent } from './gc-dialog-info/gc-announcement.component';
import { GcLayoutModule } from './layout/layout.module';
import { GCShiftSettingsComponent } from './gc-shift-settings/gc-shift-settings.component';
import { GcHSLPickerComponent } from './gc-hsl-picker/gc-hsl-picker.component';
import { GcAvatarComponent } from './gc-avatar/gc-avatar.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    SharedModule,
    FontAwesomeModule,
    ReactiveFormsModule,
    GcReactiveForms,
    GcAutoCompleteModule,
    GcTooltipModule,
    TimepickerModule,
    DatepickerModule,
    MultiSelectModule,
    GcLayoutModule,
  ],
  declarations: [
    LeftSidebarComponent,
    TopBarComponent,
    LanguageSelectorComponent,
    MenuTreeviewComponent,
    GcFileUploadComponent,
    DuplicateSidebarComponent,
    ProjectDuplicateSidebarComponent,
    PersonShortcutSidebarComponent,
    ContractAdjustmentInfoSidebarComponent,
    LawSidebarComponent,
    GcBusinessCardComponent,
    Enum2StringPipe,
    AbsPipe,
    ByteSizePipe,
    ConsolePipe,
    DatetimeComponent,
    CardItemComponent,
    RuntimePipe,
    CompareDialogComponent,
    GradientButtonComponent,
    GcPriceComponent,
    GcInfoComponent,
    GcContractCardComponent,
    LawButtonComponent,
    DualMultiSelectComponent,
    GcIconComponent,
    GCOverviewComponent,
    GCQrDialogComponent,
    StudioSwitchSidebarComponent,
    GcDraggableFabComponent,
    GcDateRangeComponent,
    GcDateComponent,
    GcTimeComponent,
    GcAddressComponent,
    GcUserComponent,
    GcContactComponent,
    GcDatePeriodSelectionComponent,
    GcSingleSelectComponent,
    PrintPreviewComponent,
    GcVarDirective,
    GcGeneralSidebarComponent,
    GcForOfDirective,
    GcAbsenceSidebarComponent,
    GcKVComponent,
    GcQrComponent,
    GcUserListComponent,
    GcLiComponent,
    GcOrgChartComponent,
    GcPersonSearchComponent,
    GcPersonSearchInputComponent,
    GcPersonSearchDropdownComponent,
    GcUserSelectComponent,
    GCOverviewPanelComponent,
    GcStatsViewRowExpandComponent,
    GCSettingsGroupComponent,
    gcIsFeatureDirective,
    gcIsPermittedDirective,
    GCSearchComponent,
    GCEventTemplateSummaryComponent,
    GCCalendarSettingsComponent,
    GcAnnouncementComponent,
    GCShiftSettingsComponent,
    GcHSLPickerComponent,
    GcAvatarComponent,
  ],
  exports: [
    LeftSidebarComponent,
    TopBarComponent,
    LanguageSelectorComponent,
    MenuTreeviewComponent,
    GcFileUploadComponent,
    DuplicateSidebarComponent,
    ProjectDuplicateSidebarComponent,
    PersonShortcutSidebarComponent,
    LawSidebarComponent,
    ContractAdjustmentInfoSidebarComponent,
    GcBusinessCardComponent,
    Enum2StringPipe,
    AbsPipe,
    ByteSizePipe,
    ConsolePipe,
    DatetimeComponent,
    CardItemComponent,
    RuntimePipe,
    CompareDialogComponent,
    GradientButtonComponent,
    GcPriceComponent,
    GcInfoComponent,
    GcContractCardComponent,
    LawButtonComponent,
    DualMultiSelectComponent,
    GcIconComponent,
    GCOverviewComponent,
    GCQrDialogComponent,
    StudioSwitchSidebarComponent,
    GcDraggableFabComponent,
    GcDateRangeComponent,
    GcDateComponent,
    GcTimeComponent,
    GcAddressComponent,
    GcUserComponent,
    GcContactComponent,
    DatepickerModule,
    TimepickerModule,
    GcDatePeriodSelectionComponent,
    GcSingleSelectComponent,
    PrintPreviewComponent,
    GcVarDirective,
    GcGeneralSidebarComponent,
    GcForOfDirective,
    GcTooltipDirective,
    GcTooltipContainerComponent,
    GcAbsenceSidebarComponent,
    GcKVComponent,
    GcQrComponent,
    GcUserListComponent,
    GcLiComponent,
    GcOrgChartComponent,
    GcPersonSearchInputComponent,
    GcPersonSearchDropdownComponent,
    GcUserSelectComponent,
    GcStatsViewRowExpandComponent,
    GCOverviewPanelComponent,
    GcStatsViewRowExpandComponent,
    GCSettingsGroupComponent,
    gcIsFeatureDirective,
    gcIsPermittedDirective,
    GCSearchComponent,
    GCEventTemplateSummaryComponent,
    GCCalendarSettingsComponent,
    GcAnnouncementComponent,
    GCShiftSettingsComponent,
    GcHSLPickerComponent,
    GcAvatarComponent,
  ]
})
export class ComponentsModule {}
