import { style, trigger, transition, animate  } from '@angular/animations';
import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';

//example usage in haml
/*
    %gc-info{severity: "blue"}
*/
export type Severity = 'info' | 'success' | 'warn' | 'danger' | 'error' | 'default';
@Component({
    selector:    'gc-info',
    templateUrl: "./gc-info.component.haml",
    styleUrls:   ['./gc-info.component.sass'],
    /**
     * animation is currently not used, but we are keeping this for the (near) future.
     * entry transition is not playing, if the element was destroyed by removing the parent element from the DOM.
     */
    animations:  [trigger(
        "animation",
        [
                transition(
                'void => *, :enter',
                [
                    style({ transform: 'translateY(-25%)', opacity: 0}),
                    animate('400ms cubic-bezier(0.86, 0, 0.07, 1)',
                        style({ transform: 'translateY(0)', opacity: 1}))
                ]
            ),
            transition(
                "* => void",
                [
                    style({ transform: 'translateY(0)', opacity: 1}),
                    animate('400ms cubic-bezier(0.86, 0, 0.07, 1)',
                        style({ transform: 'translateY(-25%)', opacity: 0}))
                ]
            )
        ]
    )],
    // eslint-disable-next-line @angular-eslint/no-host-metadata-property
    host: { class: "gc-component" }
})
export class GcInfoComponent{
    @Input() showIcon: boolean = true;
    @Input() closeable: boolean;
    @Output() closeableChange = new EventEmitter<boolean>();

    @Input() public isHidden = false;
    @Input() @HostBinding("class") severity: Severity = 'default';
}

