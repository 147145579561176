import { Component, Input } from '@angular/core';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

type FaPrefix = "fas" | "gc";
type Mode = 'prime' | 'fa' | 'img';
export type Color = 'primary' | 'secondary' | 'success' | 'warning' | 'danger' | 'text' | 'info' | 'text-inverse';

/**
 * @example Font-Awesome
 * ```html
 * <gc-icon icon="cross" mode="fa"></gc-icon>
 * ```
 * @example Prime
 * ```
 * %gc-icon{[icon]: "'pi-home'", mode: "prime"}
 * ```
 */
@Component({
    selector:    'gc-icon',
    templateUrl: './gc-icon.component.haml',
})
export class GcIconComponent {
    @Input() icon: string;
    @Input() mode: Mode = 'fa';
    @Input() prefix: FaPrefix = "fas";
    @Input() color?: Color;
    //for future purposes
    @Input() backgroundColor?: Color;

    get _primeIcon(){
        return this.icon as string;
    }
    get _faIcon(){
        return [this.prefix, this.icon] as IconProp;
    }

    get _img() {
        return this.icon as string;
    }

    getColor(color: Color){
        if (!color) return undefined;
        if (color === 'primary') return 'var(--gc-color-primary)';
        if (color === 'secondary') return 'var(--gc-color-secondary)';
        if (color === 'success') return 'var(--gc-color-success)';
        if (color === 'warning') return 'var(--gc-color-warning)';
        if (color === 'danger') return 'var(--gc-color-danger)';
        if (color === 'text') return 'var(--gc-text-color)';
        if (color === 'text-inverse') return 'var(--gc-text-color-inverse)';
        if (color === 'info') return 'var(--gc-color-info)';
        return 'transparent';
    }
}
