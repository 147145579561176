import { Component,Input} from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { IUser, IUserEx, SimpleUser } from '@vierkant-software/types__api';
import { Globals } from 'src/app/services/globals.service';
import { Format, formatUser } from 'src/util/formatting/user';

@Component({
    selector:    'gc-user',
    styleUrls:   ['./gc-user.component.sass'],
    templateUrl: './gc-user.component.haml',
})
export class GcUserComponent{

    // TODO: replace all instances in frontend with IUserEx or preferably **SimpleUser**
    @Input() public set value(value: SimpleUser | IUserEx | IUser) {
        this._internalValue = value as SimpleUser;
        this.updateValue();
    }

    @Input() public set format(value: Format){
        this._internalFormat = value;
        this.updateValue();
    }

    @Input() public set image(value: string | SafeUrl) {
        this._internalImage = value;
    }

    @Input() public forceImage: boolean = false;
    @Input() public color: boolean = false;
    @Input() public nameClass?: string;

    _internalValue: SimpleUser;
    _internalFormat: Format = "normal";
    _internvalParsedValue: string;
    _internalImage?: string | SafeUrl;

    private updateValue(){
        this._internvalParsedValue = formatUser(this._internalValue, this._internalFormat);
    }

    public getColor() {
        const [h, s] = this._internalValue?.userColor ?? this._internalValue?.defaultColor ?? [undefined, undefined];
        if (!h || !s) return undefined;
        const lightness = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ?
            Globals.DARK_MODE_LIGHTNESS :
            Globals.LIGHT_MODE_LIGHTNESS;
        return `hsl(
            ${h}deg,
            ${s * 100}%,
            ${lightness * 100}%
        )`;
    }
}
