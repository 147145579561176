import { DateTime, Duration } from "luxon";

export { };

export function toDuration(value: DateTime): Duration {
  return Duration.fromObject({hours: value.hour, minutes: value.minute, seconds: value.second});
};

/**
 * Calculate the amount of days between two DateTimes. May return undefined if the parameter is invalid
 * @param days The dates to calculate the difference of. The first entry should be the start and the last entry be the last of the range.
 * @returns The amount of days between the dateTimes.
 */
export function dayDiff(days: [DateTime, DateTime]) {
  if (!days || days.length !== 2 || days.find(x => !x.isValid)) return undefined;
  const [start, end] = days;
  return Math.ceil(
    end.toUTC().endOf("day").diff(
      start.toUTC().startOf("day")
    ).as("days")
  );
}


function toDurationProto(this: DateTime) {
  return toDuration(this);
}

declare module "luxon" {
  export interface DateTime {
    toDuration(): Duration;
  }
}

// FIXME: Jens - function is not defined 
// maybe because DateTime is already created in msgpack
DateTime.prototype.toDuration = toDurationProto;

