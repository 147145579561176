import { Component,Input,OnInit } from '@angular/core';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faSection } from '@fortawesome/free-solid-svg-icons';
import { LawSidebarComponent } from 'src/app/sidebar/components/law/law.sidebar.component';
import { SidebarComponent } from "src/app/sidebar/siderbar.component";

@Component({
    selector:    'gc-law-button',
    templateUrl: './law-button.component.haml',
    styleUrls:   ['./law-button.component.sass'],
})
export class LawButtonComponent implements OnInit {
    @Input() public name: string;

    instancedComponent: LawSidebarComponent;
    constructor(
        private library: FaIconLibrary,
        private sideBar: SidebarComponent,

    ){
        library.addIcons(faSection);

    }
    ngOnInit(): void {
    }

    click(){
        if (this.instancedComponent){
            close();
            return;
        }
        this.sideBar.clear();
        this.instancedComponent = this.sideBar.addItem(LawSidebarComponent, {name: this.name});
        this.sideBar.visible = true;
    }

    close(){
        this.sideBar.clear();
        this.sideBar.visible = false;
    }
}
