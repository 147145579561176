import { Component, ContentChild, HostBinding, Input, TemplateRef, ViewEncapsulation} from '@angular/core';
import { defaultToggleAnimation } from 'src/theme/animation';

@Component({
    selector:      'gc-overview-panel',
    templateUrl:   './gc-overview-panel.component.haml',
    styleUrls:     ['./gc-overview-panel.component.sass'],
    animations:    [defaultToggleAnimation],
    encapsulation: ViewEncapsulation.None,
})
export class GCOverviewPanelComponent{

    isCollapsed: boolean = true;
    @Input() public header: unknown;
    @Input() public value: unknown;
    @Input() public style: Record<string, unknown>;
    @Input() public styleClass: string;
    @Input() public toggler: "icon" | "header" = "header";
    @Input() public hideContent = false;
    @Input() @HostBinding("class.pad-content") public padContent = true;


    @ContentChild('header', { static: true}) public headerTemplateRef: TemplateRef<unknown>;
    @ContentChild('content', { static: true}) public contentTemplateRef: TemplateRef<unknown>;

    public toggle(_event: Event, toggler: "icon" | "header"){
        if (toggler !== this.toggler) return;
        this.isCollapsed = !this.isCollapsed;
    }

    get hasContent() {
        return !!(this.value || this.contentTemplateRef) && !this.hideContent;
    }
}
