// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.main .title {
  width: 8rem;
  text-align: center;
}

.sub .title {
  width: 8rem;
  text-align: center;
}

gc-button.stats-generic {
  display: flex;
  justify-content: flex-end;
}

.option-button {
  border-width: 1px;
  border-style: solid;
  border-radius: 4px;
}

.overflow-overlay {
  overflow: overlay;
}

.font-size-07 {
  font-size: 0.7rem;
}

.font-size-13 {
  font-size: 1.3rem;
}

.absolute {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}`, "",{"version":3,"sources":["webpack://./src/app/components/gc-stats-view-row-expand/gc-stats-view-row-expand.component.sass"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,aAAA;EACA,sBAAA;AACJ;;AACI;EACI,WAAA;EACA,kBAAA;AAER;;AAAI;EACI,WAAA;EACA,kBAAA;AAGR;;AAAI;EACI,aAAA;EACA,yBAAA;AAGR;;AADA;EACI,iBAAA;EACA,mBAAA;EACA,kBAAA;AAIJ;;AAFA;EACI,iBAAA;AAKJ;;AAHA;EACI,iBAAA;AAMJ;;AAJA;EACI,iBAAA;AAOJ;;AALA;EACI,MAAA;EACA,OAAA;EACA,QAAA;EACA,SAAA;AAQJ","sourcesContent":[":host\n    flex-grow: 1\n    display: flex\n    flex-direction: column\n.main\n    .title \n        width: 8rem\n        text-align: center\n.sub\n    .title\n        width: 8rem\n        text-align: center\n\ngc-button\n    &.stats-generic\n        display: flex\n        justify-content: flex-end\n    \n.option-button\n    border-width: 1px\n    border-style: solid\n    border-radius: 4px\n\n.overflow-overlay\n    overflow: overlay\n\n.font-size-07\n    font-size: 0.7rem\n\n.font-size-13\n    font-size: 1.3rem\n\n.absolute\n    top: 0\n    left: 0\n    right: 0\n    bottom: 0"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
