import { Component, EventEmitter, HostBinding, Output, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

export const LIGHT_LIGHTNESS = 35;
export const DARK_LIGHTNESS = 50;

type Value = [number, number];

@Component({
    selector:    'gc-hsl-picker',
    styleUrls:   ['./gc-hsl-picker.component.sass'],
    templateUrl: './gc-hsl-picker.component.haml',
    providers:   [{
        provide:     NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => GcHSLPickerComponent),
        multi:       true
    }]
})
export class GcHSLPickerComponent implements ControlValueAccessor {
    private _hue: number = 0;
    private _saturation: number = 0.5;
    private _value: Value;

    @HostBinding('class.gc-disabled')
    public disabled: boolean;
    @Output() public valueChanged: EventEmitter<Value> = new EventEmitter<Value>();
    @Output() public lightColorChanged: EventEmitter<string> = new EventEmitter<string>();
    @Output() public darkColorChanged: EventEmitter<string> = new EventEmitter<string>();

    public lightness = 0.5;
    public onChanged = (hsl: Value) => this.valueChanged.emit(hsl);
    public onTouched = () => {};

    public get lightColor() {
        return `hsl(
            ${this.hue}deg,
            ${this.saturation * 100}%,
            ${LIGHT_LIGHTNESS}%
        )`;
    }
    public get darkColor() {
        return `hsl(
            ${this.hue}deg,
            ${this.saturation * 100}%,
            ${DARK_LIGHTNESS}%
        )`;
    }

    public get hue(): number {
        return this._hue;
    }

    public set hue(value: number) {
        this._hue = value;
        this.updateValue();
        this.onChanged(this.value);
    }

    public get saturation(): number {
        return this._saturation;
    }

    public set saturation(value: number) {
        this._saturation = value;
        this.updateValue();
        this.onChanged(this.value);
    }

    public get value() {
        return this._value;
    }

    public set value(hs: Value) {
        this.writeValue(hs);
    }

    public registerOnChange(fn: (hs: Value) => void): void {
        this.onChanged = fn;
    }

    public registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    public setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    public writeValue(hs: Value): void {
        if (!hs || hs === this._value || hs.length !== 2) return;
        const [h, s] = hs;
        this.hue = h;
        this.saturation = s;
        this.updateValue();
    }

    private updateValue() {
        this._value = [this.hue, this.saturation];
        this.lightColorChanged.emit(this.lightColor);
        this.darkColorChanged.emit(this.darkColor);
        return this._value;
    }
}
