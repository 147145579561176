import { Component, ContentChild, Input, TemplateRef, ViewChild} from '@angular/core';
import { DraftService } from 'src/app/modules/ngx-draft';
import { DraftCreateInfo } from 'src/app/modules/ngx-draft/lib/draft.service';
import { GCSearchComponent } from '../gc-search/gc-search.component';
export type DraftOptions = {baseURI: string, anchor: string, options?: DraftCreateInfo};

@Component({
    selector:    'gc-overview',
    templateUrl: './gc-overview.component.haml',
    styleUrls:   ['./gc-overview.component.sass'],
})
export class GCOverviewComponent<T = unknown> {
    private _data: T[] = [];

    @Input() title = "Übersicht";
    /**
     * Add a filter function/closure to include a searchbar.
     * Function must take a search string and a list of elements.
     * 
     * Outside influences can affect the filter function. 
     * To trigger the filter after having outside changes call {@link GCOverviewComponent.updateSearch()|'updateSearch'}.
     */
    @Input() public search: (term: string, arr: T[]) => (T[] | Promise<T[]>);
    @Input({ required: true })
    public set data(value: T[]) {
        this._data = value;
        // data might be changed more than once from the outside (e.g. person absence page)
        if (!this.search)this.filteredData = this.data;
    }
    public get data(): T[] {
        return this._data;
    }

    @Input() entryClasses = '';
    @Input() titleProperty: string | (<T extends Record<string, unknown>>(obj: T) => string) = "title";
    @Input() descriptionProperty = "description";
    @Input() idProperty = "ID";
    @Input() draft: DraftOptions;
    @Input() hasCopy = true;
    @Input() hasEdit = true;
    @Input() emptyListText: string = "Es sind noch keine Einträge vorhanden.";
    @Input() padContent = true;

    public filteredData: T[];

    @ViewChild("searchbar", {static: false}) searchbar: GCSearchComponent;

    @ContentChild('header', { static: false})
    headerTemplateRef: TemplateRef<unknown>;

    @ContentChild('headerTitle', { static: false})
    headerTitleTemplateRef: TemplateRef<unknown>;
    /**
     * Add a filter template to include a popup-button.
     */
    @ContentChild('filter', { static: false})
    filterTemplateRef: TemplateRef<unknown>;
    @ContentChild('title', { static: false})
    titleTemplateRef: TemplateRef<unknown>;

    @ContentChild('center', { static: false})
    centerTemplateRef: TemplateRef<unknown>;

    @ContentChild('action', { static: false})
    actionTemplateRef: TemplateRef<unknown>;

    @ContentChild('description', { static: false})
    descriptionTemplateRef: TemplateRef<unknown>;

    constructor(private draftService: DraftService){}

    public updateSearch(): void {
        this.searchbar.update();
    }

    public hasDescription(entry: Record<string, T>){
        const hasTemplate = !!this.descriptionTemplateRef;
        const hasProp = ![null, undefined].includes(this.descriptionProperty);
        const desc = ![null, undefined, ''].includes((entry[this.descriptionProperty] as string)?.trim());
        return hasTemplate || (hasProp && desc);
    }

    async copy(entry: T){
        this.draftService.createDraft(this.draft.baseURI, this.draft.anchor, {
            ...this.draft.options,
            copy:     true,
            RecordID: (<Record<string, T>>entry)[this.idProperty] as string
        }).catch(console.error);
    }
    edit(entry: T){
        const x = <Record<string, T>>entry;
        this.draftService.createDraft(this.draft.baseURI, this.draft.anchor, {
            ...this.draft.options,
            RecordID: x[this.idProperty] as string
        }).catch(console.error);
    }

    getTitle(entry: T){
        const x = <Record<string, unknown>>entry;
        return typeof this.titleProperty === "string" ? x[this.titleProperty] as string : this.titleProperty(x);
    }
    getDescription(entry: T){
        const x = <Record<string, T>>entry;
        return x[this.descriptionProperty] as string;
    }
}

