import {
    AfterViewInit, Component, ElementRef, EventEmitter as ngEventEmmiter, forwardRef, InjectFlags, Injector, Input, Output, ViewChild
} from '@angular/core';
import { ControlValueAccessor, NgControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { MessageService } from 'primeng/api';
import { SliderChangeEvent } from 'primeng/slider';
import { formatBytes } from 'src/util/formatting/byteSize';

type Coordinate = { x: number, y: number };

export enum FileType {
	Default = 0,
	Image,
	Document,
	Excel,
}

export enum Mask {
	None = 0,
	Circle = 1,
	Square = 2,
}

export type ImageSize = "small" | "medium" | "large";

export const Size: { [key in ImageSize]: number } = {
	small:  150,
	medium: 300,
	large:  1024,
};

export const AllowedFileEndings: { [key in FileType]: string[] | undefined } = {
	[FileType.Default]:  undefined,
	[FileType.Image]:    ["JPG", "JPEG", "PNG", "SVG", "GIF"],
	[FileType.Document]: ["DOCX", "PDF"],
	[FileType.Excel]:    ["XLSX"],
};

type UIPosition = { clientX: number, clientY: number };
type ValueType = string | ArrayBuffer;
type CallbackType = (() => void) | ((x: ValueType) => void);
@Component({
	selector:    'gc-file-upload',
	templateUrl: './file-upload.component.haml',
	styleUrls:   ['./file-upload.component.sass'],
	providers:   [
		{
			provide:     NG_VALUE_ACCESSOR,
			multi:       true,
			useExisting: forwardRef(() => GcFileUploadComponent)
		}
	]
})
export class GcFileUploadComponent implements ControlValueAccessor, AfterViewInit {

	onChange: CallbackType = () => { };
	onTouch: () => void = () => { };
	MaskEnum = Mask;
	FileType = FileType;
	@Input() Type: FileType = FileType.Default;
	@Input() HasPreview: boolean = false;
	@Input() AllowedFileEndings?: string[] = null;
	@Input() MaxSize = -1;
	@Input() HideControls = false;
	@Input() AllowEdit = false;
	@Input() Mask = Mask.None;
	@Input() Size?: ImageSize = "medium";
	@Input() keepOriginal: boolean = false;
    @Output() fileChange: ngEventEmmiter<File> = new ngEventEmmiter<File>();
	@ViewChild('input') inputElementRef: ElementRef<HTMLInputElement>;
	@ViewChild('canvas') canvasRef: ElementRef;
	imageUpload: ImageUpload;
	isEditVisible = false;
	value: ValueType;
	file: File;
	_originalValue?: ValueType;
	constructor(
		private library: FaIconLibrary,
		private messageService: MessageService,
		private injector: Injector
	) {
		library.addIcons(faMinus, faPlus);
	}

	get fileTypeFilter(): string | undefined{
		switch(this.Type) {
			case FileType.Document:
			case FileType.Image:
				return AllowedFileEndings[this.Type].map(x => '.' + x.toLowerCase()).join(', ') + '|' + 'Bilder';
			case FileType.Excel:
				return AllowedFileEndings[this.Type].map(x => '.' + x.toLowerCase()).join(', ');
		}
		return undefined;
	}

	ngAfterViewInit(): void {
		const control = this.injector.get(NgControl, undefined, InjectFlags.Self);
		if (!control)
			throw new Error('NgControl not found');
		control.control.GCType = 'file';
	}

	onFileChanged(event: Event) {
		const element = event.target as HTMLInputElement;
		const files = element.files;
		const file = files[0];
		let type = null;
		switch(this.Type) {
			case FileType.Document:
				type = file.type.match(new RegExp("(?<content>application)/(?<type>pdf|vnd.openxmlformats-officedocument.wordprocessingml.document)", 'i'));
				break;
			case FileType.Image:
				type = file.type.match(new RegExp(`(?<content>image)/(?<type>${this.getFileEndings("|")})`, 'i'));
				break;
			case FileType.Excel:
				type = file.type.match(new RegExp("(?<content>application)/(?<type>xlsx|vnd.openxmlformats-officedocument.spreadsheetml.sheet)", 'i'));
		}

		if (this.Type !== FileType.Default && !type) {
			this.messageService.add({
				severity: "error",
				summary:  "Dateiformat ungültig.",
				detail:   `Folgende Formate sind erlaubt: ${this.getFileEndings()}.`,
				life:     3000,
			});
			return;
		}
		if (this.MaxSize > -1 && this.MaxSize < (file.size / 1000)) {
			this.messageService.add({
				severity: "error",
				summary:  "Dateigröße zu groß.",
				detail:   `Bitte wähle eine Datei bis ${formatBytes(this.MaxSize, 'MB')}.`,
				life:     3000,
			});
			return;
		}

		this.file = file;
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = _event => this.SelectFile(reader.result);
	}

	removeFile(_event: Event){
		const value = this.keepOriginal ? this._originalValue : undefined;
		this.file = undefined;
		this.inputElementRef.nativeElement.value = '';
		this.writeValue(value);
		this.onChange(value);
		this.fileChange.emit(undefined);
	}

	async SelectFile(value: ValueType) {
		if (this.Type !== FileType.Image || !this.AllowEdit) {
            this.fileChange.emit(this.file);
			this.writeValue(value);
			this.onChange(this.value);
			return;
		}

		// edit currently only for images
		if (this.Type === FileType.Image && typeof value === 'string')
			this.showEdit(value);
	}

	showEdit(value: string) {
		this.isEditVisible = true;
		delete this.imageUpload;
		this.imageUpload = new ImageUpload(this.canvasRef.nativeElement, value, this.Size, this.Mask);
		this.imageUpload.addEventListener("onAccept", (event: CustomEvent) => {
			this.isEditVisible = false;
			this.writeValue(event.detail);
			this.onChange(this.value);
			this.imageUpload.removeAllListeners();
		});
		this.imageUpload.addEventListener("onReject", (_) => {
			this.isEditVisible = false;
			this.inputElementRef.nativeElement.value = '';
			this.imageUpload.removeAllListeners();
		});

	}

	getFileEndings(separator = ", ") {
		return AllowedFileEndings[this.Type].join(separator);
	}

	onInputChange() {
		const val = this.inputElementRef.nativeElement.value;
		this.onChange(val);
	}

	writeValue(value: ValueType) {
		this.value = value;
		if (!this._originalValue)
			this._originalValue = value;
	}

	registerOnChange(fn: CallbackType) {
		this.onChange = fn;
	}

	registerOnTouched(fn: () => void) {
		this.onTouch = fn;
	}
}


declare interface ImageUpload {
	addEventListener(event: 'onAccept', listener: (event: CustomEvent) => void): this;
	addEventListener(event: 'onReject', listener: (event: Event) => void): this;
}

class ImageUpload extends EventTarget {
	canvas: HTMLCanvasElement;
	ctx: CanvasRenderingContext2D;
	editImage?: HTMLImageElement;
	offScreen: OffscreenCanvas;
	isDragging = false;
	mouseStart: Coordinate;
	mouseEnd: Coordinate;
	dx: number;
	dy: number;
	dw: number;
	dh: number;
	scale: number;
	halfWidth: number;
	halfHeight: number;
	defaultScaling: number = 1;
	canvasScaling: number = 1;
	editValue: string;
	size?: ImageSize;
	mask = Mask.None;

	constructor(canvas: HTMLCanvasElement, value: string, size?: ImageSize, mask?: Mask) {
		super();
		this.size = size;
		this.mask = mask;
		this.canvas = canvas;
		this.ctx = this.canvas.getContext("2d");
		this.showEdit(value);
	}

	showEdit(value: string) {
		this.editValue = value;

		this.editImage = new Image();
		this.editImage.onload = () => {
			const x = this.editImage;
			const c = this.canvas;
			// /**
			//  * only set canvas size when no size was specified
			//  * otherwise the canvas will have a set size defined in the Size-array.
			//  * Editor is in 16:9 format but mask can be applied
			//  */
			// if (!this.size)
			//   c.height = x.height;
			// else
			//   c.height = Size[this.size];
			// c.width = c.height / 9 * 16;

			const height = this.size ? Size[this.size] : x.height;
			const width = height / 9 * 16;

			/**
			 * Scale image to fit the canvas 
			 */
			this.defaultScaling = c.height / x.height;
			if (this.defaultScaling * x.width < width)
				this.defaultScaling = height / x.width;
			this.canvasScaling = this.canvas.height / this.canvas.clientHeight;
			/**
			 * OffsreenCanvas should offer better performance
			 */
			this.offScreen = new OffscreenCanvas(width, height);
			this.halfWidth = width / 2;
			this.halfHeight = height / 2;
			this.resetImage();
		};
		this.editImage.src = value;
	}

	/**
	 * Draws image without mask and then emits the cropped data 
	 */
	accept() {
		this.drawImage();
		const data = this.ctx.getImageData(this.halfWidth - this.halfHeight, 0, this.canvas.height, this.canvas.height);
		const tmpCanvas = document.createElement("canvas");
		const ctx = tmpCanvas.getContext("2d");
		tmpCanvas.width = this.canvas.height;
		tmpCanvas.height = this.canvas.height;
		ctx.putImageData(data, 0, 0);
		const image = tmpCanvas.toDataURL("image/jpeg");
		super.dispatchEvent(new CustomEvent("onAccept", {detail: image}));
	}

	/**
	 * Emits a close event
	 */
	reject() {
		this.editValue = undefined;
		super.dispatchEvent(new Event("onReject"));
	}

	/**
	 * Set the start position for dragging
	 *
	 * @param event MouseEvent for the start position
	 */
	handleMouseDown(event: UIPosition) {
		this.mouseStart = this.getMousePosition(this.canvas, event);
		this.isDragging = true;
	}

	/**
	 * Calculates image offset based on distance traveled and previous
	 * offset.
	 * Request image render.
	 *
	 * @param event MouseEvent for getting the traveled distance
	 */
	handleMouseMove(event: UIPosition) {
		if (event instanceof Event) {
			event.preventDefault();
			event.stopPropagation();
		}

		if (this.isDragging) {
			const mouse = this.getMousePosition(this.canvas, event);
			this.dx = Math.floor(((mouse.x - this.mouseStart.x) * this.canvasScaling) + this.mouseEnd.x);
			this.dy = Math.floor(((mouse.y - this.mouseStart.y) * this.canvasScaling) + this.mouseEnd.y);
			const w = (this.dw * ((1 / 100 * this.scale) + 1));
			const h = (this.dh * ((1 / 100 * this.scale) + 1));
			this.dx = Math.max(this.dx, this.halfWidth + this.halfHeight - w);
			this.dx = Math.min(this.dx, this.halfWidth - this.halfHeight);
			this.dy = Math.max(this.dy, (this.halfHeight * 2) - h);
			this.dy = Math.min(this.dy, 0);
			requestAnimationFrame(() => this.drawImage(this.mask));
		}
	}

	handleTouchStart(event: TouchEvent) {
		if (event.touches.length !== 1)
			return;
		this.handleMouseDown(event.touches[0] as UIPosition);
	}
	handleTouchEnd(_event: TouchEvent) {
		this.handleMouseUp();

	}
	handleTouchMove(event: TouchEvent) {
		if (event.touches.length !== 1)
			return;
		this.handleMouseMove(event.touches[0] as UIPosition);
	}


	/**
	 * Saves image offset
	 *
	 * @param _event 
	 */
	handleMouseUp(_event?: UIPosition) {
		this.mouseEnd = { x: this.dx, y: this.dy };
		this.isDragging = false;
	}

	/**
	 * Calls drawImage when the image is scaled through the view
	 *
	 * @param _event 
	 */
	scaleImage(_event: SliderChangeEvent) {
		this.drawImage(this.mask);
	}

	/**
	 * Draws image on an offscreen canvas and then passes data to the view canvas
	 * Can draw a mask on top
	 *
	 * @param mask Should a mask be shown?
	 */
	private drawImage(mask: Mask = Mask.None) {
		const ctx = this.offScreen.getContext("2d");
		ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
		//#region cutout
		// switch(mask){
		//   case Mask.Circle: {
		//     ctx.arc(this.halfWidth, this.halfHeight, this.halfHeight, 0, 2 * Math.PI);
		//     ctx.fillStyle = "#FFF";
		//     ctx.fill();
		//     break;
		//   }
		//   case Mask.Square: {
		//     ctx.rect(this.halfWidth - this.halfHeight, 0, this.canvas.height, this.canvas.height);
		//     ctx.fillStyle = "#FFF";
		//     ctx.fill();
		//     break;
		//   }
		// }

		// if (mask !== Mask.None)
		//   ctx.globalCompositeOperation = "source-in";
		//#endregion
		ctx.drawImage(
			this.editImage,
			this.dx,
			this.dy,
			Math.floor(this.dw * ((1 / 100 * this.scale) + 1)),
			Math.floor(this.dh * ((1 / 100 * this.scale) + 1))
		);
		switch(mask) {
			case Mask.Circle: {
				ctx.arc(this.halfWidth, this.halfHeight, this.halfHeight, 0, 2 * Math.PI);
				ctx.lineWidth = 6;
				ctx.strokeStyle = "#000";
				ctx.stroke();
				ctx.arc(this.halfWidth, this.halfHeight, this.halfHeight, 0, 2 * Math.PI);
				ctx.lineWidth = 5;
				ctx.strokeStyle = "#fff";
				ctx.stroke();
				break;
			}
			case Mask.Square: {
				ctx.rect(this.halfWidth - this.halfHeight, 0, this.canvas.height, this.canvas.height);
				ctx.strokeStyle = "#000";
				ctx.lineWidth = 6;
				ctx.stroke();
				ctx.rect(this.halfWidth - this.halfHeight, 0, this.canvas.height, this.canvas.height);
				ctx.strokeStyle = "#FFF";
				ctx.lineWidth = 5;
				ctx.stroke();
				break;
			}
		}
		this.ctx.putImageData(ctx.getImageData(0, 0, this.offScreen.width, this.offScreen.height), 0, 0);
	}

	changeImage() {

	}

	/**
	 * Resets all offsets and scaling.
	 * Draws image
	 */
	resetImage() {
		this.dw = this.editImage.width * this.defaultScaling;
		this.dh = this.editImage.height * this.defaultScaling;
		this.dx = this.halfWidth - (this.dw / 2);
		this.dy = this.halfHeight - (this.dh / 2);
		this.mouseEnd = this.mouseStart = { x: this.dx, y: this.dy };
		this.scale = 0;
		this.drawImage(this.mask);
	}

	/**
	 * Calculates mouse position in the canvas element depending on the canvas offset.
	 *
	 * @param canvas Canvas element
	 * @param event MouseEvent 
	 * @returns 
	 */
	getMousePosition(canvas: HTMLCanvasElement, event: UIPosition): Coordinate {
		const rect = canvas.getBoundingClientRect();
		return {
			x: (event.clientX) - rect.left,
			y: (event.clientY) - rect.top,
		};
	}

}
